<template>
  <b-carousel-item>
    <section id="quidditch" class="hero is-fullheight is-dark">
      <div class="hero-body slide-body has-text-black is-relative">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 823 684" style="enable-background:new 0 0 823 684;" xml:space="preserve" class="is-absolute">
          <g id="quidditch-layer">
          <g id="grid_4_" class="animate__animated animate__fadeIn _2 animate__slower">
            <g>
              <line class="no-fill blue-stroke" x1="151.67" y1="123.71" x2="308.6" y2="673.69"/>
              <line class="no-fill blue-stroke" x1="210.1" y1="107.04" x2="367.03" y2="657.02"/>
              <line class="no-fill blue-stroke" x1="268.53" y1="90.36" x2="425.46" y2="640.34"/>
              <line class="no-fill blue-stroke" x1="326.96" y1="73.69" x2="483.89" y2="623.67"/>
              <line class="no-fill blue-stroke" x1="385.39" y1="57.02" x2="542.32" y2="607"/>
              <line class="no-fill blue-stroke" x1="443.82" y1="40.35" x2="600.75" y2="590.33"/>
            </g>
            <g>
              <line class="no-fill blue-stroke" x1="496.81" y1="69.25" x2="130.36" y2="173.81"/>
              <line class="no-fill blue-stroke" x1="513.48" y1="127.68" x2="147.03" y2="232.24"/>
              <line class="no-fill blue-stroke" x1="530.15" y1="186.11" x2="163.7" y2="290.67"/>
              <line class="no-fill blue-stroke" x1="546.82" y1="244.54" x2="180.38" y2="349.1"/>
              <line class="no-fill blue-stroke" x1="563.49" y1="302.97" x2="197.05" y2="407.53"/>
              <g>
                <line class="no-fill blue-stroke" x1="580.77" y1="363.51" x2="214.32" y2="468.07"/>
                <line class="no-fill blue-stroke" x1="597.44" y1="421.94" x2="231" y2="526.5"/>
                <line class="no-fill blue-stroke" x1="614.11" y1="480.37" x2="247.67" y2="584.93"/>
                <line class="no-fill blue-stroke" x1="630.79" y1="538.8" x2="264.34" y2="643.36"/>
              </g>
            </g>
          </g>
            <g class="animate__animated animate__fadeIn _4">
            <rect x="327.88" y="223.93" class="black" width="214.17" height="53.82"/>
              <g>
              <defs>
                <rect id="SVGID_7_" x="327.88" y="223.93" width="214.17" height="53.82"/>
              </defs>
                <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_7_" style="overflow:visible;"/>
              </clipPath>
                <path style="clip-path:url(#SVGID_2_);fill:#F2F0EC;" d="M203.91,222.93l71.73,55.36h-5.82l-71.73-55.36H203.91z M226.65,222.93
				l71.73,55.36h5.82l-71.73-55.36H226.65z M240.93,222.93l71.73,55.36h5.82l-71.73-55.36H240.93z M255.21,222.93l71.73,55.36h5.82
				l-71.73-55.36H255.21z M269.49,222.93l71.73,55.36h5.82l-71.73-55.36H269.49z M212.37,222.93l71.73,55.36h5.82l-71.73-55.36
				H212.37z M283.66,222.93l71.73,55.36h5.82l-71.73-55.36H283.66z M312.22,222.93l71.73,55.36h5.82l-71.73-55.36H312.22z
				 M326.5,222.93l71.73,55.36h5.82l-71.73-55.36H326.5z M340.78,222.93l71.73,55.36h5.82l-71.73-55.36H340.78z M355.06,222.93
				l71.73,55.36h5.82l-71.73-55.36H355.06z M297.94,222.93l71.73,55.36h5.82l-71.73-55.36H297.94z M368.92,222.93l71.72,55.36h5.82
				l-71.72-55.36H368.92z M397.47,222.93l71.72,55.36h5.82l-71.72-55.36H397.47z M411.74,222.93l71.72,55.36h5.82l-71.72-55.36
				H411.74z M426.01,222.93l71.72,55.36h5.82l-71.72-55.36H426.01z M440.29,222.93L512,278.29h5.82l-71.72-55.36H440.29z
				 M383.2,222.93l71.72,55.36h5.82l-71.72-55.36H383.2z M454.44,222.93l71.72,55.36h5.82l-71.72-55.36H454.44z M482.99,222.93
				l71.72,55.36h5.82l-71.72-55.36H482.99z M497.26,222.93l71.72,55.36h5.82l-71.72-55.36H497.26z M511.53,222.93l71.72,55.36h5.82
				l-71.72-55.36H511.53z M525.8,222.93l71.72,55.36h5.82l-71.72-55.36H525.8z M468.72,222.93l71.72,55.36h5.82l-71.72-55.36H468.72
				z M226.09,167.56l71.72,55.36h5.82l-71.72-55.36H226.09z M254.63,167.56l71.72,55.36h5.82l-71.72-55.36H254.63z M268.9,167.56
				l71.72,55.36h5.82l-71.72-55.36H268.9z M283.18,167.56l71.72,55.36h5.82L289,167.56H283.18z M297.45,167.56l71.72,55.36h5.82
				l-71.72-55.36H297.45z M240.36,167.56l71.72,55.36h5.82l-71.72-55.36H240.36z M311.61,167.56l71.72,55.36h5.82l-71.72-55.36
				H311.61z M340.15,167.56l71.72,55.36h5.82l-71.72-55.36H340.15z M354.42,167.56l71.72,55.36h5.82l-71.72-55.36H354.42z
				 M368.69,167.56l71.72,55.36h5.82l-71.72-55.36H368.69z M382.97,167.56l71.72,55.36h5.82l-71.72-55.36H382.97z M325.88,167.56
				l71.72,55.36h5.82l-71.72-55.36H325.88z"/>
            </g>
              <g>
              <rect x="311.72" y="211" class="pure-white" width="216.94" height="54.53"/>
                <path class="black"
                      d="M527.67,212v52.53H312.72V212H527.67 M529.67,210H310.72v56.53h218.94V210L529.67,210z"/>
            </g>
          </g>
            <g id="circle_5_" class="animate__animated animate__fadeInTopRight _3">
            <circle class="black" cx="609.1" cy="273.51" r="150.44"/>
              <g>
              <circle class="yellow" cx="587.06" cy="242.71" r="149.44"/>
                <path class="black" d="M587.06,94.28c39.65,0,76.92,15.44,104.96,43.48c28.04,28.04,43.48,65.31,43.48,104.96
				s-15.44,76.92-43.48,104.96s-65.31,43.48-104.96,43.48c-39.65,0-76.92-15.44-104.96-43.48c-28.04-28.04-43.48-65.31-43.48-104.96
				s15.44-76.92,43.48-104.96C510.13,109.72,547.41,94.28,587.06,94.28 M587.06,92.28c-83.08,0-150.44,67.35-150.44,150.44
				c0,83.08,67.35,150.44,150.44,150.44S737.5,325.8,737.5,242.71C737.5,159.63,670.14,92.28,587.06,92.28L587.06,92.28z"/>
            </g>
          </g>
            <g id="triangle-lines_2_" class="animate__animated animate__fadeIn _3">
            <g>
              <path class="yellow" d="M23.25,468.66c2.61,2.65,5.22,5.31,7.83,7.96c8.35,8.47,16.7,16.92,25.06,25.38l9.89-2.25
				c-13.46-13.65-26.93-27.3-40.4-40.96L23.25,468.66z"/>
            </g>
              <g>
              <path class="yellow" d="M28.32,447.61c1.27,1.29,2.54,2.57,3.81,3.86c15.02,15.25,30.04,30.5,45.06,45.74l10.86-2.47
				c-19.04-19.3-38.08-38.6-57.13-57.92L28.32,447.61z"/>
            </g>
              <g>
              <path class="yellow" d="M33.59,425.7c3.5,3.55,6.99,7.1,10.49,10.64C57.46,449.9,70.87,463.44,84.25,477
				c5,5.06,9.99,10.13,14.98,15.2l11.24-2.56c-24.7-25.04-49.42-50.11-74.16-75.19L33.59,425.7z"/>
            </g>
              <g>
              <path class="yellow" d="M12.84,511.87l9.1-2.07c-2.31-2.34-4.61-4.67-6.91-7.01L12.84,511.87z"/>
            </g>
              <g>
              <path class="yellow"
                    d="M18.06,490.2c5.5,5.57,11,11.15,16.49,16.73l10.41-2.37c-8.13-8.24-16.26-16.49-24.4-24.74L18.06,490.2z"/>
            </g>
              <g>
              <path class="yellow" d="M75.61,251.28c10.52,10.67,21.04,21.33,31.55,32.01c13.46,13.66,26.9,27.35,40.35,41.02
				c12.7,12.9,25.43,25.78,38.14,38.67c9.18,9.31,18.35,18.64,27.53,27.95c14.98,15.18,29.96,30.35,44.94,45.52
				c5.26,5.33,10.52,10.65,15.79,15.97l10.11-2.3c-7.25-7.36-14.48-14.74-21.73-22.09c-24.07-24.39-48.16-48.76-72.22-73.15
				c-5.68-5.76-11.36-11.51-17.03-17.27l-95.64-93.74L75.61,251.28z"/>
            </g>
              <g>
              <path class="yellow" d="M81.79,419.37c14.93,15.14,29.9,30.24,44.84,45.37c5.7,5.77,11.4,11.55,17.1,17.33l10.11-2.3
				c-1.53-1.55-3.06-3.1-4.59-4.65C122.11,447.62,95,420.1,67.88,392.6c-7.05-7.15-14.1-14.29-21.14-21.44l-2.44,10.14
				C56.79,393.98,69.28,406.69,81.79,419.37z"/>
            </g>
              <g>
              <path class="yellow" d="M125.05,369.52c-19.59-19.87-39.15-39.77-58.74-59.64c-1.29-1.31-2.58-2.61-3.87-3.92l-2.54,10.56
				c12.05,12.2,24.12,24.39,36.17,36.6c17.99,18.23,35.95,36.49,53.93,54.73c16.39,16.61,32.81,33.2,49.2,49.82
				c3.16,3.2,6.31,6.42,9.47,9.63l10.43-2.37c-10.97-11.12-21.89-22.29-32.85-33.41C165.86,410.83,145.44,390.19,125.05,369.52z"/>
            </g>
              <g>
              <path class="yellow" d="M38.99,403.28c0.2,0.2,0.39,0.4,0.59,0.6c9.56,9.69,19.1,19.4,28.66,29.08
				c17.81,18.04,35.62,36.08,53.42,54.12l10.85-2.47c-30.3-30.72-60.6-61.44-90.92-92.18L38.99,403.28z"/>
            </g>
              <g>
              <path class="yellow" d="M196.55,415.44c-15.07-15.28-30.17-30.55-45.24-45.83c-15.16-15.37-30.31-30.74-45.47-46.11
				c-12.76-12.94-25.52-25.87-38.28-38.8l-2.45,10.16c0.48,0.48,0.95,0.97,1.43,1.45c17.38,17.61,34.74,35.23,52.11,52.85
				c15.35,15.56,30.72,31.11,46.08,46.68c14.36,14.56,28.7,29.12,43.06,43.68c7.51,7.62,15.02,15.24,22.53,22.85l10.18-2.32
				C225.85,445.17,211.2,430.3,196.55,415.44z"/>
            </g>
              <g>
              <path class="yellow" d="M167.49,358.42c-16.2-16.43-32.41-32.84-48.61-49.27c-15.33-15.54-30.65-31.09-45.97-46.64l-2.65,10.98
				c15.84,16.08,31.68,32.16,47.53,48.24c20.89,21.2,41.79,42.39,62.69,63.58c16.49,16.71,32.99,33.41,49.47,50.13
				c7.24,7.35,14.48,14.7,21.72,22.05l10.98-2.5c-12-12.16-24-24.33-35.99-36.5C206.93,398.47,187.22,378.44,167.49,358.42z"/>
            </g>
              <g>
              <path class="yellow" d="M91.68,402.84c16.81,17.04,33.6,34.11,50.41,51.15c7.64,7.75,15.3,15.49,22.95,23.23l11.01-2.51
				c-6.4-6.48-12.8-12.97-19.19-19.45c-23.22-23.54-46.41-47.11-69.63-70.65c-11.71-11.88-23.43-23.75-35.15-35.62l-2.66,11.03
				C63.51,374.28,77.6,388.56,91.68,402.84z"/>
            </g>
              <g>
              <path class="yellow" d="M90.79,374.38c10.64,10.8,21.32,21.56,31.96,32.36c10.87,11.03,21.71,22.08,32.58,33.11
				c10.63,10.78,21.27,21.54,31.91,32.32l10.22-2.33c-18.44-18.69-36.89-37.38-55.33-56.08c-22.75-23.06-45.5-46.12-68.24-69.19
				c-5.56-5.65-11.12-11.3-16.68-16.95l-2.45,10.17c2.61,2.64,5.22,5.29,7.83,7.93C72.01,355.26,81.38,364.84,90.79,374.38z"/>
            </g>
          </g>
            <g id="quid-blue-rect" class="animate__animated animate__fadeInBottomRight _3">

            <rect x="362.4" y="228.35" transform="matrix(-5.136627e-07 1 -1 -5.136627e-07 990.8873 77.7694)"
                  class="black" width="188.31" height="611.96"/>

              <rect x="344.34" y="204.84" transform="matrix(-5.136627e-07 1 -1 -5.136627e-07 949.3146 72.3155)"
                    class="blue black-stroke small-stroke" width="188.31" height="611.96"/>
          </g>
            <g id="quid-text-pink" class="animate__animated animate__fadeInRight _4">
            <polygon class="black" points="537.64,304.07 537.64,416.64 535.05,543.82 803.06,543.82 803.06,503.2 803.06,416.64 803.06,304.07
					"/>
              <polygon class="red black-stroke small-stroke"
                       points="526.29,285.72 524.5,529.78 791.71,529.78 791.71,488.42 791.71,400.31 791.71,285.72 		"/>
              <polygon class="no-fill" points="535.2,294.57 535.19,623.06 777.87,623.06 777.87,441.13 777.87,310.54 777.87,294.57
			664.68,294.57 		"/>
              <text transform="matrix(1 0 0 1 535.2035 307.7491)"><tspan x="0" y="0" class="lufga black svg-text-18">Before long, we had a </tspan>
                <tspan x="0" y="21" class="lufga black svg-text-18">partnership with </tspan>
                <tspan x="0" y="42" class="lufga black svg-text-18 ">	</tspan>
                <tspan x="40.33" y="42" class="lufga black svg-text-18">      </tspan>
                <tspan x="77.27" y="42" class="lufga black svg-text-18 ">	</tspan>
                <tspan x="80.69" y="42" class="lufga black svg-text-18 ">	</tspan>
                <tspan x="145" y="42" class="lufga black svg-text-18"> for a set of </tspan>
                <tspan x="0" y="63" class="lufga black svg-text-18">uniforms that I designed, </tspan>
                <tspan x="0" y="84" class="lufga black svg-text-18">and merch, flyers, and a </tspan>
                <tspan x="-0.01" y="105" class="lufga black svg-text-18">website that I spun up. </tspan>
                <tspan x="-0.01" y="126" class="lufga black svg-text-18">Merchandise sales both from </tspan>
                <tspan x="-0.01" y="147" class="lufga black svg-text-18">in-person tabling on campus </tspan>
                <tspan x="-0.01" y="168" class="lufga black svg-text-18">and online sales allowed us </tspan>
                <tspan x="-0.01" y="189" class="lufga black svg-text-18">to fund several out of state </tspan>
                <tspan x="-0.01" y="210" class="lufga black svg-text-18">tournaments for the team.</tspan></text>
              <a xlink:href="https://viiapparel.co/" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 535.6099 349.9364)" class="lufga black svg-text-18">Savage Apparel</text></a>
          </g>
            <g id="zigzag_4_" class="animate__animated animate__fadeIn _5 ">
            <polygon class="black" points="500.11,667.84 517.68,678.82 510.65,650.72 538.75,643.8 530.85,615.16 559.6,607.91 552.14,580.04
			580.02,573.89 561.8,562.69 534.58,569.5 541.82,598.04 513.29,605.28 522.12,633.68 492.65,640.84 		"/>
              <g>
              <polygon class="yellow" points="495.49,660.09 488.4,634.46 517.91,627.29 509.07,598.88 537.55,591.65 530.31,563.12
				556.14,556.66 571.9,566.34 545.41,572.18 552.89,600.08 524.12,607.33 532.02,635.97 503.95,642.88 510.62,669.55 			"/>
                <path class="black" d="M555.98,557.73l13.29,8.16l-23.04,5.08l-2.04,0.45l0.54,2.02l6.94,25.92l-26.79,6.75l-1.98,0.5l0.54,1.97
				l7.36,26.66l-26.1,6.42l-1.95,0.48l0.49,1.95l5.82,23.29l-12.7-7.94l-6.71-24.26l27.47-6.68l2.07-0.5l-0.63-2.03l-8.21-26.38
				l26.49-6.72l1.94-0.49l-0.49-1.94l-6.75-26.59L555.98,557.73 M556.31,555.58l-27.22,6.8l7.24,28.54l-28.54,7.24l8.84,28.4
				l-29.47,7.16l7.46,27l17.56,10.98l-7.02-28.1l28.1-6.91l-7.9-28.65l28.76-7.24l-7.46-27.88l27.88-6.15L556.31,555.58
				L556.31,555.58z"/>
            </g>
          </g>
            <g>
            <g id="quid-text-white" class="animate__animated animate__fadeInTopLeft _4">
              <rect x="47.05" y="48.38" class="black" width="249.63" height="293.21"/>
              <g>
                <rect x="37.55" y="40.74" class="white" width="245.63" height="289.21"/>
                <path class="black"
                      d="M281.18,42.74v285.21H39.55V42.74H281.18 M285.18,38.74H35.55v293.21h249.63V38.74L285.18,38.74z"/>
              </g>
              <polygon class="no-fill" points="49.42,337.2 49.42,52.33 160.36,52.33 150.18,91.35 166.12,125.92 203.77,138.7 212.15,167.43
				255.69,176.77 274.98,176.77 276.16,337.2 			"/>
              <text transform="matrix(1 0 0 1 49.418 65.5046)"><tspan x="0" y="0" class="lufga black svg-text-18">Throughout </tspan>
                <tspan x="0" y="21" class="lufga black svg-text-18">college, I </tspan>
                <tspan x="0" y="42" class="lufga black svg-text-18">played </tspan>
                <tspan x="0" y="84" class="lufga black svg-text-18">yes like the </tspan>
                <tspan x="0" y="105" class="lufga black svg-text-18">Harry Potter </tspan>
                <tspan x="0" y="126" class="lufga black svg-text-18">sport. When I was a </tspan>
                <tspan x="0" y="147" class="lufga black svg-text-18">freshman, the sport was </tspan>
                <tspan x="0" y="168" class="lufga black svg-text-18">still in the process of </tspan>
                <tspan x="0" y="189" class="lufga black svg-text-18">formalizing, and teams </tspan>
                <tspan x="0" y="210" class="lufga black svg-text-18">were only starting to think </tspan>
                <tspan x="0" y="231" class="lufga black svg-text-18">about things like uniforms </tspan>
                <tspan x="0" y="252" class="lufga black svg-text-18">and gear.</tspan></text>
              <a xlink:href="https://iqasport.org" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 50.0891 129.2729)" class="lufga black svg-text-18">Quidditch,</text></a>
            </g>
              <g id="snitch" class="animate__animated animate__fadeIn _6">
              <g>
                <path class="black" d="M258.38,193.89c-6.71,0-13.04-2.3-18.82-6.84c-6.23-4.9-9.93-11.47-11.01-19.52
					c-0.1-0.77-0.13-1.53-0.15-2.19c-0.02-0.44-0.03-0.84-0.07-1.18c-0.02-2.26,0.11-4,0.42-5.66c-10.25-0.86-18.4-2.38-25.66-4.77
					c-8.6-2.84-15.27-7.02-20.41-12.8c-3.82-4.29-6.34-9.34-7.48-15c-1.42-7.02,0.24-13.71,1.85-18.74
					c2.42-7.59,5.95-14.73,9.36-21.63c3.02-6.12,6.86-14.24,9.7-22.82c1.31-3.97,2.11-7.27,2.51-10.41c0.59-4.61,0-7.94-1.91-10.79
					c-0.56-0.83-1.26-1.55-2-2.32c-0.41-0.42-0.81-0.84-1.2-1.28c-1.22-1.39-0.75-2.37-0.13-2.94c0.51-0.48,1.04-0.58,1.39-0.58
					c0.41,0,1.02,0.13,1.67,0.78c2.83,2.84,5.34,5.91,7.77,8.89c5.49,6.73,9.58,13.27,12.49,20c2.21,5.1,3.88,10.7,5.12,17.11
					c0.82,4.25,1.51,8.57,2.04,12.85c0.41,3.25,0.69,6.65,0.84,10.12c0.21,4.93,0.45,8.96,0.75,12.69
					c0.41,5.11,0.95,10.52,2.58,15.69c1.31,4.17,3.76,7.84,7.7,11.53c5.33-5.99,11.93-9.43,19.62-10.22
					c1.25-0.13,2.49-0.19,3.69-0.19c8.78,0,16.42,3.43,22.68,10.21c0.08,0.09,0.16,0.18,0.24,0.25c0.07-0.06,0.15-0.13,0.21-0.19
					c4.6-4.15,7.42-9.21,8.63-15.47c1-5.17,1.41-10.49,1.7-15.46c0.09-1.45,0.16-2.9,0.24-4.35c0.24-4.67,0.5-9.5,1.1-14.24
					c0.92-7.22,2.12-15.6,4.7-23.69c2.73-8.56,7.18-16.71,13.6-24.89c3.09-3.94,5.99-7.31,8.88-10.33c0.15-0.15,0.3-0.3,0.46-0.44
					c0.45-0.41,0.96-0.63,1.47-0.63c0.34,0,0.86,0.1,1.34,0.58c0.81,0.8,0.8,1.94-0.01,2.82l-0.38,0.42
					c-0.61,0.66-1.21,1.32-1.84,1.97c-2.01,2.1-3.05,4.76-3.16,8.15c-0.16,4.88,1.11,9.56,2.51,13.88
					c2.99,9.23,7.27,18.12,11.39,26.44c3.23,6.51,6.51,13.59,8.65,21.28c1.34,4.81,1.8,9.17,1.42,13.33c-0.41,4.4-2,8.82-4.87,13.5
					c-3.38,5.52-8.43,9.88-15.88,13.74c-5.16,2.67-11.02,4.54-18.98,6.04c-4.54,0.86-9.23,1.47-14.3,1.88
					c0.33,1.83,0.5,3.67,0.49,5.47c-0.06,12.67-8.47,24.26-20.45,28.19C265.29,193.3,261.77,193.89,258.38,193.89z M257.54,189.9
					c0.31,0.02,0.63,0.03,0.97,0.03c0.35,0,0.71-0.01,1.06-0.02c-0.37-0.65-0.7-1.33-1.02-2.04
					C258.26,188.53,257.92,189.2,257.54,189.9z M261,186.44c0.42,1.05,0.98,2.11,1.69,3.2c1.95-0.32,3.75-0.79,5.41-1.42
					c-0.44-0.01-0.88-0.05-1.3-0.1C264.83,187.86,262.92,187.3,261,186.44z M249.48,188.24c1.55,0.58,3.19,1.01,4.93,1.3
					c0.02,0,0.05,0.01,0.08,0.01c0.57-0.9,1.16-1.93,1.61-3.1C253.75,187.51,251.6,188.1,249.48,188.24z M239.11,181.33
					c2.24,2.68,5.43,4.02,9.73,4.11l0.2,0c3.03,0,5.72-1.3,7.97-2.58c0.08-1.25,0.05-2.31-0.11-3.27c-0.65,0.24-1.32,0.45-1.97,0.66
					l-0.68,0.21c-2.69,0.85-5.2,1.26-7.7,1.26c-1.12,0-2.24-0.09-3.34-0.26c-1.77-0.27-3.46-0.72-5.02-1.22
					C238.4,180.47,239.11,181.33,239.11,181.33z M260.11,182.86c3.12,1.73,5.81,2.54,8.45,2.54c0,0,0,0,0,0
					c1.08,0,2.18-0.14,3.26-0.42c3.29-0.85,5.89-2.5,7.86-5.02l-0.33,0.11c-3.1,1.08-6,1.61-8.89,1.61c-1.18,0-2.37-0.09-3.54-0.27
					c-2.34-0.37-4.56-1.12-6.71-1.84c-0.19,1.16-0.29,2.18-0.12,3.19L260.11,182.86z M235.48,176.25c3.65,1.53,6.56,2.33,9.41,2.6
					c0.54,0.05,1.09,0.08,1.64,0.08c3.43,0,6.72-0.98,9.54-1.99c-0.5-1.06-1.14-2.07-1.94-3.07c-0.12-0.15-0.18-0.18-0.18-0.18
					c0.01,0.01-0.07,0.01-0.27,0.09c-2.97,1.19-5.98,1.8-8.94,1.8c-1.49,0-2.99-0.15-4.47-0.45c-2.11-0.43-4.19-1.18-6.41-2.33
					C234.29,173.92,234.82,175.06,235.48,176.25z M261.04,176.89c2.61,0.93,5.37,1.79,8.21,1.96c0.4,0.03,0.8,0.04,1.2,0.04
					c4.33,0,8.36-1.46,11.73-2.89c0.04-0.02,0.07-0.03,0.09-0.04c0.64-1.17,1.16-2.35,1.58-3.54c-3.57,1.93-6.99,2.95-10.43,3.09
					c-0.32,0.01-0.63,0.02-0.95,0.02c-3.04,0-6.07-0.6-9.27-1.85l-0.05-0.02C262.24,174.7,261.55,175.76,261.04,176.89z
					 M249.77,167.05c4.23,2.19,7.05,4.97,8.78,8.63c1.71-3.59,4.52-6.39,8.61-8.57c-3.83-0.7-6.9-3.08-8.54-6.61
					c-1.43,3.12-3.91,5.21-7.39,6.21C250.76,166.85,250.28,166.96,249.77,167.05z M232.96,169.02c3.86,2.49,7.83,3.75,11.78,3.75
					c2.27,0,4.61-0.41,6.94-1.23c-3.92-3-8.48-4.52-13.94-4.63l-0.27,0c-1.63,0-3.3,0.22-4.97,0.65
					C232.59,168.05,232.8,168.75,232.96,169.02z M265.41,171.53c2.3,0.75,4.31,1.14,6.27,1.22c0.24,0.01,0.48,0.01,0.72,0.01
					c3.82,0,7.54-1.17,11.71-3.69c0.62-0.37,0.87-0.73,0.97-1.44c-1.52-0.36-3.3-0.72-5.09-0.72c-0.17,0-0.35,0-0.52,0.01
					c-0.27-0.01-0.47-0.01-0.66-0.01c-1.45,0-2.96,0.19-4.61,0.57C270.72,168.28,267.84,169.61,265.41,171.53z M284.64,164.67
					c0.27,0.06,0.51,0.12,0.71,0.16c0.01-0.23,0.02-0.53,0.03-0.84c-0.46,0.19-0.93,0.37-1.39,0.54
					C284.22,164.58,284.43,164.63,284.64,164.67z M232.29,164.72c0.34-0.08,0.68-0.15,1.02-0.22c-0.34-0.13-0.68-0.27-1.03-0.41
					C232.29,164.31,232.29,164.52,232.29,164.72z M261.08,159.18c0.42,0.99,0.9,1.75,1.49,2.39c1.94,2.1,4.52,3.02,8.63,3.08
					c0.14-0.01,0.37-0.03,0.63-0.04c0.55-0.03,1.18-0.06,1.78-0.14c2.09-0.27,4.26-0.77,6.62-1.53c-2.6,0.76-5.23,1.14-7.86,1.14
					c-1.3,0-2.62-0.09-3.93-0.28c-3.24-0.46-5.42-1.76-6.66-3.98C261.44,159.24,261.25,159.19,261.08,159.18z M237.1,162.88
					c2.78,0.89,5.92,1.61,9.23,1.68c0.19,0.01,0.35,0.01,0.51,0.01h0c0.94,0,1.92-0.12,3.01-0.38c3.09-0.73,5.16-2.36,6.29-4.99
					c-0.09-0.02-0.17-0.04-0.25-0.05c-0.96,1.84-2.51,3.14-4.63,3.92c-1.78,0.66-3.69,0.97-5.84,0.97c-1.15,0-2.37-0.09-3.74-0.28
					C240,163.53,238.48,163.24,237.1,162.88z M285.15,161.03c-0.47,0.23-0.94,0.44-1.41,0.64c0.37-0.14,0.74-0.29,1.11-0.45
					c0.2-0.08,0.28-0.14,0.3-0.16C285.16,161.06,285.16,161.05,285.15,161.03z M264.15,158.47c0.6,0.87,1.46,1.52,2.6,1.97
					c1.37,0.55,3.01,0.87,4.48,0.87l0.16,0c2.8-0.01,5.2-0.32,7.8-1c1.53-0.4,2.86-0.82,4.06-1.41c-0.09,0-0.17,0.01-0.26,0.02
					c-1.07,0.07-2.14,0.14-3.2,0.19c-1.21,0.06-2.42,0.11-3.63,0.16c-1.64,0.07-3.28,0.14-4.91,0.23l-0.49,0.03
					c-0.39,0.03-0.78,0.05-1.16,0.05c-0.76,0-1.88-0.09-2.81-0.89c-0.6-0.51-1.04-0.57-1.32-0.57
					C265.1,158.12,264.68,158.23,264.15,158.47z M234.45,158.97c3.25,1.4,6.81,2.14,10.85,2.24l0.31-0.01
					c1.01-0.03,2.05-0.06,3.05-0.27c2.16-0.47,3.54-1.25,4.46-2.54c-0.37-0.12-0.71-0.29-1.03-0.47c-0.04-0.02-0.07-0.04-0.1-0.05
					c-0.01,0.01-0.04,0.04-0.09,0.08c-1.11,0.99-2.54,1.52-4.13,1.52l-0.14,0c-2.66-0.05-5.26-0.1-7.83-0.2
					c-1.4-0.06-2.8-0.14-4.2-0.22C235.21,159.02,234.83,158.99,234.45,158.97z M253.64,155.56c1.4,0.54,2.9,1.01,4.41,1.01
					c0.17,0,0.33,0,0.5-0.02c0.02,0,0.1,0,0.11,0c0.14,0.01,0.27,0.01,0.41,0.01c1.7,0,3.3-0.44,4.92-0.97
					c-0.16-0.37-0.25-0.73-0.3-1.08c-1.76,0.77-3.51,1.16-5.21,1.16c-1.55,0-3.09-0.32-4.58-0.96
					C253.85,155.02,253.75,155.31,253.64,155.56z M269.91,151c-1.68,0.01-2.95,1.14-2.95,2.64c0,0.69,0.26,1.33,0.75,1.83
					c0.56,0.57,1.35,0.88,2.21,0.88c1.62,0,2.95-1.19,2.97-2.65c0.01-0.68-0.26-1.32-0.74-1.81c-0.56-0.57-1.35-0.88-2.22-0.88v-1
					L269.91,151z M247.77,150.97c-0.85,0-1.62,0.31-2.18,0.87c-0.48,0.48-0.75,1.11-0.75,1.77c0,1.48,1.27,2.59,2.94,2.59
					c0.89,0,1.67-0.31,2.22-0.86c0.47-0.48,0.72-1.1,0.72-1.78c-0.01-1.44-1.3-2.57-2.93-2.58l-0.02-1V150.97z M236,155.03
					c1.99,0.08,3.92,0.19,5.85,0.3c-0.51-1.41-0.4-2.85,0.33-4.25c-1.58-0.3-3-0.66-4.38-1.23c0,0,0,0,0,0
					C235.85,151.25,235.35,152.7,236,155.03z M275.56,151.09c0.7,1.36,0.83,2.78,0.39,4.2c0.43-0.06,0.9-0.11,1.37-0.11
					c0.18,0,0.36,0.01,0.55,0.02c0.19,0.02,0.39,0.02,0.58,0.02c0.54,0,1.09-0.05,1.67-0.1c0.5-0.04,1.01-0.09,1.53-0.11
					c0.66-2.36,0.24-3.53-1.79-5.11l0.01-0.02C278.41,150.51,276.92,150.85,275.56,151.09z M224.47,154.09
					c3.18,0.32,6.06,0.58,8.79,0.79c-0.52-2.42,0.16-4.57,1.96-6.29c-1.48-0.91-2.84-2.06-4.14-3.49c-1.49,0.48-2.84,1.27-4.02,2.35
					c-1.89,1.72-2.79,3.86-2.74,6.54c0,0.04,0,0.06,0,0.09C224.36,154.08,224.41,154.09,224.47,154.09z M282.58,148.74
					c1.56,1.42,2.2,3.31,1.93,5.78c-0.01,0.1-0.03,0.19-0.05,0.28c0.05-0.01,0.11-0.01,0.17-0.01c2.84-0.09,5.45-0.32,7.98-0.71
					c0.2-0.03,0.44-0.07,0.6-0.1c0.01-0.18,0.01-0.44,0.01-0.67c-0.02-0.11-0.02-0.33-0.02-0.55c0-0.28,0-0.54-0.06-0.78
					c-0.7-3.25-2.79-5.43-6.57-6.86c-0.01,0.01-0.02,0.02-0.03,0.03c-1.29,1.4-2.59,2.5-3.96,3.36c-0.05,0.03-0.1,0.06-0.16,0.1
					C282.49,148.66,282.54,148.71,282.58,148.74z M212.74,152.21c3.14,0.64,6.27,1.13,8.96,1.54c-0.02-1.08,0.1-2.04,0.36-2.96
					c0.98-3.57,3.36-6.13,7.25-7.8c-0.1-0.12-0.19-0.24-0.29-0.36c-0.93-1.14-1.89-2.32-2.6-3.71c-0.21-0.01-0.39-0.01-0.57-0.01
					c-3.05,0-5.39,0.65-7.36,2.05c-3.37,2.39-5.21,5.86-5.94,11.22C212.6,152.18,212.67,152.2,212.74,152.21z M288.43,142.93
					c1.6,0.66,2.99,1.57,4.15,2.7c2.35,2.3,3.5,5.01,3.42,8.08c2.26-0.29,4.54-0.71,6.74-1.12c0.72-0.13,1.43-0.26,2.15-0.39
					c0.08-0.01,0.13-0.03,0.18-0.04c0-0.03,0-0.06-0.01-0.1c-0.26-1.96-0.64-3.54-1.22-4.98c-2.11-5.25-5.95-7.93-11.75-8.19
					l-0.43,0c-0.26,0-0.33,0.02-0.35,0.03c-0.82,1.46-1.84,2.72-2.82,3.94L288.43,142.93z M256.91,152.74
					c-0.64-4.85-2.74-9.41-6.39-13.9l-0.1,0.04c0.04,0.06,0.07,0.12,0.11,0.18c0.06,0.1,0.35,0.54,0.35,0.54
					c2.11,3.38,4.43,7.44,5.56,12.01c0.13,0.52,0.25,1.01,0.33,1.12c0,0,0.01,0,0.03,0C256.81,152.74,256.85,152.74,256.91,152.74z
					 M266.91,138.88c-0.1,0.02-0.17,0.04-0.19,0.06c-1.19,1.46-2.28,3.04-3.18,4.63c-1.72,3.04-2.75,6.01-3.16,9.06
					c0.13-0.07,0.24-0.14,0.31-0.2c0.32-1.5,0.73-2.88,1.25-4.2C263.09,145.31,264.63,142.41,266.91,138.88z M201.26,148.81
					c2.73,1.05,5.57,1.95,8.73,2.77c0.08-0.65,0.18-1.26,0.3-1.85c1.06-5.12,3.37-8.78,7.09-11.19c2.27-1.47,4.9-2.22,7.81-2.23
					c-0.14-0.35-0.27-0.7-0.4-1.04c-0.16-0.43-0.31-0.85-0.49-1.27c-0.2-0.46-0.28-0.96-0.35-1.39c-0.09-0.55-0.16-0.95-0.34-1.03
					c-0.5-0.24-1.22-0.25-1.85-0.27c-0.29,0-0.56-0.01-0.8-0.03c-2.77,0.12-4.89,0.37-6.87,1.19
					C207.3,135.28,203.11,140.63,201.26,148.81z M292.51,136.33c0.83,0,1.68,0.02,2.55,0.17c4.05,0.68,7.29,2.84,9.64,6.4
					c1.61,2.44,2.6,5.27,3.04,8.65c2.8-0.73,5.63-1.62,8.64-2.72c-0.73-4.16-2.57-7.93-5.5-11.24c-3.6-4.08-8.09-6.14-13.33-6.14
					c-0.19,0-0.6,0.01-0.62,0.01c-0.26,0-1.19-0.02-1.42-0.02c-0.29,0-0.58,0.01-0.87,0.03l-0.16,0.01
					c-0.14,0.01-0.35,0.02-0.41,0.05C293.74,133.02,293.23,134.56,292.51,136.33z M269.63,139.79c-1.31,2.05-2.68,4.26-3.8,6.54
					c-0.7,1.42-1.46,3.1-2.01,5.07c0.28-0.15,0.48-0.32,0.63-0.58c0.97-1.68,2.57-2.73,4.64-3.03c0.63-0.09,1.26-0.12,1.87-0.15
					c0.38-0.02,0.75-0.04,1.13-0.07c2.46-0.22,4.57-0.69,6.44-1.41C275.95,143.43,272.96,141.29,269.63,139.79z M239.14,146.11
					c0.74,0.3,1.44,0.54,2.11,0.71c2.31,0.58,4.69,0.79,6.93,0.95c2.33,0.17,4.09,1.25,5.07,3.13c0.04,0.07,0.08,0.14,0.13,0.21
					c-1.27-4.17-3.53-7.94-5.64-11.2C244.4,141.44,241.51,143.52,239.14,146.11z M253.46,138.07c1.67,2.15,3.08,4.52,4.2,7.05
					c0.37,0.84,0.7,1.71,0.99,2.64c1.1-3.41,2.84-6.62,5.26-9.74c-1.74-0.31-3.47-0.47-5.16-0.47
					C256.98,137.55,255.2,137.72,253.46,138.07z M190.8,142.97c2.56,1.91,5.23,3.5,7.99,4.77c0.34-1.85,0.95-3.66,1.86-5.56
					c2.92-6.1,7.01-10.08,12.48-12.17c2.35-0.89,4.8-1.35,7.29-1.35c0.84,0,1.69,0.05,2.54,0.15c-0.38-1.58-0.66-3.28-0.84-5.2
					c-0.01-0.13-0.03-0.19-0.04-0.22c0,0,0,0,0,0c-0.01,0-0.06-0.03-0.17-0.06c-2.23-0.73-4.19-1.09-6.01-1.09
					c-0.16,0-0.32,0-0.47,0.01c-0.29,0-0.52-0.01-0.75-0.01c-1.19,0-2.09,0.09-2.91,0.28C200.98,125.05,194.12,131.74,190.8,142.97z
					 M297.21,128.65c3.23,0,6.31,0.71,9.17,2.1c2.78,1.35,5.2,3.35,7.38,6.11c2.6,3.29,4.33,6.94,5.14,10.86
					c3.12-1.52,5.62-3,7.81-4.63c0.08-0.06,0.12-0.09,0.14-0.12c-0.01-0.01-0.02-0.07-0.04-0.15c-0.53-1.96-1.17-3.7-1.98-5.33
					c-2.06-4.16-4.9-7.6-8.44-10.23c-4.57-3.4-9.19-4.94-14.98-5l-0.2,0c-1.22,0-3.32,0.44-5.48,1.15c-0.05,0.01-0.1,0.03-0.15,0.05
					c-0.18,1.82-0.46,3.61-0.84,5.33C295.58,128.69,296.41,128.65,297.21,128.65z M211.93,112.74c-2.34,0-4.67,0.37-6.93,1.09
					c-9.91,3.17-17.08,9.99-21.91,20.83c-0.02,0.05-0.02,0.08,0.03,0.17c1.49,2.65,3.63,4.75,5.45,6.35
					c1.77-6.1,5.15-11.21,10.06-15.19c5.24-4.25,10.75-6.4,16.38-6.4c2.22,0,4.5,0.33,6.79,1c-0.17-1.96-0.34-3.97-0.46-5.96
					c-0.01-0.11-0.02-0.17-0.02-0.2c-0.01,0-0.05-0.02-0.11-0.04c-2.87-1.1-5.63-1.64-8.45-1.64l-0.37,0L211.93,112.74z
					 M302.31,119.54c3.22,0,6.49,0.7,9.71,2.08c4.61,1.97,8.61,5.12,11.91,9.37c2.44,3.15,4.19,6.55,5.2,10.13
					c2.24-1.92,3.96-3.88,5.25-5.97c0.22-0.36,0.2-0.5,0.09-0.73c-2.04-4.48-4.35-8.1-7.08-11.04c-2.37-2.57-4.8-4.6-7.41-6.22
					c-4.67-2.88-9.46-4.32-14.65-4.4c-0.25-0.02-0.44-0.02-0.64-0.02c-0.77,0-1.53,0.09-2.44,0.21c-2.17,0.29-4.07,0.78-5.79,1.49
					c-0.04,0.02-0.07,0.03-0.09,0.04c-0.02,1.04-0.08,2.15-0.19,3.51c-0.06,0.78-0.13,1.64-0.24,2.5c1.7-0.55,3.54-0.86,5.6-0.92
					C301.79,119.54,302.05,119.54,302.31,119.54z M209.65,102.22c-3.38,0-6.81,0.71-10.21,2.12c-8.4,3.48-15.13,9.56-20.56,18.58
					c-0.05,0.09-0.07,0.18-0.05,0.5c0.15,2.6,1.23,4.89,2.38,7.32c0.13,0.28,0.27,0.56,0.4,0.84c4.43-8.97,10.59-15.15,18.82-18.87
					c3.85-1.74,7.84-2.63,11.86-2.63c0.57,0,1.15,0.02,1.73,0.05c2.39,0.15,4.77,0.62,7.09,1.4c-0.11-1.85-0.22-3.77-0.32-5.91
					c-0.04-0.03-0.11-0.08-0.16-0.12c-2.69-1.91-5.77-2.99-9.2-3.24C210.84,102.24,210.24,102.22,209.65,102.22z M305.17,110.06
					c0.76,0,1.53,0.03,2.29,0.1c3.72,0.33,7.33,1.31,10.71,2.93c5.76,2.76,10.57,6.98,14.71,12.91c1.17,1.67,2.2,3.46,3.2,5.52
					c0.06-0.14,0.12-0.27,0.18-0.41c0.24-0.58,0.51-1.15,0.78-1.72c0.65-1.36,1.26-2.65,1.49-4.06c0.04-0.22,0.08-0.43,0.12-0.65
					c0.19-0.95,0.29-1.56-0.08-2.15c-2.62-4.11-5.32-7.46-8.28-10.25c-3.27-3.09-6.65-5.42-10.31-7.13
					c-4.17-1.94-8.04-2.88-11.84-2.88c-0.87,0-1.75,0.05-2.62,0.15c-3.1,0.36-5.84,1.36-8.37,3.06c-0.06,0.04-0.2,0.13-0.23,0.17
					c-0.18,2.18-0.3,4.08-0.35,5.88C299.38,110.56,302.27,110.06,305.17,110.06z M208.87,89.77c-3.33,0-6.72,0.84-10.36,2.57
					c-5.69,2.71-10.67,6.82-15.67,12.96c-1.44,1.76-2.36,3.7-2.84,5.92c-0.11,0.51-0.23,1.02-0.35,1.53
					c-0.19,0.8-0.37,1.55-0.51,2.32c-0.21,1.17-0.35,2.23-0.41,3.24c0.08-0.12,0.17-0.24,0.25-0.35
					c5.71-8.02,12.38-13.39,20.41-16.4c3.56-1.33,6.9-1.98,10.21-1.98c1.98,0,3.95,0.24,5.86,0.72c1.78,0.45,3.48,1.14,5.16,2.1
					c-0.19-2.65-0.38-5.2-0.72-7.74l-0.02-0.17c-0.03-0.27-0.05-0.34-0.09-0.38c-2.54-2.51-5.49-3.9-9-4.24
					C210.14,89.8,209.5,89.77,208.87,89.77z M308.16,99.52c3.05,0,6.17,0.56,9.27,1.67c7.93,2.83,14.52,7.78,20.14,15.13
					c0.46,0.6,0.9,1.22,1.33,1.84c-0.17-2.54-0.73-4.96-1.31-7.09c-0.08-0.28-0.15-0.57-0.22-0.85c-0.28-1.13-0.54-2.19-1.19-3.05
					c-3.16-4.21-6.1-7.33-9.23-9.81c-5.47-4.33-10.44-6.66-15.64-7.33c-0.96-0.12-1.89-0.19-2.77-0.19c-3.36,0-6.21,0.88-8.71,2.69
					c-0.16,0.12-0.33,0.24-0.51,0.35c-0.93,0.63-1.51,1.06-1.6,1.87c-0.06,0.53-0.1,1.06-0.14,1.59c-0.02,0.31-0.05,0.62-0.07,0.93
					c0,0-0.38,4.19-0.46,5.08c3.12-1.8,6.69-2.75,10.64-2.83L308.16,99.52z M208.21,78.7c-1.15,0-2.37,0.13-3.62,0.38
					c-4.15,0.84-7.97,2.86-12.02,6.35c-0.21,0.18-0.43,0.37-0.65,0.55c-1.13,0.95-2.19,1.84-2.81,3.06
					c-1.84,3.62-3.55,7.27-5.08,10.86c1.3-1.39,2.64-2.65,4.02-3.81c6.38-5.34,12.33-8.15,18.71-8.84c0.79-0.08,1.57-0.13,2.32-0.13
					c3.75,0,7.18,1.06,10.21,3.15c-0.07-0.43-0.13-0.86-0.19-1.29c-0.28-1.88-0.58-3.83-0.95-5.72c-0.06-0.28-0.14-0.44-0.32-0.61
					C215.08,80,211.93,78.7,208.21,78.7z M308.74,87.05c2.17,0,4.47,0.36,6.85,1.08c6.52,1.97,12.44,5.82,18.03,11.74
					c-1.02-2.39-2.12-4.75-3.21-7.06l-0.3-0.64c-0.15-0.33-0.3-0.66-0.44-1c-0.46-1.05-0.88-2.05-1.61-2.8l-0.41-0.42
					c-1.08-1.12-2.1-2.18-3.27-3.1c-3.71-2.92-6.83-4.59-10.1-5.44c-1.71-0.44-3.34-0.67-4.85-0.67c-3.08,0-5.76,0.91-8.18,2.78
					c-0.19,0.15-0.4,0.29-0.62,0.43c-0.62,0.4-0.97,0.65-1.04,1.09c-0.42,2.4-0.82,4.8-1.22,7.2c0.45-0.32,0.93-0.62,1.44-0.9
					C302.59,87.82,305.59,87.05,308.74,87.05z M207.8,69.28c-0.88,0-1.77,0.11-2.65,0.31c-3.87,0.91-6.94,3.19-9.43,5.31
					c-1.1,2.43-2.2,4.85-3.29,7.26c0.51-0.4,1.03-0.79,1.57-1.16c4.3-2.96,8.47-4.56,12.75-4.89c0.48-0.04,0.96-0.06,1.43-0.06
					c3.32,0,6.36,0.92,9.05,2.74c-0.57-2.53-1.18-4.78-1.84-6.87c-0.02-0.08-0.08-0.26-0.12-0.3
					C212.63,70.05,210.18,69.28,207.8,69.28z M309.67,76.02c3.97,0,7.88,1.23,12.32,3.86c1.15,0.68,2.21,1.4,3.19,2.15
					c-0.68-1.49-1.37-2.98-2.07-4.46c-0.16-0.34-0.3-0.69-0.44-1.04c-0.35-0.89-0.65-1.65-1.32-2.13c-0.38-0.27-0.76-0.55-1.14-0.83
					c-0.73-0.54-1.46-1.08-2.22-1.58c-2.41-1.61-4.92-2.51-7.47-2.67c-0.25-0.02-0.49-0.02-0.73-0.02c-2.44,0-4.85,0.78-7.36,2.4
					c-0.06,0.04-0.1,0.11-0.14,0.22c-0.72,2.23-1.3,4.55-1.84,6.83c2.28-1.57,4.93-2.46,7.89-2.67
					C308.8,76.03,309.24,76.02,309.67,76.02z M207.09,60.24c-2.04,0-3.98,0.82-6.31,2.65c-0.55,0.43-0.88,0.92-1.09,1.57
					c-0.62,1.98-1.34,3.92-2.02,5.64c0.17-0.11,0.33-0.22,0.5-0.33c3.25-2.07,6.49-3.12,9.64-3.12c2.06,0,4.13,0.45,6.16,1.34
					c-0.39-1.02-0.85-2.03-1.32-3.08c-0.33-0.73-0.65-1.46-0.96-2.19c-0.62-1.47-1.73-2.2-3.73-2.43
					C207.68,60.26,207.38,60.24,207.09,60.24z M309.88,66.64c1.93,0,3.89,0.39,5.85,1.15c1.53,0.6,2.93,1.39,4.23,2.26
					c-0.72-1.85-1.38-3.74-2.08-5.78c-0.03-0.09-0.06-0.19-0.09-0.29c-0.03-0.1-0.09-0.29-0.12-0.32c-2.5-2.31-4.7-3.35-7.16-3.35
					l-0.48-0.01c-0.04,0-0.09,0-0.13,0.01c-2.14,0.25-3.17,0.86-3.79,2.24l-0.59,1.29c-0.62,1.35-1.25,2.73-1.79,4.13
					C305.75,67.09,307.82,66.64,309.88,66.64z M205.55,54.5c-1.58,0-3.24,0.83-3.55,1.78c-0.33,1.01-0.58,2.06-0.83,3.15
					c2.09-1.26,4.03-1.85,5.97-1.85c0.61,0,1.23,0.06,1.84,0.18c-0.2-0.33-0.41-0.66-0.62-1c-0.31-0.5-0.62-1-0.92-1.51
					c-0.19-0.33-0.43-0.5-0.87-0.63C206.27,54.54,205.92,54.5,205.55,54.5z M310.51,57.54c1.22,0,2.48,0.25,3.75,0.74
					c0.79,0.3,1.51,0.65,2.17,1.04c-0.19-0.76-0.39-1.51-0.59-2.27c-0.04-0.16-0.07-0.33-0.08-0.5c-0.01-0.07-0.02-0.17-0.03-0.2
					c-1.03-1.08-1.99-1.85-3.18-1.88l-0.42-0.01c-0.9,0-1.35,0.14-1.63,0.5c-0.67,0.88-1.26,1.84-1.79,2.76
					C309.3,57.6,309.91,57.54,310.51,57.54z M202.77,48.71c0.01,1.23-0.06,2.49-0.2,3.81c0.86-0.48,1.53-0.67,2.53-0.67
					c0.01,0,0.01,0,0.02,0C204.34,50.76,203.56,49.71,202.77,48.71z M312.59,51.83c0.89,0.05,1.73,0.26,2.51,0.63
					c-0.12-1.21-0.16-2.47-0.13-3.83C314.14,49.7,313.34,50.75,312.59,51.83z"/>
                <path class="pure-white" d="M194.76,35.41c0.33,0,0.66,0.18,0.96,0.49c2.76,2.77,5.24,5.79,7.7,8.81c4.95,6.06,9.23,12.56,12.35,19.76
					c2.35,5.43,3.94,11.09,5.06,16.9c0.82,4.24,1.49,8.5,2.03,12.78c0.42,3.33,0.69,6.68,0.83,10.04c0.18,4.25,0.42,8.49,0.76,12.73
					c0.43,5.37,0.98,10.72,2.62,15.91c1.53,4.87,4.48,8.72,8.14,12.12c0.21,0.19,0.38,0.3,0.55,0.3c0.18,0,0.35-0.12,0.58-0.37
					c5.08-5.78,11.42-9.25,19.11-10.04c1.22-0.13,2.42-0.19,3.59-0.19c8.55,0,15.88,3.32,21.95,9.89c0.47,0.5,0.69,0.75,0.93,0.75
					c0.22,0,0.46-0.21,0.92-0.62c4.8-4.33,7.72-9.68,8.94-16.03c0.99-5.15,1.41-10.36,1.72-15.59c0.37-6.18,0.55-12.38,1.33-18.52
					c1.02-7.94,2.22-15.85,4.66-23.51c2.89-9.07,7.59-17.14,13.44-24.58c2.78-3.55,5.69-6.99,8.81-10.25
					c0.13-0.14,0.27-0.27,0.41-0.4c0.25-0.23,0.52-0.37,0.79-0.37c0.22,0,0.44,0.09,0.64,0.29c0.45,0.45,0.38,0.97-0.05,1.44
					c-0.73,0.8-1.46,1.59-2.21,2.37c-2.36,2.46-3.33,5.48-3.44,8.81c-0.16,4.92,1.07,9.61,2.56,14.22
					c2.99,9.21,7.16,17.92,11.45,26.58c3.38,6.82,6.53,13.74,8.58,21.1c1.18,4.25,1.79,8.57,1.38,12.98
					c-0.44,4.75-2.25,9.03-4.73,13.07c-3.75,6.11-9.28,10.16-15.48,13.38c-5.88,3.05-12.23,4.73-18.71,5.95
					c-4.86,0.92-9.77,1.53-14.7,1.9c-0.74,0.06-0.52,0.49-0.46,0.84c0.35,1.84,0.54,3.7,0.53,5.58
					c-0.06,12.21-8.15,23.44-19.76,27.25c-3.48,1.14-6.88,1.71-10.17,1.71c-6.47,0-12.56-2.2-18.2-6.63
					c-6.08-4.78-9.61-11.15-10.64-18.87c-0.17-1.24-0.12-2.47-0.22-3.35c-0.02-2.2,0.12-4.03,0.5-5.84c0.14-0.64-0.1-0.64-0.59-0.68
					c-8.77-0.72-17.44-1.99-25.83-4.76c-7.68-2.53-14.57-6.44-19.98-12.51c-3.68-4.14-6.14-9.03-7.25-14.53
					c-1.27-6.27-0.08-12.3,1.82-18.24c2.39-7.47,5.84-14.48,9.3-21.49c3.69-7.46,7.13-15.03,9.75-22.95
					c1.15-3.46,2.09-6.98,2.55-10.59c0.51-4.02,0.28-7.95-2.07-11.47c-0.92-1.38-2.19-2.46-3.28-3.71c-0.44-0.51-0.77-1.01-0.2-1.55
					C194.28,35.51,194.52,35.41,194.76,35.41 M316.13,45.51c-1.96,2.54-3.84,4.83-5.5,7.47c0.54-0.1,1.06-0.16,1.56-0.16
					c1.57,0,2.93,0.54,4.2,1.78C315.85,51.51,315.87,48.63,316.13,45.51 M201.29,54.5c1.96-1.29,2.52-1.65,3.8-1.65
					c0.51,0,1.14,0.06,2.01,0.14c-1.73-2.58-3.53-4.98-5.52-7.26C201.93,48.64,201.78,51.5,201.29,54.5 M307.15,59.25
					c0.18,0,0.39-0.15,0.56-0.21c0.94-0.34,1.88-0.5,2.81-0.5c1.14,0,2.26,0.24,3.39,0.67c1.31,0.51,2.52,1.17,3.59,2.09
					c0.1,0.08,0.18,0.21,0.32,0.21c0.02,0,0.05,0,0.08-0.01c0.06-0.02,0.08-0.14,0.11-0.19c-0.05-0.13-0.09-0.21-0.11-0.3
					c-0.36-1.41-0.71-2.82-1.09-4.22c-0.1-0.36-0.01-0.75-0.31-1.07c-1.08-1.16-2.28-2.21-3.92-2.25c-0.15,0-0.3-0.01-0.45-0.01
					c-0.88,0-1.82,0.11-2.42,0.89c-0.98,1.28-1.78,2.69-2.55,4.11c-0.12,0.21-0.38,0.49-0.22,0.68
					C306.99,59.23,307.07,59.25,307.15,59.25 M199.62,61.72c2.39-1.85,4.84-3.13,7.52-3.13c1.17,0,2.39,0.25,3.67,0.8
					c0.07,0.02,0.13,0.04,0.2,0.06c-0.03-0.06-0.05-0.11-0.08-0.17c-0.72-1.59-1.74-3.01-2.61-4.51c-0.35-0.6-0.82-0.91-1.46-1.09
					c-0.41-0.12-0.85-0.17-1.3-0.17c-1.93,0-4.02,1-4.5,2.47C200.45,57.79,200.11,59.69,199.62,61.72 M195.75,72.68
					c0.99-0.69,1.96-1.41,2.97-2.05c2.98-1.9,6.01-2.96,9.1-2.96c2.37,0,4.78,0.63,7.24,1.98c0.14,0.08,0.27,0.19,0.43,0.19
					c0.05,0,0.1-0.01,0.16-0.04c0,0,0,0,0,0c0.01,0.01,0.02,0.01,0.03,0.02c0.01,0.02,0.02,0.05,0.02,0.07
					c0-0.02,0.02-0.04,0.01-0.05c-0.01-0.01-0.02-0.02-0.03-0.03c0-0.01-0.01-0.02-0.01-0.03c-0.01,0-0.01,0.01-0.02,0.01
					c-0.73-2.6-1.98-4.98-3.02-7.46c-0.87-2.08-2.53-2.81-4.54-3.04c-0.34-0.04-0.67-0.06-1-0.06c-2.64,0-4.85,1.23-6.93,2.87
					c-0.7,0.55-1.15,1.2-1.42,2.05c-0.84,2.66-1.85,5.25-2.9,7.83C195.76,72.19,195.5,72.4,195.75,72.68 M302.37,69.73
					c0.23,0,0.46-0.28,0.7-0.35c0.18-0.05,0.34-0.17,0.51-0.25c2.08-0.99,4.18-1.49,6.3-1.49c1.81,0,3.64,0.36,5.48,1.08
					c2.24,0.87,4.2,2.2,6.11,3.62c0.11,0.08,0.21,0.25,0.34,0.25c0.04,0,0.08-0.01,0.12-0.05c0.19-0.15-0.02-0.35-0.09-0.51
					c-1.15-2.65-2.1-5.37-3.04-8.09c-0.12-0.35-0.17-0.74-0.47-1.02c-2.22-2.06-4.68-3.61-7.84-3.61c-0.02,0-0.04,0-0.06,0
					c-0.14,0-0.28-0.01-0.42-0.01c-0.08,0-0.16,0-0.24,0.01c-1.96,0.23-3.67,0.79-4.58,2.82c-0.99,2.2-2.05,4.36-2.83,6.65
					c-0.1,0.29-0.47,0.61-0.21,0.86C302.22,69.7,302.3,69.73,302.37,69.73 M189.78,85.6c0.06-0.03,0.13-0.06,0.19-0.09
					c1.51-1.26,2.97-2.57,4.6-3.68c3.71-2.56,7.73-4.37,12.26-4.72c0.46-0.04,0.91-0.05,1.35-0.05c3.65,0,6.96,1.17,9.85,3.6
					c0.15,0.12,0.26,0.35,0.47,0.35c0.04,0,0.09-0.01,0.14-0.03c0.06-0.03,0.03-0.3,0-0.45c-0.63-3-1.37-5.97-2.3-8.9
					c-0.1-0.33-0.22-0.65-0.55-0.85c-2.53-1.52-5.16-2.5-7.99-2.5c-0.94,0-1.9,0.11-2.88,0.34c-3.82,0.9-6.92,3.04-9.85,5.52
					c-0.12,0.1-0.21,0.24-0.27,0.38c-1.65,3.63-3.29,7.26-4.94,10.89C189.84,85.47,189.81,85.53,189.78,85.6 M299.18,80.9
					c0.1,0,0.19-0.12,0.28-0.17c0.16-0.1,0.32-0.2,0.47-0.32c2.46-2.07,5.35-3.12,8.51-3.35c0.42-0.03,0.83-0.04,1.24-0.04
					c4.27,0,8.14,1.55,11.8,3.72c1.99,1.18,3.85,2.55,5.51,4.17c0.11,0.11,0.21,0.25,0.37,0.25c0.03,0,0.07-0.01,0.1-0.02
					c0.06-0.02,0.09-0.15,0.14-0.24c-0.1-0.21-0.19-0.41-0.28-0.61c-1.1-2.39-2.18-4.79-3.31-7.16c-0.59-1.24-0.85-2.68-2.07-3.56
					c-1.13-0.81-2.23-1.66-3.39-2.43c-2.41-1.61-5.07-2.65-7.96-2.84c-0.27-0.02-0.53-0.03-0.8-0.03c-2.85,0-5.47,1-7.9,2.55
					c-0.28,0.18-0.45,0.45-0.55,0.76c-0.94,2.92-1.63,5.91-2.32,8.89c-0.03,0.12-0.04,0.29,0.03,0.36
					C299.1,80.89,299.14,80.9,299.18,80.9 M181.04,104.64c0.36-0.02,0.45-0.21,0.56-0.35c2.12-2.71,4.45-5.22,7.09-7.43
					c5.31-4.44,11.12-7.85,18.18-8.61c0.75-0.08,1.49-0.12,2.21-0.12c3.75,0,7.2,1.09,10.31,3.46c0.37,0.28,0.65,0.69,1.17,0.86
					c0.01-0.15,0.05-0.27,0.03-0.38c-0.5-3-0.87-6.01-1.46-8.99c-0.09-0.47-0.27-0.82-0.6-1.13c-3.03-2.93-6.5-4.24-10.31-4.24
					c-1.24,0-2.51,0.14-3.82,0.4c-4.79,0.97-8.82,3.43-12.47,6.58c-1.36,1.17-2.86,2.25-3.7,3.92c-1.89,3.73-3.67,7.51-5.3,11.36
					C182.27,101.49,181.68,103.04,181.04,104.64 M297.23,92.32c0.09,0,0.25-0.14,0.35-0.22c0.87-0.69,1.76-1.35,2.73-1.88
					c2.75-1.51,5.57-2.16,8.43-2.16c2.17,0,4.36,0.38,6.56,1.04c7.67,2.31,13.73,7.05,18.96,12.98c0.68,0.77,1.18,1.72,2.11,2.26
					c0.02,0.08,0.05,0.17,0.07,0.25c0.02-0.02,0.05-0.03,0.07-0.05c-0.05-0.07-0.09-0.13-0.14-0.2c0.01-0.6-0.33-1.08-0.53-1.61
					c-1.41-3.74-3.13-7.34-4.82-10.96c-0.66-1.4-1.14-2.93-2.24-4.07c-1.21-1.25-2.4-2.53-3.78-3.62c-3.16-2.48-6.53-4.6-10.47-5.62
					c-1.73-0.45-3.44-0.7-5.1-0.7c-3.1,0-6.05,0.87-8.79,2.98c-0.77,0.59-1.83,1-2.03,2.14c-0.52,2.98-1.01,5.96-1.51,8.95
					c-0.03,0.17-0.11,0.41,0.09,0.5C297.21,92.32,297.22,92.32,297.23,92.32 M177.74,121.58c0.75-1.12,1.38-2.1,2.05-3.04
					c5.14-7.22,11.55-12.89,19.94-16.04c3.23-1.21,6.51-1.92,9.86-1.92c1.86,0,3.73,0.22,5.62,0.69c2.32,0.58,4.46,1.59,6.53,3.02
					c-0.23-3.36-0.44-6.57-0.86-9.77c-0.05-0.39-0.05-0.79-0.4-1.13c-2.67-2.64-5.87-4.16-9.61-4.53c-0.67-0.07-1.34-0.1-2-0.1
					c-3.78,0-7.35,1.03-10.79,2.67c-6.44,3.06-11.55,7.75-16.02,13.23c-1.55,1.9-2.54,3.98-3.04,6.34c-0.28,1.3-0.64,2.58-0.87,3.89
					C177.77,117.04,177.55,119.2,177.74,121.58 M296.24,104.05c0.19,0,0.35-0.23,0.5-0.33c3.34-2.16,7.05-3.12,10.97-3.19
					c0.15,0,0.3,0,0.45,0c3.06,0,6.05,0.58,8.94,1.61c8.09,2.88,14.5,8.02,19.68,14.8c1.09,1.42,2.06,2.93,3.1,4.4
					c0.3-3.63-0.39-7.09-1.32-10.52c-0.39-1.46-0.62-2.98-1.57-4.24c-2.76-3.68-5.78-7.13-9.41-10c-4.78-3.79-9.95-6.74-16.13-7.54
					c-0.98-0.13-1.95-0.19-2.9-0.19c-3.32,0-6.45,0.82-9.3,2.88c-1.05,0.76-2.33,1.39-2.51,2.91c-0.1,0.85-0.14,1.7-0.22,2.55
					c-0.18,2.01-0.37,4.02-0.54,6.03c-0.02,0.27-0.16,0.62,0.11,0.79C296.14,104.04,296.19,104.05,296.24,104.05 M181.57,133.98
					c0.21-0.44,0.36-0.75,0.5-1.06c4.04-8.58,9.99-15.32,18.76-19.28c3.66-1.65,7.46-2.54,11.45-2.54c0.55,0,1.11,0.02,1.66,0.05
					c2.59,0.16,5.07,0.69,7.49,1.58c0.19,0.07,0.34,0.12,0.46,0.12c0.19,0,0.28-0.13,0.25-0.56c-0.15-2.25-0.27-4.5-0.37-6.75
					c-0.02-0.47-0.32-0.65-0.63-0.87c-2.89-2.05-6.15-3.12-9.65-3.38c-0.62-0.05-1.24-0.07-1.85-0.07c-3.65,0-7.18,0.79-10.59,2.2
					c-9.26,3.84-15.94,10.53-21.04,18.98c-0.21,0.34-0.22,0.68-0.2,1.08C178.05,127.32,180.13,130.46,181.57,133.98 M295.89,112.79
					c0.11,0,0.26-0.04,0.43-0.1c2.88-1.08,5.81-1.63,8.85-1.63c0.73,0,1.47,0.03,2.21,0.1c3.65,0.32,7.09,1.27,10.36,2.84
					c5.95,2.85,10.58,7.22,14.32,12.58c1.56,2.24,2.84,4.64,4.01,7.34c0.42-0.92,0.79-1.66,1.1-2.41c0.83-1.98,1.98-3.84,2.34-6
					c0.19-1.17,0.63-2.34-0.1-3.5c-2.42-3.8-5.14-7.34-8.43-10.44c-3.16-2.99-6.64-5.48-10.58-7.31c-3.93-1.83-7.97-2.97-12.26-2.97
					c-0.9,0-1.81,0.05-2.73,0.16c-3.22,0.37-6.14,1.43-8.82,3.23c-0.33,0.22-0.6,0.42-0.65,0.87c-0.2,2.2-0.35,4.41-0.39,6.63
					C295.54,112.62,295.65,112.79,295.89,112.79 M295.13,121.76c0.13,0,0.3-0.04,0.5-0.12c1.91-0.71,3.92-1.02,5.94-1.09
					c0.25-0.01,0.49-0.01,0.74-0.01c3.23,0,6.32,0.71,9.32,2c4.66,1.99,8.43,5.09,11.51,9.06c2.46,3.16,4.26,6.66,5.23,10.56
					c0.08,0.33,0.17,0.49,0.32,0.49c0.11,0,0.25-0.09,0.46-0.25c2.36-1.94,4.46-4.11,6.08-6.73c0.37-0.59,0.42-1.09,0.15-1.67
					c-1.87-4.12-4.17-7.97-7.25-11.31c-2.26-2.45-4.77-4.62-7.62-6.39c-4.66-2.88-9.68-4.46-15.16-4.54
					c-0.22-0.02-0.44-0.02-0.65-0.02c-0.86,0-1.72,0.11-2.57,0.22c-2.08,0.28-4.1,0.75-6.04,1.56c-0.39,0.16-0.7,0.37-0.71,0.89
					c-0.02,1.17-0.1,2.34-0.19,3.5c-0.08,1.04-0.16,2.08-0.33,3.1C294.75,121.55,294.84,121.76,295.13,121.76 M188.98,142.7
					c0.16,0,0.25-0.16,0.35-0.52c1.64-6.21,4.96-11.39,9.93-15.42c4.82-3.91,10.04-6.18,15.75-6.18c2.33,0,4.74,0.38,7.24,1.18
					c0.16,0.05,0.29,0.08,0.38,0.08c0.3,0,0.26-0.26,0.23-0.64c-0.19-2.21-0.39-4.42-0.52-6.64c-0.04-0.59-0.2-0.9-0.77-1.12
					c-2.84-1.09-5.76-1.7-8.8-1.7c-0.13,0-0.26,0-0.39,0c-0.15,0-0.3,0-0.45,0c-2.47,0-4.88,0.39-7.23,1.14
					c-10.94,3.5-17.96,11.15-22.52,21.38c-0.17,0.38-0.14,0.69,0.07,1.07c1.59,2.82,3.82,5.05,6.26,7.11
					C188.72,142.61,188.87,142.7,188.98,142.7 M293.95,129.87c0.08,0,0.16-0.01,0.26-0.02c1.01-0.13,2.01-0.2,3-0.2
					c3.02,0,5.94,0.63,8.74,1.99c2.81,1.37,5.1,3.39,7.04,5.83c2.58,3.26,4.31,6.92,5.06,11.02c0.07,0.4,0.16,0.54,0.32,0.54
					c0.11,0,0.25-0.06,0.44-0.15c2.98-1.43,5.85-3.02,8.52-4.99c0.55-0.41,0.62-0.76,0.47-1.33c-0.51-1.9-1.17-3.75-2.05-5.51
					c-2.09-4.21-4.97-7.79-8.74-10.59c-4.29-3.19-9.06-5.13-15.57-5.2c-0.07,0-0.14,0-0.21,0c-1.39,0-3.61,0.48-5.79,1.2
					c-0.39,0.13-0.77,0.25-0.83,0.79c-0.19,2.01-0.52,4-0.98,5.97C293.51,129.72,293.61,129.87,293.95,129.87 M215.4,121.26
					c-0.24-0.01-0.48-0.01-0.72-0.01c-1.08,0-2.12,0.07-3.13,0.3c-11.52,2.69-18.45,10.07-21.73,21.21
					c-0.13,0.46-0.01,0.71,0.33,0.96c2.78,2.08,5.75,3.83,8.92,5.23c0.14,0.06,0.25,0.11,0.34,0.11c0.13,0,0.2-0.11,0.25-0.41
					c0.3-2.12,0.98-4.12,1.9-6.04c2.56-5.34,6.27-9.51,11.93-11.67c2.26-0.86,4.58-1.28,6.94-1.28c0.92,0,1.85,0.06,2.78,0.19
					c0.14,0.02,0.26,0.03,0.36,0.03c0.55,0,0.63-0.28,0.46-0.94c-0.46-1.78-0.73-3.6-0.91-5.42c-0.06-0.65-0.29-0.94-0.89-1.13
					c-2.08-0.68-4.19-1.14-6.32-1.14C215.74,121.26,215.57,121.26,215.4,121.26 M210.58,152.74c0.22,0,0.3-0.14,0.33-0.46
					c0.08-0.79,0.2-1.57,0.36-2.35c0.9-4.35,2.82-8.07,6.65-10.55c2.23-1.44,4.69-2.07,7.3-2.07c0.22,0,0.44,0,0.66,0.01
					c0.07,0,0.14,0.01,0.21,0.01c0.45,0,0.62-0.1,0.31-0.73c-0.47-0.96-0.76-2.01-1.19-2.99c-0.43-0.98-0.16-2.44-1.17-2.92
					c-0.97-0.46-2.23-0.31-3.06-0.4c-2.69,0.12-5.06,0.35-7.28,1.27c-7.76,3.22-11.74,9.39-13.47,17.3c-0.1,0.46,0.1,0.68,0.52,0.84
					c3.13,1.21,6.33,2.19,9.58,3.01C210.43,152.72,210.51,152.74,210.58,152.74 M307.19,152.69c0.09,0,0.2-0.02,0.35-0.06
					c3.19-0.81,6.33-1.81,9.42-2.95c0.35-0.13,0.53-0.28,0.45-0.77c-0.74-4.57-2.75-8.56-5.78-11.99
					c-3.74-4.23-8.44-6.48-14.08-6.48c-0.2,0-0.41,0-0.61,0.01c-0.48,0-0.95-0.02-1.43-0.02c-0.32,0-0.64,0.01-0.95,0.03
					c-0.56,0.05-1.21,0.02-1.41,0.81c-0.47,1.88-1.14,3.69-1.89,5.47c-0.23,0.54-0.02,0.57,0.43,0.57c1.07,0.01,2.14-0.02,3.22,0.16
					c3.89,0.66,6.83,2.72,8.97,5.96c1.73,2.62,2.58,5.55,2.93,8.64C306.85,152.49,306.9,152.69,307.19,152.69 M258.65,151.93
					c0.93-5.38,3.27-9.87,6.53-13.91c0.4-0.5,0.47-0.68-0.26-0.83c-2.06-0.43-4.11-0.65-6.17-0.65c-2.12,0-4.24,0.24-6.36,0.73
					c-0.69,0.16-0.59,0.34-0.24,0.76c1.88,2.28,3.4,4.78,4.59,7.49C257.62,147.52,258.21,149.6,258.65,151.93 M257.69,153.83
					c0.28,0,0.33-0.12,0.31-0.33c-0.01-0.17-0.02-0.23-0.02-0.3c-0.63-5.79-3.17-10.72-6.82-15.16c-0.11-0.13-0.21-0.32-0.4-0.32
					c-0.04,0-0.08,0.01-0.13,0.02c-0.56,0.2-1.12,0.41-1.67,0.61c0.24,0.41,0.48,0.82,0.72,1.22c0.11,0.19,0.24,0.37,0.36,0.56
					c2.3,3.68,4.38,7.46,5.43,11.72c0.44,1.79,0.43,1.77,2.06,1.96C257.58,153.82,257.64,153.83,257.69,153.83 M259.75,153.85
					c0.65,0,1.78-0.61,1.88-1.09c0.3-1.42,0.7-2.81,1.23-4.17c1.35-3.43,3.19-6.59,5.2-9.66c0.13-0.2,0.48-0.39,0.26-0.69
					c-0.2-0.26-0.67-0.39-1.14-0.39c-0.48,0-0.96,0.13-1.17,0.39c-1.25,1.52-2.37,3.12-3.34,4.84c-1.76,3.11-2.95,6.4-3.35,9.94
					c0.04,0.27-0.13,0.58,0.11,0.75C259.51,153.82,259.62,153.85,259.75,153.85 M295.53,154.75c0.09,0,0.19-0.01,0.31-0.02
					c3.1-0.38,6.16-1,9.23-1.55c0.84-0.15,1.08-0.51,0.98-1.26c-0.23-1.79-0.61-3.54-1.29-5.22c-2.42-6.01-6.86-8.56-12.63-8.81
					c-0.02,0-0.04,0-0.06,0c-0.14,0-0.28,0-0.42,0c-0.48,0-0.94,0.06-1.23,0.56c-0.87,1.55-2.02,2.91-3.13,4.29
					c-0.4,0.5-0.47,0.66,0.24,0.92c1.62,0.59,3.11,1.48,4.34,2.68c2.11,2.06,3.26,4.57,3.11,7.58
					C294.97,154.45,294.95,154.75,295.53,154.75 M222.32,154.83c0.32,0,0.43-0.15,0.4-0.59c-0.07-1.08,0.01-2.15,0.3-3.2
					c1.05-3.79,3.73-6,7.22-7.37c0.64-0.25,0.61-0.36,0.23-0.84c-1.14-1.44-2.39-2.8-3.21-4.47c-0.16-0.32-0.42-0.44-0.77-0.45
					c-0.21-0.01-0.43-0.01-0.64-0.01c-2.84,0-5.56,0.54-7.94,2.23c-4.04,2.87-5.71,7.03-6.37,12.09c-0.2,0.53,0.15,0.8,1,0.97
					c3.16,0.64,6.34,1.15,9.54,1.62C222.17,154.83,222.25,154.83,222.32,154.83 M262.45,152.8
					C262.45,152.8,262.45,152.8,262.45,152.8c0,0-0.05,0.18,0.1,0.18c0.04,0,0.1-0.01,0.17-0.04c0.93-0.4,2-0.59,2.59-1.61
					c0.86-1.51,2.24-2.3,3.92-2.54c0.97-0.14,1.96-0.13,2.94-0.22c2.53-0.23,5-0.72,7.35-1.73c0.51-0.22,0.6-0.4,0.19-0.85
					c-2.75-3.06-5.97-5.45-9.74-7.13c-0.2-0.09-0.36-0.14-0.5-0.14c-0.24,0-0.43,0.14-0.64,0.46c-1.4,2.18-2.76,4.39-3.9,6.72
					C263.88,148.02,262.97,150.19,262.45,152.8 M254.63,153.02c0.13,0,0.21-0.09,0.15-0.36c-0.03-0.21-0.03-0.37-0.07-0.52
					c-1.2-4.83-3.69-9.03-6.36-13.15c-0.12-0.18-0.23-0.24-0.35-0.24c-0.11,0-0.23,0.05-0.35,0.11c-3.79,1.69-7.08,4.04-9.8,7.19
					c-0.3,0.34-0.26,0.47,0.15,0.66c0.97,0.45,1.97,0.82,3,1.08c2.33,0.59,4.72,0.81,7.11,0.98c1.85,0.13,3.39,0.93,4.26,2.6
					c0.45,0.86,1.2,1.15,1.93,1.55C254.41,152.97,254.54,153.02,254.63,153.02 M234.06,155.94c0.39,0,0.49-0.13,0.32-0.72
					c-0.77-2.65,0.03-4.8,2.19-6.47c0.12-0.09,0.23-0.18,0.41-0.33c-2.08-1.04-3.77-2.45-5.26-4.12c-0.16-0.19-0.32-0.25-0.49-0.25
					c-0.13,0-0.27,0.04-0.43,0.09c-1.66,0.54-3.12,1.4-4.4,2.57c-2.15,1.95-3.12,4.41-3.07,7.3c0.01,0.7,0.24,1.01,1.05,1.09
					c3.12,0.31,6.25,0.59,9.38,0.83C233.87,155.93,233.97,155.94,234.06,155.94 M283.69,156c0.3,0,0.67-0.2,0.98-0.21
					c2.71-0.09,5.41-0.31,8.1-0.72c1.46-0.22,1.46-0.23,1.46-1.77c-0.05-0.46,0.02-0.99-0.1-1.53c-0.87-4.02-3.59-6.24-7.24-7.6
					c-0.14-0.05-0.27-0.08-0.38-0.08c-0.27,0-0.47,0.15-0.69,0.38c-1.12,1.22-2.35,2.31-3.76,3.19c-1.23,0.77-1.26,0.82-0.16,1.82
					c1.5,1.36,1.81,3.06,1.61,4.94c-0.05,0.47-0.4,1.05-0.22,1.36C283.4,155.95,283.54,156,283.69,156 M242.89,156.39
					c0.41,0,0.54-0.11,0.21-0.71c-0.89-1.63-0.7-3.23,0.33-4.75c0.12-0.18,0.23-0.36,0.37-0.58c-2-0.32-3.92-0.7-5.73-1.46
					c-0.12-0.05-0.22-0.07-0.32-0.07c-0.2,0-0.36,0.1-0.54,0.23c-2.31,1.65-2.95,3.55-2.17,6.3c0.14,0.48,0.32,0.66,0.82,0.68
					c2.25,0.09,4.5,0.22,6.75,0.35C242.72,156.39,242.81,156.39,242.89,156.39 M274.99,156.42c0.1,0,0.22-0.01,0.35-0.02
					c0.65-0.11,1.31-0.22,1.97-0.22c0.16,0,0.31,0.01,0.47,0.02c0.22,0.02,0.44,0.03,0.66,0.03c1.14,0,2.27-0.2,3.41-0.21
					c0.39,0,0.59-0.19,0.69-0.52c0.82-2.76,0.44-4.42-2.08-6.37c-0.2-0.16-0.38-0.25-0.59-0.25c-0.13,0-0.27,0.03-0.43,0.1
					c-1.6,0.69-3.31,1.02-5.03,1.29c-0.51,0.08-0.5,0.12-0.21,0.52c1.08,1.5,1.33,3.15,0.55,4.84
					C274.45,156.28,274.61,156.42,274.99,156.42 M247.79,157.2c0.01,0,0.02,0,0.03,0c2.23-0.01,3.92-1.59,3.91-3.65
					c-0.02-1.98-1.75-3.56-3.92-3.58c-0.01,0-0.02,0-0.03,0c-2.18,0-3.92,1.61-3.92,3.64C243.85,155.65,245.55,157.2,247.79,157.2
					 M269.92,157.34C269.93,157.34,269.93,157.34,269.92,157.34c2.19,0,3.95-1.62,3.97-3.64c0.02-2.07-1.73-3.7-3.96-3.7
					c-0.01,0-0.02,0-0.03,0c-2.22,0.01-3.94,1.59-3.95,3.63C265.95,155.71,267.69,157.34,269.92,157.34 M258.49,154.68
					c-1.48,0-2.97-0.32-4.47-1.01c-0.31-0.14-0.57-0.29-0.75-0.29c-0.21,0-0.33,0.19-0.31,0.79c0.02,0.45-0.18,0.94-0.41,1.35
					c-0.28,0.5-0.06,0.65,0.35,0.81c1.68,0.67,3.39,1.23,5.16,1.23c0.19,0,0.38-0.01,0.57-0.02c0.15,0.01,0.3,0.01,0.45,0.01
					c2.02,0,3.88-0.57,5.73-1.18c0.42-0.14,0.57-0.3,0.31-0.76c-0.29-0.52-0.5-1.13-0.43-1.73c0.06-0.54-0.04-0.73-0.26-0.73
					c-0.15,0-0.36,0.09-0.62,0.21C262.05,154.21,260.28,154.68,258.49,154.68 M247.76,158.47c-0.04,0-0.08,0-0.12,0
					c-2.6-0.05-5.21-0.1-7.81-0.2c-2.38-0.1-4.75-0.27-7.13-0.39c-0.16-0.01-0.36-0.16-0.54-0.16c-0.12,0-0.23,0.07-0.32,0.31
					c-0.19,0.51,0.08,0.77,0.46,0.99c0.38,0.22,0.77,0.44,1.17,0.63c3.71,1.7,7.63,2.47,11.79,2.57c1.14-0.04,2.38-0.04,3.59-0.31
					c2.1-0.45,3.98-1.27,5.21-3.16c0.39-0.6,0.63-1.07-0.39-1.25c-0.39-0.07-0.78-0.26-1.14-0.46c-0.21-0.12-0.4-0.18-0.59-0.18
					c-0.24,0-0.48,0.11-0.74,0.34C250.25,158.08,249.05,158.47,247.76,158.47 M271.23,162.31c0.06,0,0.12,0,0.18,0
					c3.09-0.01,5.59-0.39,8.04-1.04c2.07-0.55,4.12-1.2,5.9-2.46c0.24-0.17,0.54-0.35,0.4-0.77c-0.09-0.25-0.22-0.31-0.38-0.31
					c-0.13,0-0.28,0.05-0.41,0.05c-1.74,0.1-3.48,0.23-5.22,0.31c-2.85,0.14-5.7,0.23-8.55,0.39c-0.52,0.03-1.06,0.08-1.59,0.08
					c-0.79,0-1.55-0.12-2.16-0.65c-0.68-0.58-1.32-0.81-1.97-0.81c-0.63,0-1.26,0.21-1.92,0.54c-0.06,0.03-0.12,0.05-0.18,0.05
					c-0.79,0.03-0.58,0.43-0.33,0.86c0.77,1.35,1.93,2.24,3.35,2.81C268.1,162.06,269.88,162.31,271.23,162.31 M245.43,163.04
					c-1.2,0-2.4-0.1-3.61-0.27c-3.45-0.47-6.82-1.27-9.88-3.05c-0.08-0.05-0.17-0.12-0.26-0.12c-0.06,0-0.13,0.04-0.19,0.17
					c-0.48,1-0.12,2.03,0.91,2.45c4.46,1.83,9.02,3.26,13.9,3.35c0.18,0.01,0.35,0.01,0.53,0.01c1.1,0,2.18-0.16,3.24-0.41
					c3.47-0.81,5.98-2.75,7.22-6.18c0.19-0.51,0.11-0.69-0.43-0.69c-0.31,0-0.62-0.09-0.93-0.16c-0.09-0.02-0.18-0.03-0.26-0.03
					c-0.27,0-0.45,0.13-0.62,0.48c-0.86,1.77-2.32,2.88-4.14,3.55C249.11,162.79,247.28,163.04,245.43,163.04 M271.18,165.65
					c0.72-0.05,1.64-0.07,2.55-0.19c4-0.52,7.79-1.78,11.51-3.31c0.76-0.32,1.01-0.7,0.88-1.49c-0.12-0.7-0.15-0.97-0.37-0.97
					c-0.16,0-0.42,0.14-0.88,0.37c-4,1.97-8.17,3.03-12.51,3.03c-1.25,0-2.51-0.09-3.79-0.27c-2.39-0.34-4.68-1.23-5.93-3.47
					c-0.47-0.84-0.94-1.16-1.61-1.16c-0.22,0-0.45,0.03-0.72,0.09c-0.47,0.1-0.57,0.2-0.42,0.64c0.45,1.23,1.04,2.37,1.94,3.34
					C264.31,164.94,267.53,165.6,271.18,165.65 M245.7,166.34C245.7,166.34,245.7,166.34,245.7,166.34
					c6.1,2.31,10.82,5.69,12.84,12.09c1.97-6.21,6.49-9.59,12.32-11.9c-0.81-0.08-1.44-0.17-2.07-0.21
					c-4.54-0.28-8.13-2.95-9.66-7.21c-0.1-0.27-0.07-0.66-0.51-0.66c0,0-0.01,0-0.01,0c-0.47,0.01-0.4,0.43-0.5,0.69
					c-1.25,3.44-3.65,5.61-7.15,6.62C249.33,166.22,247.66,166.34,245.7,166.34 M231.56,165.9c0.07,0,0.16-0.02,0.27-0.04
					c1.96-0.51,3.94-0.78,5.96-0.78c0.04,0,0.07,0,0.11,0c-2.08-0.7-4.12-1.42-6.11-2.27c-0.15-0.06-0.25-0.09-0.33-0.09
					c-0.17,0-0.21,0.15-0.2,0.44c0.02,0.76,0.03,1.52,0.02,2.28C231.28,165.77,231.35,165.9,231.56,165.9 M280.37,165.16
					c0.07,0,0.15-0.01,0.21-0.01c0.04,0,0.07,0,0.11,0c1.27,0,2.51,0.22,3.74,0.5c0.6,0.14,1.01,0.23,1.29,0.23
					c0.63,0,0.64-0.47,0.67-1.99c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0-0.02-0.19,0-0.37c0.06-0.55-0.04-0.8-0.35-0.8
					c-0.15,0-0.34,0.05-0.58,0.16c-1.63,0.67-3.29,1.25-4.94,1.87c-0.13,0.05-0.37-0.02-0.37,0.19
					C280.15,165.13,280.25,165.16,280.37,165.16 M244.74,173.77c2.43,0,4.92-0.44,7.47-1.36c0.42-0.15,1.09-0.15,1.16-0.6
					c0.05-0.36-0.54-0.64-0.89-0.91c-4.33-3.39-9.29-4.88-14.73-4.99c-0.1,0-0.19,0-0.29,0c-1.85,0-3.67,0.26-5.45,0.74
					c-0.19,0.05-0.44-0.03-0.55,0.27c-0.17,0.49,0.42,2.61,0.84,2.88C236.29,172.38,240.42,173.77,244.74,173.77 M272.4,173.76
					c4.45,0,8.43-1.54,12.23-3.83c0.95-0.57,1.34-1.25,1.46-2.3c0.08-0.65-0.12-0.81-0.66-0.94c-1.8-0.43-3.6-0.78-5.45-0.78
					c-0.19,0-0.37,0-0.56,0.01c-0.21-0.01-0.41-0.01-0.62-0.01c-1.64,0-3.25,0.23-4.84,0.59c-3.68,0.85-7.05,2.36-9.93,4.87
					c-0.44,0.39-0.47,0.57,0.13,0.78c2.42,0.86,4.89,1.49,7.48,1.59C271.9,173.75,272.15,173.76,272.4,173.76 M285.52,170.28
					c-0.4,0.24-0.66,0.41-0.92,0.57c-3.46,2.07-7.13,3.5-11.22,3.67c-0.3,0.01-0.61,0.02-0.91,0.02c-3.08,0-6.04-0.66-8.91-1.78
					c-0.2-0.08-0.38-0.16-0.55-0.16c-0.15,0-0.3,0.06-0.45,0.24c-1.15,1.28-2.08,2.7-2.7,4.32c-0.1,0.25-0.13,0.34,0.18,0.45
					c2.97,1.07,5.96,2.06,9.15,2.26c0.42,0.03,0.85,0.04,1.26,0.04c4.26,0,8.22-1.32,12.12-2.97c0.19-0.08,0.43-0.21,0.52-0.38
					C284.15,174.61,284.98,172.58,285.52,170.28 M246.53,179.92c3.59,0,7.01-1,10.39-2.24c0.5-0.18,0.38-0.44,0.25-0.74
					c-0.58-1.34-1.35-2.56-2.27-3.71c-0.3-0.37-0.58-0.55-0.94-0.55c-0.19,0-0.41,0.05-0.66,0.15c-2.8,1.12-5.65,1.73-8.56,1.73
					c-1.41,0-2.83-0.14-4.27-0.43c-2.94-0.6-5.62-1.83-8.29-3.45c0.55,2.26,1.41,4.24,2.47,6.14c0.09,0.15,0.26,0.29,0.43,0.36
					c3.12,1.31,6.33,2.36,9.72,2.67C245.38,179.89,245.96,179.92,246.53,179.92 M282.11,178.05c-1.09,0.38-2.09,0.73-3.09,1.08
					c-2.8,0.98-5.65,1.55-8.56,1.55c-1.12,0-2.24-0.08-3.38-0.26c-2.41-0.38-4.71-1.18-7-1.96c-0.16-0.06-0.29-0.09-0.39-0.09
					c-0.2,0-0.3,0.13-0.36,0.49c-0.23,1.35-0.44,2.69-0.21,4.06c0.05,0.28,0.05,0.54,0.35,0.71c2.91,1.64,5.9,2.76,9.1,2.76
					c1.14,0,2.31-0.14,3.51-0.45C276.54,184.8,279.85,182.23,282.11,178.05 M249.04,186.43c3.08,0,5.88-1.23,8.54-2.76
					c0.24-0.14,0.39-0.26,0.41-0.56c0.11-1.43,0.09-2.85-0.21-4.26c-0.06-0.29-0.13-0.48-0.37-0.48c-0.08,0-0.19,0.02-0.33,0.08
					c-1.03,0.4-2.09,0.71-3.14,1.04c-2.43,0.77-4.89,1.21-7.39,1.21c-1.05,0-2.12-0.08-3.19-0.25c-2.63-0.41-5.14-1.21-7.85-2.22
					c0.88,1.47,1.86,2.59,2.82,3.73c2.74,3.27,6.44,4.39,10.48,4.46C248.9,186.43,248.97,186.43,249.04,186.43 M257.71,184.52
					c-3.03,1.67-6.08,2.76-9.26,2.76c-1.47,0-2.98-0.23-4.52-0.75c3.17,2.11,6.61,3.39,10.31,4c0.16,0.03,0.34,0.1,0.5,0.1
					c0.14,0,0.27-0.05,0.37-0.21C256.26,188.63,257.25,186.79,257.71,184.52 M262.49,190.68c0.04,0,0.09,0,0.15-0.01
					c4.16-0.65,8.04-2.02,11.44-4.54c-0.14,0.05-0.28,0.11-0.42,0.16c-1.71,0.54-3.43,0.95-5.21,0.95c-0.49,0-0.99-0.03-1.5-0.1
					c-2.49-0.34-4.79-1.15-6.99-2.33c-0.1-0.06-0.22-0.19-0.34-0.19c-0.05,0-0.1,0.02-0.14,0.08c-0.06,0.07,0.01,0.29,0.05,0.43
					c0.5,1.88,1.36,3.58,2.43,5.2C262.09,190.55,262.23,190.68,262.49,190.68 M258.51,190.92c0.73,0,1.45-0.04,2.18-0.07
					c0.01,0,0.03,0,0.04,0c0.04,0,0.08,0,0.12,0c0.22,0,0.46-0.03,0.19-0.44c-1.08-1.67-1.88-3.48-2.5-5.41
					c-0.59,2.03-1.51,3.89-2.64,5.7C256.78,190.87,257.65,190.92,258.51,190.92 M262.45,152.8
					C262.45,152.8,262.45,152.8,262.45,152.8C262.45,152.81,262.45,152.8,262.45,152.8 M194.76,33.41
					C194.76,33.41,194.76,33.41,194.76,33.41c-0.76,0-1.48,0.3-2.08,0.86c-0.48,0.45-1.89,2.09,0.07,4.33
					c0.41,0.46,0.83,0.9,1.23,1.32c0.71,0.73,1.38,1.43,1.89,2.18c1.76,2.63,2.3,5.75,1.75,10.1c-0.39,3.07-1.17,6.31-2.47,10.22
					c-2.82,8.52-6.63,16.6-9.64,22.69c-3.42,6.93-6.97,14.1-9.41,21.77c-1.65,5.15-3.35,11.99-1.88,19.24
					c1.18,5.83,3.78,11.04,7.72,15.46c5.26,5.91,12.08,10.19,20.85,13.08c7.09,2.34,15,3.85,24.81,4.73
					c-0.2,1.41-0.28,2.9-0.26,4.67c0,0.06,0,0.13,0.01,0.19c0.03,0.3,0.05,0.7,0.06,1.12c0.02,0.69,0.05,1.47,0.16,2.29
					c1.11,8.33,4.94,15.11,11.38,20.18c5.96,4.68,12.5,7.06,19.44,7.06c3.5,0,7.13-0.61,10.8-1.81
					c12.38-4.06,21.07-16.04,21.14-29.14c0.01-1.5-0.1-3.03-0.33-4.56c4.7-0.41,9.07-1,13.32-1.8c8.06-1.52,14-3.42,19.26-6.14
					c7.62-3.95,12.79-8.43,16.27-14.11c2.96-4.82,4.6-9.38,5.02-13.93c0.4-4.29-0.08-8.77-1.45-13.69
					c-2.16-7.77-5.46-14.9-8.71-21.45c-4.11-8.29-8.37-17.14-11.34-26.31c-1.37-4.24-2.62-8.81-2.47-13.54
					c0.1-3.13,1.05-5.58,2.88-7.49c0.63-0.65,1.24-1.32,1.85-1.99L325,38.5c1.19-1.29,1.18-3.02-0.02-4.21
					c-0.57-0.56-1.29-0.87-2.05-0.87c-0.54,0-1.34,0.16-2.15,0.9c-0.17,0.16-0.34,0.32-0.5,0.49c-2.91,3.04-5.83,6.44-8.94,10.4
					c-6.5,8.28-11.01,16.53-13.77,25.21c-2.6,8.16-3.81,16.6-4.74,23.86c-0.61,4.77-0.86,9.62-1.11,14.31
					c-0.08,1.45-0.15,2.9-0.24,4.35c-0.29,4.93-0.7,10.2-1.69,15.32c-1.12,5.8-3.68,10.54-7.8,14.45
					c-6.39-6.68-14.11-10.06-22.97-10.06c-1.24,0-2.51,0.07-3.79,0.2c-7.61,0.78-14.19,4.07-19.56,9.78
					c-3.39-3.34-5.53-6.67-6.7-10.4c-1.6-5.07-2.13-10.42-2.53-15.47c-0.3-3.71-0.54-7.73-0.75-12.65c-0.15-3.5-0.44-6.93-0.84-10.2
					c-0.54-4.3-1.23-8.64-2.05-12.91c-1.25-6.48-2.95-12.15-5.18-17.32c-2.95-6.81-7.08-13.43-12.64-20.23
					c-2.44-2.99-4.97-6.09-7.84-8.96C196.25,33.6,195.35,33.41,194.76,33.41L194.76,33.41z M310.6,56.54
					c0.22-0.33,0.45-0.66,0.69-0.97c0.03-0.02,0.18-0.1,0.83-0.1c0.14,0,0.27,0,0.4,0.01c0.69,0.02,1.32,0.39,2.26,1.35
					c0.02,0.14,0.04,0.31,0.09,0.49c0.01,0.05,0.03,0.1,0.04,0.15c-0.1-0.04-0.19-0.08-0.29-0.12
					C313.27,56.82,311.91,56.55,310.6,56.54L310.6,56.54z M202.66,57.54c0.09-0.32,0.18-0.64,0.29-0.95
					c0.12-0.37,1.23-1.09,2.6-1.09c0.28,0,0.54,0.03,0.76,0.1c0.21,0.06,0.23,0.08,0.27,0.17c0.16,0.28,0.33,0.55,0.49,0.82
					C205.61,56.59,204.16,56.9,202.66,57.54L202.66,57.54z M199.62,67.8c0.36-0.98,0.71-2,1.03-3.03c0.15-0.46,0.37-0.78,0.76-1.09
					c2.14-1.69,3.89-2.44,5.69-2.44c0.25,0,0.51,0.02,0.77,0.05c1.89,0.22,2.52,0.86,2.92,1.82c0.31,0.74,0.64,1.48,0.97,2.22
					c0.14,0.32,0.29,0.64,0.43,0.95c-1.44-0.41-2.9-0.62-4.36-0.62C205.12,65.66,202.37,66.38,199.62,67.8L199.62,67.8z
					 M305.51,66.26c0.29-0.66,0.6-1.32,0.91-2c0.2-0.44,0.4-0.87,0.6-1.31c0.4-0.89,1-1.42,2.99-1.65l0.16,0
					c0.09,0,0.18,0.01,0.27,0.01l0.06,0c2.16,0,4.06,0.88,6.31,2.92c0,0.01,0.01,0.02,0.01,0.03c0.03,0.11,0.07,0.22,0.1,0.33
					c0.37,1.08,0.74,2.13,1.1,3.15c-0.62-0.32-1.27-0.62-1.94-0.88c-2.07-0.81-4.16-1.22-6.21-1.22
					C308.42,65.64,306.96,65.85,305.51,66.26L305.51,66.26z M196.55,75.51c2.36-1.99,5.25-4.1,8.83-4.94
					c0.81-0.19,1.62-0.29,2.43-0.29c2.14,0,4.26,0.65,6.67,2.04c0.44,1.4,0.86,2.87,1.26,4.44c-2.32-1.14-4.84-1.71-7.54-1.71
					c-0.5,0-1,0.02-1.51,0.06c-3.99,0.31-7.87,1.66-11.83,4.13C195.42,78,195.98,76.75,196.55,75.51L196.55,75.51z M303.2,72.38
					c2.27-1.41,4.43-2.1,6.6-2.1c0.22,0,0.44,0.01,0.67,0.02c2.37,0.15,4.72,1,6.98,2.51c0.73,0.49,1.43,1.01,2.18,1.55
					c0.38,0.28,0.77,0.56,1.15,0.84c0.41,0.29,0.63,0.83,0.97,1.68c0.14,0.36,0.29,0.73,0.47,1.11c0.19,0.39,0.37,0.78,0.56,1.18
					c-0.09-0.05-0.18-0.11-0.27-0.16c-4.59-2.73-8.67-4-12.83-4c-0.46,0-0.92,0.02-1.38,0.05c-2.28,0.16-4.39,0.71-6.3,1.63
					C302.36,75.24,302.75,73.79,303.2,72.38L303.2,72.38z M187.01,95.67c0.95-2.05,1.95-4.11,2.99-6.16
					c0.53-1.04,1.52-1.87,2.57-2.75c0.22-0.18,0.44-0.37,0.66-0.56c3.92-3.38,7.59-5.32,11.56-6.13c1.19-0.24,2.34-0.36,3.42-0.36
					c3.45,0,6.37,1.2,8.92,3.68c0.01,0,0.02,0.03,0.03,0.09c0.32,1.59,0.58,3.23,0.82,4.84c-2.72-1.45-5.7-2.18-8.9-2.18
					c-0.79,0-1.61,0.04-2.43,0.13c-6.59,0.71-12.71,3.59-19.25,9.06C187.27,95.44,187.14,95.56,187.01,95.67L187.01,95.67z
					 M299.73,88.25c0.28-1.68,0.57-3.37,0.86-5.04c0.05-0.06,0.39-0.28,0.61-0.42c0.23-0.15,0.47-0.31,0.68-0.47
					c2.24-1.73,4.71-2.57,7.57-2.57c1.42,0,2.97,0.21,4.6,0.64c3.13,0.81,6.14,2.44,9.73,5.26c1.12,0.88,2.11,1.91,3.17,3.01
					l0.41,0.43c0.6,0.62,0.99,1.53,1.41,2.5c0.15,0.34,0.3,0.69,0.46,1.02l0.29,0.63c0.36,0.77,0.73,1.55,1.09,2.33
					c-4.61-4.04-9.46-6.8-14.71-8.38c-2.47-0.75-4.88-1.12-7.14-1.12C305.56,86.05,302.53,86.79,299.73,88.25L299.73,88.25z
					 M180.24,114.62c0.11-0.53,0.24-1.07,0.37-1.63c0.12-0.52,0.25-1.04,0.36-1.56c0.44-2.06,1.31-3.86,2.64-5.5
					c4.9-6.02,9.77-10.05,15.33-12.69c3.5-1.66,6.75-2.47,9.93-2.47c0.6,0,1.21,0.03,1.81,0.09c3.18,0.31,5.87,1.54,8.2,3.76
					l0.02,0.16c0.26,1.94,0.43,3.89,0.58,5.89c-1.23-0.58-2.49-1.03-3.79-1.35c-1.99-0.5-4.05-0.75-6.11-0.75
					c-3.43,0-6.89,0.67-10.56,2.05C191.77,103.35,185.6,107.94,180.24,114.62L180.24,114.62z M298.22,100.62
					c0.03-0.32,0.06-0.64,0.09-0.96l0.21-2.31c0.03-0.32,0.05-0.63,0.08-0.95c0.04-0.54,0.08-1.05,0.14-1.54
					c0.04-0.34,0.31-0.57,1.17-1.16c0.18-0.12,0.36-0.24,0.53-0.37c2.33-1.68,4.99-2.5,8.13-2.5c0.84,0,1.73,0.06,2.65,0.18
					c5.08,0.65,9.75,2.85,15.15,7.12c3.07,2.43,5.94,5.49,9.05,9.63c0.52,0.7,0.75,1.62,1.01,2.69c0.07,0.29,0.14,0.58,0.22,0.87
					c0.28,1.02,0.54,2.07,0.76,3.14c-5.48-6.75-12.08-11.54-19.62-14.23c-3.21-1.15-6.45-1.73-9.61-1.73c-0.16,0-0.33,0-0.49,0
					C304.24,98.59,301.07,99.3,298.22,100.62L298.22,100.62z M179.82,123.3c5.31-8.75,11.85-14.65,20-18.03
					c3.28-1.36,6.58-2.05,9.82-2.05c0.57,0,1.14,0.02,1.71,0.06c3.17,0.23,6.01,1.2,8.46,2.89c0.07,1.42,0.14,2.74,0.21,3.99
					c-1.96-0.55-3.95-0.89-5.95-1.01c-0.59-0.04-1.2-0.05-1.79-0.05c-4.16,0-8.29,0.91-12.27,2.72
					c-7.88,3.56-13.91,9.31-18.37,17.52c-0.92-1.98-1.71-3.9-1.82-5.96C179.82,123.34,179.82,123.32,179.82,123.3L179.82,123.3z
					 M297.89,106.19c2.35-1.54,4.88-2.45,7.74-2.78c0.83-0.1,1.67-0.14,2.51-0.14c3.65,0,7.38,0.91,11.41,2.79
					c3.57,1.66,6.86,3.93,10.05,6.95c2.89,2.73,5.55,6.02,8.12,10.06c0.15,0.24,0.12,0.55-0.05,1.41c-0.05,0.23-0.09,0.46-0.13,0.69
					c-0.21,1.26-0.79,2.49-1.4,3.79c-0.04,0.08-0.08,0.16-0.11,0.24c-0.73-1.35-1.49-2.59-2.32-3.78
					c-4.24-6.08-9.18-10.41-15.1-13.24c-3.49-1.67-7.21-2.69-11.05-3.02c-0.8-0.07-1.6-0.1-2.38-0.1c-2.54,0-5.07,0.37-7.55,1.1
					C297.67,108.92,297.76,107.63,297.89,106.19L297.89,106.19z M297.34,115.16c1.53-0.58,3.17-0.98,5.03-1.22
					c0.87-0.12,1.59-0.2,2.31-0.2c0.17,0,0.34,0.01,0.51,0.02c0.04,0,0.07,0,0.11,0c5.01,0.07,9.63,1.46,14.14,4.25
					c2.54,1.57,4.9,3.54,7.2,6.04c2.62,2.84,4.86,6.32,6.84,10.64c-1,1.61-2.28,3.14-3.87,4.65c-1.09-3.16-2.72-6.16-4.89-8.96
					c-3.4-4.38-7.54-7.64-12.31-9.68c-3.35-1.43-6.75-2.16-10.11-2.16c-0.27,0-0.54,0-0.81,0.01c-1.58,0.05-3.04,0.24-4.42,0.57
					c0.03-0.36,0.06-0.72,0.09-1.06C297.26,116.96,297.31,116.02,297.34,115.16L297.34,115.16z M184.18,134.67
					c4.71-10.34,11.62-16.85,21.12-19.89c2.16-0.69,4.39-1.04,6.62-1.04c0.14,0,0.27,0,0.41,0c0.01,0,0.03,0,0.04,0
					c0.01,0,0.02,0,0.03,0c0.12,0,0.24,0,0.36,0c2.53,0,5.02,0.46,7.61,1.39c0.09,1.38,0.2,2.76,0.31,4.12
					c-1.91-0.45-3.81-0.67-5.67-0.67c-5.86,0-11.59,2.23-17.01,6.62c-4.66,3.78-7.99,8.54-9.92,14.18
					C186.71,138.09,185.28,136.52,184.18,134.67L184.18,134.67z M179.82,123.2L179.82,123.2L179.82,123.2L179.82,123.2z
					 M296.52,124.2c1.87-0.58,3.69-0.95,4.71-0.95c0.04,0,0.09,0,0.13,0c0.02,0,0.04,0,0.06,0c5.57,0.06,10.01,1.54,14.39,4.8
					c3.42,2.54,6.15,5.86,8.14,9.88c0.71,1.43,1.29,2.95,1.77,4.64c-1.79,1.29-3.77,2.47-6.12,3.68c-0.94-3.61-2.63-6.97-5.04-10.02
					c-2.28-2.88-4.81-4.97-7.73-6.39c-3-1.46-6.23-2.2-9.61-2.2c-0.4,0-0.81,0.01-1.22,0.03
					C296.21,126.54,296.39,125.38,296.52,124.2L296.52,124.2z M215.4,123.26c0.02,0,0.05,0,0.07,0c0.15-0.01,0.29-0.01,0.44-0.01
					c1.59,0,3.32,0.29,5.26,0.9c0.13,1.27,0.31,2.45,0.52,3.55c-0.43-0.02-0.85-0.04-1.27-0.04c-2.61,0-5.19,0.48-7.65,1.41
					c-5.72,2.18-9.99,6.33-13.02,12.67c-0.74,1.55-1.3,3.05-1.67,4.54c-2.11-1.05-4.16-2.29-6.12-3.7
					c3.29-10.46,9.85-16.71,20.04-19.08c0.75-0.17,1.57-0.25,2.68-0.25c0.22,0,0.45,0,0.68,0.01
					C215.37,123.26,215.38,123.26,215.4,123.26L215.4,123.26z M202.43,148.19c1.87-7.37,5.81-12.22,12.03-14.8
					c1.84-0.76,3.84-1,6.47-1.12c0.27,0.02,0.54,0.03,0.8,0.03c0.38,0.01,0.83,0.02,1.15,0.08c0.02,0.12,0.05,0.27,0.06,0.38
					c0.08,0.47,0.17,1.06,0.42,1.63c0.13,0.3,0.26,0.63,0.38,0.97c-2.54,0.21-4.87,1-6.93,2.33c-3.95,2.56-6.41,6.43-7.52,11.82
					c-0.05,0.26-0.1,0.53-0.15,0.8C206.75,149.67,204.54,148.97,202.43,148.19L202.43,148.19z M294.91,132.45
					c0.18-0.01,0.37-0.01,0.6-0.01c0.23,0,0.45,0,0.68,0.01c0.25,0,0.5,0.01,0.75,0.01c0.02,0,0.04,0,0.06,0
					c0.19-0.01,0.37-0.01,0.55-0.01c4.95,0,9.18,1.95,12.59,5.81c2.62,2.96,4.33,6.29,5.11,9.93c-2.3,0.82-4.5,1.52-6.67,2.12
					c-0.52-3.04-1.52-5.65-3.03-7.94c-2.51-3.8-5.98-6.1-10.31-6.83c-0.44-0.07-0.86-0.12-1.27-0.14
					C294.34,134.34,294.65,133.38,294.91,132.45L294.91,132.45z M255.24,138.79c1.17-0.16,2.35-0.24,3.52-0.24
					c1.1,0,2.23,0.07,3.36,0.21c-1.41,1.99-2.57,4.03-3.46,6.14c-0.03-0.06-0.05-0.12-0.08-0.18
					C257.65,142.62,256.54,140.63,255.24,138.79L255.24,138.79z M290.01,142.56c0.66-0.84,1.32-1.72,1.9-2.67l0.17,0l0.02,0
					c5.41,0.25,8.84,2.65,10.81,7.56c0.46,1.15,0.8,2.41,1.04,3.9c-0.46,0.08-0.92,0.17-1.38,0.25c-1.84,0.34-3.72,0.69-5.6,0.96
					c-0.2-2.88-1.44-5.45-3.7-7.65C292.33,143.98,291.23,143.2,290.01,142.56L290.01,142.56z M213.68,151.38
					c0.77-4.54,2.45-7.54,5.38-9.62c1.79-1.27,3.93-1.86,6.75-1.86c0.57,1,1.23,1.88,1.91,2.72c-3.49,1.8-5.66,4.4-6.63,7.9
					c-0.19,0.67-0.31,1.36-0.36,2.07C218.54,152.25,216.13,151.86,213.68,151.38L213.68,151.38z M270.52,140.26L270.52,140.26
					L270.52,140.26L270.52,140.26z M266.3,147.66c0.14-0.3,0.28-0.6,0.42-0.88c0.93-1.89,2-3.68,3.28-5.71
					c2.45,1.2,4.68,2.76,6.67,4.66c-1.41,0.41-2.96,0.69-4.69,0.85c-0.36,0.03-0.73,0.05-1.09,0.07c-0.63,0.03-1.28,0.06-1.95,0.16
					C267.97,146.95,267.08,147.23,266.3,147.66L266.3,147.66z M240.94,145.69c1.87-1.8,4.03-3.31,6.45-4.51
					c1.25,1.97,2.48,4.02,3.5,6.21c-0.79-0.34-1.67-0.56-2.63-0.63c-2.2-0.16-4.53-0.36-6.76-0.92
					C241.31,145.8,241.13,145.75,240.94,145.69L240.94,145.69z M225.34,153.18c0.14-2.03,0.92-3.65,2.39-4.99
					c0.92-0.83,1.95-1.48,3.07-1.92c0.92,0.97,1.88,1.8,2.9,2.52c-1.15,1.46-1.69,3.16-1.59,5
					C229.97,153.61,227.74,153.41,225.34,153.18L225.34,153.18z M284,148.78c0.98-0.69,1.92-1.51,2.85-2.47
					c3.08,1.3,4.74,3.14,5.33,5.89c0.03,0.14,0.03,0.35,0.03,0.58c0,0.11,0,0.24,0.01,0.36c-2.12,0.31-4.31,0.52-6.65,0.62
					C285.63,151.76,285.11,150.12,284,148.78L284,148.78z M236.8,154.07c-0.23-1.33,0.1-2.2,1.16-3.08
					c0.92,0.34,1.86,0.61,2.85,0.83c-0.24,0.81-0.32,1.62-0.23,2.44C239.37,154.19,238.1,154.13,236.8,154.07L236.8,154.07z
					 M276.93,151.84c0.9-0.19,1.82-0.45,2.73-0.8c1.2,1.03,1.45,1.76,1.19,3.01c-0.28,0.02-0.56,0.05-0.83,0.07
					c-0.56,0.05-1.08,0.1-1.58,0.1c-0.17,0-0.33-0.01-0.5-0.02c-0.21-0.02-0.42-0.02-0.63-0.02c-0.05,0-0.1,0-0.14,0
					C277.24,153.4,277.16,152.61,276.93,151.84L276.93,151.84z M252.37,167.4c2.7-0.95,4.79-2.57,6.25-4.82
					c1.46,2.25,3.53,3.92,6.01,4.87c-2.62,1.71-4.63,3.74-6.09,6.15C256.86,170.86,254.65,168.87,252.37,167.4L252.37,167.4z
					 M233.73,168.3c1.26-0.26,2.51-0.39,3.74-0.39l0.26,0c4.47,0.09,8.31,1.16,11.67,3.27c-1.57,0.39-3.14,0.59-4.67,0.59
					c-3.68,0-7.37-1.15-10.99-3.43C233.74,168.32,233.74,168.31,233.73,168.3L233.73,168.3z M267.69,171.15
					c1.94-1.21,4.16-2.1,6.72-2.69c1.58-0.37,3.01-0.54,4.39-0.54c0.18,0,0.37,0,0.55,0.01c0.02,0,0.05,0,0.07,0
					c0.03,0,0.05,0,0.08,0c0.16-0.01,0.32-0.01,0.48-0.01c1.18,0,2.37,0.17,3.48,0.39c-3.95,2.36-7.47,3.46-11.06,3.46
					c-0.22,0-0.45,0-0.68-0.01C270.43,171.69,269.11,171.5,267.69,171.15L267.69,171.15z M272.47,176.54c0.33,0,0.66-0.01,0.99-0.02
					c2.8-0.12,5.59-0.79,8.44-2.03c-0.11,0.23-0.23,0.46-0.35,0.69c-3.23,1.36-7.05,2.71-11.1,2.71c-0.38,0-0.76-0.01-1.14-0.04
					c-2.36-0.15-4.61-0.77-6.86-1.53c0.29-0.5,0.62-0.99,1-1.47C266.55,175.99,269.51,176.54,272.47,176.54L272.47,176.54z
					 M236.19,175.46c-0.12-0.23-0.24-0.45-0.35-0.67c1.43,0.58,2.82,1.01,4.24,1.3c1.55,0.31,3.12,0.47,4.67,0.47
					c2.95,0,5.93-0.57,8.89-1.71c0.38,0.51,0.72,1.01,1.01,1.51c-2.48,0.84-5.26,1.55-8.12,1.55c-0.52,0-1.04-0.02-1.55-0.07
					C242.31,177.6,239.59,176.87,236.19,175.46L236.19,175.46z M261.03,182.23c-0.04-0.43-0.03-0.86,0.01-1.32
					c1.83,0.61,3.73,1.19,5.73,1.5c1.21,0.19,2.46,0.29,3.69,0.29c1.82,0,3.64-0.2,5.51-0.61c-1.29,0.87-2.75,1.51-4.4,1.94
					c-1,0.26-2.01,0.39-3.01,0.39C266.21,184.41,263.8,183.71,261.03,182.23L261.03,182.23z M246.56,182.71c2.6,0,5.21-0.43,8-1.31
					l0.66-0.21c0.27-0.08,0.55-0.17,0.83-0.26c0.02,0.41,0.02,0.85,0,1.31c-2.04,1.13-4.42,2.19-7,2.19l-0.18,0
					c-3.17-0.06-5.61-0.8-7.52-2.32c0.56,0.12,1.14,0.23,1.72,0.32C244.22,182.62,245.39,182.71,246.56,182.71L246.56,182.71z
					 M263.18,188.54c-0.05-0.09-0.1-0.17-0.15-0.25c0.19,0.06,0.38,0.12,0.57,0.17C263.46,188.49,263.32,188.52,263.18,188.54
					L263.18,188.54z M253.8,188.41c0.08-0.03,0.17-0.05,0.25-0.08c-0.02,0.04-0.05,0.08-0.07,0.12
					C253.92,188.43,253.86,188.42,253.8,188.41L253.8,188.41z"/>
                <path d="M194.76,35.41c0.33,0,0.66,0.18,0.96,0.49c2.76,2.77,5.24,5.79,7.7,8.81c4.95,6.06,9.23,12.56,12.35,19.76
					c2.35,5.43,3.94,11.09,5.06,16.9c0.82,4.24,1.49,8.5,2.03,12.78c0.42,3.33,0.69,6.68,0.83,10.04c0.18,4.25,0.42,8.49,0.76,12.73
					c0.43,5.37,0.98,10.72,2.62,15.91c1.53,4.87,4.48,8.72,8.14,12.12c0.21,0.19,0.38,0.3,0.55,0.3c0.18,0,0.35-0.12,0.58-0.37
					c5.08-5.78,11.42-9.25,19.11-10.04c1.22-0.13,2.42-0.19,3.59-0.19c8.55,0,15.88,3.32,21.95,9.89c0.47,0.5,0.69,0.75,0.93,0.75
					c0.22,0,0.46-0.21,0.92-0.62c4.8-4.33,7.72-9.68,8.94-16.03c0.99-5.15,1.41-10.36,1.72-15.59c0.37-6.18,0.55-12.38,1.33-18.52
					c1.02-7.94,2.22-15.85,4.66-23.51c2.89-9.07,7.59-17.14,13.44-24.58c2.78-3.55,5.69-6.99,8.81-10.25
					c0.13-0.14,0.27-0.27,0.41-0.4c0.25-0.23,0.52-0.37,0.79-0.37c0.22,0,0.44,0.09,0.64,0.29c0.45,0.45,0.38,0.97-0.05,1.44
					c-0.73,0.8-1.46,1.59-2.21,2.37c-2.36,2.46-3.33,5.48-3.44,8.81c-0.16,4.92,1.07,9.61,2.56,14.22
					c2.99,9.21,7.16,17.92,11.45,26.58c3.38,6.82,6.53,13.74,8.58,21.1c1.18,4.25,1.79,8.57,1.38,12.98
					c-0.44,4.75-2.25,9.03-4.73,13.07c-3.75,6.11-9.28,10.16-15.48,13.38c-5.88,3.05-12.23,4.73-18.71,5.95
					c-4.86,0.92-9.77,1.53-14.7,1.9c-0.74,0.06-0.52,0.49-0.46,0.84c0.35,1.84,0.54,3.7,0.53,5.58
					c-0.06,12.21-8.15,23.44-19.76,27.25c-3.48,1.14-6.88,1.71-10.17,1.71c-6.47,0-12.56-2.2-18.2-6.63
					c-6.08-4.78-9.61-11.15-10.64-18.87c-0.17-1.24-0.12-2.47-0.22-3.35c-0.02-2.2,0.12-4.03,0.5-5.84c0.14-0.64-0.1-0.64-0.59-0.68
					c-8.77-0.72-17.44-1.99-25.83-4.76c-7.68-2.53-14.57-6.44-19.98-12.51c-3.68-4.14-6.14-9.03-7.25-14.53
					c-1.27-6.27-0.08-12.3,1.82-18.24c2.39-7.47,5.84-14.48,9.3-21.49c3.69-7.46,7.13-15.03,9.75-22.95
					c1.15-3.46,2.09-6.98,2.55-10.59c0.51-4.02,0.28-7.95-2.07-11.47c-0.92-1.38-2.19-2.46-3.28-3.71c-0.44-0.51-0.77-1.01-0.2-1.55
					C194.28,35.51,194.52,35.41,194.76,35.41 M316.13,45.51c-1.96,2.54-3.84,4.83-5.5,7.47c0.54-0.1,1.06-0.16,1.56-0.16
					c1.57,0,2.93,0.54,4.2,1.78C315.85,51.51,315.87,48.63,316.13,45.51 M201.29,54.5c1.96-1.29,2.52-1.65,3.8-1.65
					c0.51,0,1.14,0.06,2.01,0.14c-1.73-2.58-3.53-4.98-5.52-7.26C201.93,48.64,201.78,51.5,201.29,54.5 M307.15,59.25
					c0.18,0,0.39-0.15,0.56-0.21c0.94-0.34,1.88-0.5,2.81-0.5c1.14,0,2.26,0.24,3.39,0.67c1.31,0.51,2.52,1.17,3.59,2.09
					c0.1,0.08,0.18,0.21,0.32,0.21c0.02,0,0.05,0,0.08-0.01c0.06-0.02,0.08-0.14,0.11-0.19c-0.05-0.13-0.09-0.21-0.11-0.3
					c-0.36-1.41-0.71-2.82-1.09-4.22c-0.1-0.36-0.01-0.75-0.31-1.07c-1.08-1.16-2.28-2.21-3.92-2.25c-0.15,0-0.3-0.01-0.45-0.01
					c-0.88,0-1.82,0.11-2.42,0.89c-0.98,1.28-1.78,2.69-2.55,4.11c-0.12,0.21-0.38,0.49-0.22,0.68
					C306.99,59.23,307.07,59.25,307.15,59.25 M199.62,61.72c2.39-1.85,4.84-3.13,7.52-3.13c1.17,0,2.39,0.25,3.67,0.8
					c0.07,0.02,0.13,0.04,0.2,0.06c-0.03-0.06-0.05-0.11-0.08-0.17c-0.72-1.59-1.74-3.01-2.61-4.51c-0.35-0.6-0.82-0.91-1.46-1.09
					c-0.41-0.12-0.85-0.17-1.3-0.17c-1.93,0-4.02,1-4.5,2.47C200.45,57.79,200.11,59.69,199.62,61.72 M195.75,72.68
					c0.99-0.69,1.96-1.41,2.97-2.05c2.98-1.9,6.01-2.96,9.1-2.96c2.37,0,4.78,0.63,7.24,1.98c0.14,0.08,0.27,0.19,0.43,0.19
					c0.05,0,0.1-0.01,0.16-0.04c0,0,0,0,0,0c0.01,0.01,0.02,0.01,0.03,0.02c0.01,0.02,0.02,0.05,0.02,0.07
					c0-0.02,0.02-0.04,0.01-0.05c-0.01-0.01-0.02-0.02-0.03-0.03c0-0.01-0.01-0.02-0.01-0.03c-0.01,0-0.01,0.01-0.02,0.01
					c-0.73-2.6-1.98-4.98-3.02-7.46c-0.87-2.08-2.53-2.81-4.54-3.04c-0.34-0.04-0.67-0.06-1-0.06c-2.64,0-4.85,1.23-6.93,2.87
					c-0.7,0.55-1.15,1.2-1.42,2.05c-0.84,2.66-1.85,5.25-2.9,7.83C195.76,72.19,195.5,72.4,195.75,72.68 M302.37,69.73
					c0.23,0,0.46-0.28,0.7-0.35c0.18-0.05,0.34-0.17,0.51-0.25c2.08-0.99,4.18-1.49,6.3-1.49c1.81,0,3.64,0.36,5.48,1.08
					c2.24,0.87,4.2,2.2,6.11,3.62c0.11,0.08,0.21,0.25,0.34,0.25c0.04,0,0.08-0.01,0.12-0.05c0.19-0.15-0.02-0.35-0.09-0.51
					c-1.15-2.65-2.1-5.37-3.04-8.09c-0.12-0.35-0.17-0.74-0.47-1.02c-2.22-2.06-4.68-3.61-7.84-3.61c-0.02,0-0.04,0-0.06,0
					c-0.14,0-0.28-0.01-0.42-0.01c-0.08,0-0.16,0-0.24,0.01c-1.96,0.23-3.67,0.79-4.58,2.82c-0.99,2.2-2.05,4.36-2.83,6.65
					c-0.1,0.29-0.47,0.61-0.21,0.86C302.22,69.7,302.3,69.73,302.37,69.73 M189.78,85.6c0.06-0.03,0.13-0.06,0.19-0.09
					c1.51-1.26,2.97-2.57,4.6-3.68c3.71-2.56,7.73-4.37,12.26-4.72c0.46-0.04,0.91-0.05,1.35-0.05c3.65,0,6.96,1.17,9.85,3.6
					c0.15,0.12,0.26,0.35,0.47,0.35c0.04,0,0.09-0.01,0.14-0.03c0.06-0.03,0.03-0.3,0-0.45c-0.63-3-1.37-5.97-2.3-8.9
					c-0.1-0.33-0.22-0.65-0.55-0.85c-2.53-1.52-5.16-2.5-7.99-2.5c-0.94,0-1.9,0.11-2.88,0.34c-3.82,0.9-6.92,3.04-9.85,5.52
					c-0.12,0.1-0.21,0.24-0.27,0.38c-1.65,3.63-3.29,7.26-4.94,10.89C189.84,85.47,189.81,85.53,189.78,85.6 M299.18,80.9
					c0.1,0,0.19-0.12,0.28-0.17c0.16-0.1,0.32-0.2,0.47-0.32c2.46-2.07,5.35-3.12,8.51-3.35c0.42-0.03,0.83-0.04,1.24-0.04
					c4.27,0,8.14,1.55,11.8,3.72c1.99,1.18,3.85,2.55,5.51,4.17c0.11,0.11,0.21,0.25,0.37,0.25c0.03,0,0.07-0.01,0.1-0.02
					c0.06-0.02,0.09-0.15,0.14-0.24c-0.1-0.21-0.19-0.41-0.28-0.61c-1.1-2.39-2.18-4.79-3.31-7.16c-0.59-1.24-0.85-2.68-2.07-3.56
					c-1.13-0.81-2.23-1.66-3.39-2.43c-2.41-1.61-5.07-2.65-7.96-2.84c-0.27-0.02-0.53-0.03-0.8-0.03c-2.85,0-5.47,1-7.9,2.55
					c-0.28,0.18-0.45,0.45-0.55,0.76c-0.94,2.92-1.63,5.91-2.32,8.89c-0.03,0.12-0.04,0.29,0.03,0.36
					C299.1,80.89,299.14,80.9,299.18,80.9 M181.04,104.64c0.36-0.02,0.45-0.21,0.56-0.35c2.12-2.71,4.45-5.22,7.09-7.43
					c5.31-4.44,11.12-7.85,18.18-8.61c0.75-0.08,1.49-0.12,2.21-0.12c3.75,0,7.2,1.09,10.31,3.46c0.37,0.28,0.65,0.69,1.17,0.86
					c0.01-0.15,0.05-0.27,0.03-0.38c-0.5-3-0.87-6.01-1.46-8.99c-0.09-0.47-0.27-0.82-0.6-1.13c-3.03-2.93-6.5-4.24-10.31-4.24
					c-1.24,0-2.51,0.14-3.82,0.4c-4.79,0.97-8.82,3.43-12.47,6.58c-1.36,1.17-2.86,2.25-3.7,3.92c-1.89,3.73-3.67,7.51-5.3,11.36
					C182.27,101.49,181.68,103.04,181.04,104.64 M297.23,92.32c0.09,0,0.25-0.14,0.35-0.22c0.87-0.69,1.76-1.35,2.73-1.88
					c2.75-1.51,5.57-2.16,8.43-2.16c2.17,0,4.36,0.38,6.56,1.04c7.67,2.31,13.73,7.05,18.96,12.98c0.68,0.77,1.18,1.72,2.11,2.26
					c0.02,0.08,0.05,0.17,0.07,0.25c0.02-0.02,0.05-0.03,0.07-0.05c-0.05-0.07-0.09-0.13-0.14-0.2c0.01-0.6-0.33-1.08-0.53-1.61
					c-1.41-3.74-3.13-7.34-4.82-10.96c-0.66-1.4-1.14-2.93-2.24-4.07c-1.21-1.25-2.4-2.53-3.78-3.62c-3.16-2.48-6.53-4.6-10.47-5.62
					c-1.73-0.45-3.44-0.7-5.1-0.7c-3.1,0-6.05,0.87-8.79,2.98c-0.77,0.59-1.83,1-2.03,2.14c-0.52,2.98-1.01,5.96-1.51,8.95
					c-0.03,0.17-0.11,0.41,0.09,0.5C297.21,92.32,297.22,92.32,297.23,92.32 M177.74,121.58c0.75-1.12,1.38-2.1,2.05-3.04
					c5.14-7.22,11.55-12.89,19.94-16.04c3.23-1.21,6.51-1.92,9.86-1.92c1.86,0,3.73,0.22,5.62,0.69c2.32,0.58,4.46,1.59,6.53,3.02
					c-0.23-3.36-0.44-6.57-0.86-9.77c-0.05-0.39-0.05-0.79-0.4-1.13c-2.67-2.64-5.87-4.16-9.61-4.53c-0.67-0.07-1.34-0.1-2-0.1
					c-3.78,0-7.35,1.03-10.79,2.67c-6.44,3.06-11.55,7.75-16.02,13.23c-1.55,1.9-2.54,3.98-3.04,6.34c-0.28,1.3-0.64,2.58-0.87,3.89
					C177.77,117.04,177.55,119.2,177.74,121.58 M296.24,104.05c0.19,0,0.35-0.23,0.5-0.33c3.34-2.16,7.05-3.12,10.97-3.19
					c0.15,0,0.3,0,0.45,0c3.06,0,6.05,0.58,8.94,1.61c8.09,2.88,14.5,8.02,19.68,14.8c1.09,1.42,2.06,2.93,3.1,4.4
					c0.3-3.63-0.39-7.09-1.32-10.52c-0.39-1.46-0.62-2.98-1.57-4.24c-2.76-3.68-5.78-7.13-9.41-10c-4.78-3.79-9.95-6.74-16.13-7.54
					c-0.98-0.13-1.95-0.19-2.9-0.19c-3.32,0-6.45,0.82-9.3,2.88c-1.05,0.76-2.33,1.39-2.51,2.91c-0.1,0.85-0.14,1.7-0.22,2.55
					c-0.18,2.01-0.37,4.02-0.54,6.03c-0.02,0.27-0.16,0.62,0.11,0.79C296.14,104.04,296.19,104.05,296.24,104.05 M181.57,133.98
					c0.21-0.44,0.36-0.75,0.5-1.06c4.04-8.58,9.99-15.32,18.76-19.28c3.66-1.65,7.46-2.54,11.45-2.54c0.55,0,1.11,0.02,1.66,0.05
					c2.59,0.16,5.07,0.69,7.49,1.58c0.19,0.07,0.34,0.12,0.46,0.12c0.19,0,0.28-0.13,0.25-0.56c-0.15-2.25-0.27-4.5-0.37-6.75
					c-0.02-0.47-0.32-0.65-0.63-0.87c-2.89-2.05-6.15-3.12-9.65-3.38c-0.62-0.05-1.24-0.07-1.85-0.07c-3.65,0-7.18,0.79-10.59,2.2
					c-9.26,3.84-15.94,10.53-21.04,18.98c-0.21,0.34-0.22,0.68-0.2,1.08C178.05,127.32,180.13,130.46,181.57,133.98 M295.89,112.79
					c0.11,0,0.26-0.04,0.43-0.1c2.88-1.08,5.81-1.63,8.85-1.63c0.73,0,1.47,0.03,2.21,0.1c3.65,0.32,7.09,1.27,10.36,2.84
					c5.95,2.85,10.58,7.22,14.32,12.58c1.56,2.24,2.84,4.64,4.01,7.34c0.42-0.92,0.79-1.66,1.1-2.41c0.83-1.98,1.98-3.84,2.34-6
					c0.19-1.17,0.63-2.34-0.1-3.5c-2.42-3.8-5.14-7.34-8.43-10.44c-3.16-2.99-6.64-5.48-10.58-7.31c-3.93-1.83-7.97-2.97-12.26-2.97
					c-0.9,0-1.81,0.05-2.73,0.16c-3.22,0.37-6.14,1.43-8.82,3.23c-0.33,0.22-0.6,0.42-0.65,0.87c-0.2,2.2-0.35,4.41-0.39,6.63
					C295.54,112.62,295.65,112.79,295.89,112.79 M295.13,121.76c0.13,0,0.3-0.04,0.5-0.12c1.91-0.71,3.92-1.02,5.94-1.09
					c0.25-0.01,0.49-0.01,0.74-0.01c3.23,0,6.32,0.71,9.32,2c4.66,1.99,8.43,5.09,11.51,9.06c2.46,3.16,4.26,6.66,5.23,10.56
					c0.08,0.33,0.17,0.49,0.32,0.49c0.11,0,0.25-0.09,0.46-0.25c2.36-1.94,4.46-4.11,6.08-6.73c0.37-0.59,0.42-1.09,0.15-1.67
					c-1.87-4.12-4.17-7.97-7.25-11.31c-2.26-2.45-4.77-4.62-7.62-6.39c-4.66-2.88-9.68-4.46-15.16-4.54
					c-0.22-0.02-0.44-0.02-0.65-0.02c-0.86,0-1.72,0.11-2.57,0.22c-2.08,0.28-4.1,0.75-6.04,1.56c-0.39,0.16-0.7,0.37-0.71,0.89
					c-0.02,1.17-0.1,2.34-0.19,3.5c-0.08,1.04-0.16,2.08-0.33,3.1C294.75,121.55,294.84,121.76,295.13,121.76 M188.98,142.7
					c0.16,0,0.25-0.16,0.35-0.52c1.64-6.21,4.96-11.39,9.93-15.42c4.82-3.91,10.04-6.18,15.75-6.18c2.33,0,4.74,0.38,7.24,1.18
					c0.16,0.05,0.29,0.08,0.38,0.08c0.3,0,0.26-0.26,0.23-0.64c-0.19-2.21-0.39-4.42-0.52-6.64c-0.04-0.59-0.2-0.9-0.77-1.12
					c-2.84-1.09-5.76-1.7-8.8-1.7c-0.13,0-0.26,0-0.39,0c-0.15,0-0.3,0-0.45,0c-2.47,0-4.88,0.39-7.23,1.14
					c-10.94,3.5-17.96,11.15-22.52,21.38c-0.17,0.38-0.14,0.69,0.07,1.07c1.59,2.82,3.82,5.05,6.26,7.11
					C188.72,142.61,188.87,142.7,188.98,142.7 M293.95,129.87c0.08,0,0.16-0.01,0.26-0.02c1.01-0.13,2.01-0.2,3-0.2
					c3.02,0,5.94,0.63,8.74,1.99c2.81,1.37,5.1,3.39,7.04,5.83c2.58,3.26,4.31,6.92,5.06,11.02c0.07,0.4,0.16,0.54,0.32,0.54
					c0.11,0,0.25-0.06,0.44-0.15c2.98-1.43,5.85-3.02,8.52-4.99c0.55-0.41,0.62-0.76,0.47-1.33c-0.51-1.9-1.17-3.75-2.05-5.51
					c-2.09-4.21-4.97-7.79-8.74-10.59c-4.29-3.19-9.06-5.13-15.57-5.2c-0.07,0-0.14,0-0.21,0c-1.39,0-3.61,0.48-5.79,1.2
					c-0.39,0.13-0.77,0.25-0.83,0.79c-0.19,2.01-0.52,4-0.98,5.97C293.51,129.72,293.61,129.87,293.95,129.87 M215.4,121.26
					c-0.24-0.01-0.48-0.01-0.72-0.01c-1.08,0-2.12,0.07-3.13,0.3c-11.52,2.69-18.45,10.07-21.73,21.21
					c-0.13,0.46-0.01,0.71,0.33,0.96c2.78,2.08,5.75,3.83,8.92,5.23c0.14,0.06,0.25,0.11,0.34,0.11c0.13,0,0.2-0.11,0.25-0.41
					c0.3-2.12,0.98-4.12,1.9-6.04c2.56-5.34,6.27-9.51,11.93-11.67c2.26-0.86,4.58-1.28,6.94-1.28c0.92,0,1.85,0.06,2.78,0.19
					c0.14,0.02,0.26,0.03,0.36,0.03c0.55,0,0.63-0.28,0.46-0.94c-0.46-1.78-0.73-3.6-0.91-5.42c-0.06-0.65-0.29-0.94-0.89-1.13
					c-2.08-0.68-4.19-1.14-6.32-1.14C215.74,121.26,215.57,121.26,215.4,121.26 M210.58,152.74c0.22,0,0.3-0.14,0.33-0.46
					c0.08-0.79,0.2-1.57,0.36-2.35c0.9-4.35,2.82-8.07,6.65-10.55c2.23-1.44,4.69-2.07,7.3-2.07c0.22,0,0.44,0,0.66,0.01
					c0.07,0,0.14,0.01,0.21,0.01c0.45,0,0.62-0.1,0.31-0.73c-0.47-0.96-0.76-2.01-1.19-2.99c-0.43-0.98-0.16-2.44-1.17-2.92
					c-0.97-0.46-2.23-0.31-3.06-0.4c-2.69,0.12-5.06,0.35-7.28,1.27c-7.76,3.22-11.74,9.39-13.47,17.3c-0.1,0.46,0.1,0.68,0.52,0.84
					c3.13,1.21,6.33,2.19,9.58,3.01C210.43,152.72,210.51,152.74,210.58,152.74 M307.19,152.69c0.09,0,0.2-0.02,0.35-0.06
					c3.19-0.81,6.33-1.81,9.42-2.95c0.35-0.13,0.53-0.28,0.45-0.77c-0.74-4.57-2.75-8.56-5.78-11.99
					c-3.74-4.23-8.44-6.48-14.08-6.48c-0.2,0-0.41,0-0.61,0.01c-0.48,0-0.95-0.02-1.43-0.02c-0.32,0-0.64,0.01-0.95,0.03
					c-0.56,0.05-1.21,0.02-1.41,0.81c-0.47,1.88-1.14,3.69-1.89,5.47c-0.23,0.54-0.02,0.57,0.43,0.57c1.07,0.01,2.14-0.02,3.22,0.16
					c3.89,0.66,6.83,2.72,8.97,5.96c1.73,2.62,2.58,5.55,2.93,8.64C306.85,152.49,306.9,152.69,307.19,152.69 M258.65,151.93
					c0.93-5.38,3.27-9.87,6.53-13.91c0.4-0.5,0.47-0.68-0.26-0.83c-2.06-0.43-4.11-0.65-6.17-0.65c-2.12,0-4.24,0.24-6.36,0.73
					c-0.69,0.16-0.59,0.34-0.24,0.76c1.88,2.28,3.4,4.78,4.59,7.49C257.62,147.52,258.21,149.6,258.65,151.93 M257.69,153.83
					c0.28,0,0.33-0.12,0.31-0.33c-0.01-0.17-0.02-0.23-0.02-0.3c-0.63-5.79-3.17-10.72-6.82-15.16c-0.11-0.13-0.21-0.32-0.4-0.32
					c-0.04,0-0.08,0.01-0.13,0.02c-0.56,0.2-1.12,0.41-1.67,0.61c0.24,0.41,0.48,0.82,0.72,1.22c0.11,0.19,0.24,0.37,0.36,0.56
					c2.3,3.68,4.38,7.46,5.43,11.72c0.44,1.79,0.43,1.77,2.06,1.96C257.58,153.82,257.64,153.83,257.69,153.83 M259.75,153.85
					c0.65,0,1.78-0.61,1.88-1.09c0.3-1.42,0.7-2.81,1.23-4.17c1.35-3.43,3.19-6.59,5.2-9.66c0.13-0.2,0.48-0.39,0.26-0.69
					c-0.2-0.26-0.67-0.39-1.14-0.39c-0.48,0-0.96,0.13-1.17,0.39c-1.25,1.52-2.37,3.12-3.34,4.84c-1.76,3.11-2.95,6.4-3.35,9.94
					c0.04,0.27-0.13,0.58,0.11,0.75C259.51,153.82,259.62,153.85,259.75,153.85 M295.53,154.75c0.09,0,0.19-0.01,0.31-0.02
					c3.1-0.38,6.16-1,9.23-1.55c0.84-0.15,1.08-0.51,0.98-1.26c-0.23-1.79-0.61-3.54-1.29-5.22c-2.42-6.01-6.86-8.56-12.63-8.81
					c-0.02,0-0.04,0-0.06,0c-0.14,0-0.28,0-0.42,0c-0.48,0-0.94,0.06-1.23,0.56c-0.87,1.55-2.02,2.91-3.13,4.29
					c-0.4,0.5-0.47,0.66,0.24,0.92c1.62,0.59,3.11,1.48,4.34,2.68c2.11,2.06,3.26,4.57,3.11,7.58
					C294.97,154.45,294.95,154.75,295.53,154.75 M222.32,154.83c0.32,0,0.43-0.15,0.4-0.59c-0.07-1.08,0.01-2.15,0.3-3.2
					c1.05-3.79,3.73-6,7.22-7.37c0.64-0.25,0.61-0.36,0.23-0.84c-1.14-1.44-2.39-2.8-3.21-4.47c-0.16-0.32-0.42-0.44-0.77-0.45
					c-0.21-0.01-0.43-0.01-0.64-0.01c-2.84,0-5.56,0.54-7.94,2.23c-4.04,2.87-5.71,7.03-6.37,12.09c-0.2,0.53,0.15,0.8,1,0.97
					c3.16,0.64,6.34,1.15,9.54,1.62C222.17,154.83,222.25,154.83,222.32,154.83 M262.45,152.8
					C262.45,152.8,262.45,152.8,262.45,152.8c0,0-0.05,0.18,0.1,0.18c0.04,0,0.1-0.01,0.17-0.04c0.93-0.4,2-0.59,2.59-1.61
					c0.86-1.51,2.24-2.3,3.92-2.54c0.97-0.14,1.96-0.13,2.94-0.22c2.53-0.23,5-0.72,7.35-1.73c0.51-0.22,0.6-0.4,0.19-0.85
					c-2.75-3.06-5.97-5.45-9.74-7.13c-0.2-0.09-0.36-0.14-0.5-0.14c-0.24,0-0.43,0.14-0.64,0.46c-1.4,2.18-2.76,4.39-3.9,6.72
					C263.88,148.02,262.97,150.19,262.45,152.8 M254.63,153.02c0.13,0,0.21-0.09,0.15-0.36c-0.03-0.21-0.03-0.37-0.07-0.52
					c-1.2-4.83-3.69-9.03-6.36-13.15c-0.12-0.18-0.23-0.24-0.35-0.24c-0.11,0-0.23,0.05-0.35,0.11c-3.79,1.69-7.08,4.04-9.8,7.19
					c-0.3,0.34-0.26,0.47,0.15,0.66c0.97,0.45,1.97,0.82,3,1.08c2.33,0.59,4.72,0.81,7.11,0.98c1.85,0.13,3.39,0.93,4.26,2.6
					c0.45,0.86,1.2,1.15,1.93,1.55C254.41,152.97,254.54,153.02,254.63,153.02 M234.06,155.94c0.39,0,0.49-0.13,0.32-0.72
					c-0.77-2.65,0.03-4.8,2.19-6.47c0.12-0.09,0.23-0.18,0.41-0.33c-2.08-1.04-3.77-2.45-5.26-4.12c-0.16-0.19-0.32-0.25-0.49-0.25
					c-0.13,0-0.27,0.04-0.43,0.09c-1.66,0.54-3.12,1.4-4.4,2.57c-2.15,1.95-3.12,4.41-3.07,7.3c0.01,0.7,0.24,1.01,1.05,1.09
					c3.12,0.31,6.25,0.59,9.38,0.83C233.87,155.93,233.97,155.94,234.06,155.94 M283.69,156c0.3,0,0.67-0.2,0.98-0.21
					c2.71-0.09,5.41-0.31,8.1-0.72c1.46-0.22,1.46-0.23,1.46-1.77c-0.05-0.46,0.02-0.99-0.1-1.53c-0.87-4.02-3.59-6.24-7.24-7.6
					c-0.14-0.05-0.27-0.08-0.38-0.08c-0.27,0-0.47,0.15-0.69,0.38c-1.12,1.22-2.35,2.31-3.76,3.19c-1.23,0.77-1.26,0.82-0.16,1.82
					c1.5,1.36,1.81,3.06,1.61,4.94c-0.05,0.47-0.4,1.05-0.22,1.36C283.4,155.95,283.54,156,283.69,156 M242.89,156.39
					c0.41,0,0.54-0.11,0.21-0.71c-0.89-1.63-0.7-3.23,0.33-4.75c0.12-0.18,0.23-0.36,0.37-0.58c-2-0.32-3.92-0.7-5.73-1.46
					c-0.12-0.05-0.22-0.07-0.32-0.07c-0.2,0-0.36,0.1-0.54,0.23c-2.31,1.65-2.95,3.55-2.17,6.3c0.14,0.48,0.32,0.66,0.82,0.68
					c2.25,0.09,4.5,0.22,6.75,0.35C242.72,156.39,242.81,156.39,242.89,156.39 M274.99,156.42c0.1,0,0.22-0.01,0.35-0.02
					c0.65-0.11,1.31-0.22,1.97-0.22c0.16,0,0.31,0.01,0.47,0.02c0.22,0.02,0.44,0.03,0.66,0.03c1.14,0,2.27-0.2,3.41-0.21
					c0.39,0,0.59-0.19,0.69-0.52c0.82-2.76,0.44-4.42-2.08-6.37c-0.2-0.16-0.38-0.25-0.59-0.25c-0.13,0-0.27,0.03-0.43,0.1
					c-1.6,0.69-3.31,1.02-5.03,1.29c-0.51,0.08-0.5,0.12-0.21,0.52c1.08,1.5,1.33,3.15,0.55,4.84
					C274.45,156.28,274.61,156.42,274.99,156.42 M247.79,157.2c0.01,0,0.02,0,0.03,0c2.23-0.01,3.92-1.59,3.91-3.65
					c-0.02-1.98-1.75-3.56-3.92-3.58c-0.01,0-0.02,0-0.03,0c-2.18,0-3.92,1.61-3.92,3.64C243.85,155.65,245.55,157.2,247.79,157.2
					 M269.92,157.34C269.93,157.34,269.93,157.34,269.92,157.34c2.19,0,3.95-1.62,3.97-3.64c0.02-2.07-1.73-3.7-3.96-3.7
					c-0.01,0-0.02,0-0.03,0c-2.22,0.01-3.94,1.59-3.95,3.63C265.95,155.71,267.69,157.34,269.92,157.34 M258.49,154.68
					c-1.48,0-2.97-0.32-4.47-1.01c-0.31-0.14-0.57-0.29-0.75-0.29c-0.21,0-0.33,0.19-0.31,0.79c0.02,0.45-0.18,0.94-0.41,1.35
					c-0.28,0.5-0.06,0.65,0.35,0.81c1.68,0.67,3.39,1.23,5.16,1.23c0.19,0,0.38-0.01,0.57-0.02c0.15,0.01,0.3,0.01,0.45,0.01
					c2.02,0,3.88-0.57,5.73-1.18c0.42-0.14,0.57-0.3,0.31-0.76c-0.29-0.52-0.5-1.13-0.43-1.73c0.06-0.54-0.04-0.73-0.26-0.73
					c-0.15,0-0.36,0.09-0.62,0.21C262.05,154.21,260.28,154.68,258.49,154.68 M247.76,158.47c-0.04,0-0.08,0-0.12,0
					c-2.6-0.05-5.21-0.1-7.81-0.2c-2.38-0.1-4.75-0.27-7.13-0.39c-0.16-0.01-0.36-0.16-0.54-0.16c-0.12,0-0.23,0.07-0.32,0.31
					c-0.19,0.51,0.08,0.77,0.46,0.99c0.38,0.22,0.77,0.44,1.17,0.63c3.71,1.7,7.63,2.47,11.79,2.57c1.14-0.04,2.38-0.04,3.59-0.31
					c2.1-0.45,3.98-1.27,5.21-3.16c0.39-0.6,0.63-1.07-0.39-1.25c-0.39-0.07-0.78-0.26-1.14-0.46c-0.21-0.12-0.4-0.18-0.59-0.18
					c-0.24,0-0.48,0.11-0.74,0.34C250.25,158.08,249.05,158.47,247.76,158.47 M271.23,162.31c0.06,0,0.12,0,0.18,0
					c3.09-0.01,5.59-0.39,8.04-1.04c2.07-0.55,4.12-1.2,5.9-2.46c0.24-0.17,0.54-0.35,0.4-0.77c-0.09-0.25-0.22-0.31-0.38-0.31
					c-0.13,0-0.28,0.05-0.41,0.05c-1.74,0.1-3.48,0.23-5.22,0.31c-2.85,0.14-5.7,0.23-8.55,0.39c-0.52,0.03-1.06,0.08-1.59,0.08
					c-0.79,0-1.55-0.12-2.16-0.65c-0.68-0.58-1.32-0.81-1.97-0.81c-0.63,0-1.26,0.21-1.92,0.54c-0.06,0.03-0.12,0.05-0.18,0.05
					c-0.79,0.03-0.58,0.43-0.33,0.86c0.77,1.35,1.93,2.24,3.35,2.81C268.1,162.06,269.88,162.31,271.23,162.31 M245.43,163.04
					c-1.2,0-2.4-0.1-3.61-0.27c-3.45-0.47-6.82-1.27-9.88-3.05c-0.08-0.05-0.17-0.12-0.26-0.12c-0.06,0-0.13,0.04-0.19,0.17
					c-0.48,1-0.12,2.03,0.91,2.45c4.46,1.83,9.02,3.26,13.9,3.35c0.18,0.01,0.36,0.01,0.53,0.01c1.1,0,2.18-0.16,3.24-0.41
					c3.47-0.81,5.98-2.75,7.22-6.18c0.19-0.51,0.11-0.69-0.43-0.69c-0.31,0-0.62-0.09-0.93-0.16c-0.09-0.02-0.18-0.03-0.26-0.03
					c-0.27,0-0.45,0.13-0.62,0.48c-0.86,1.77-2.32,2.88-4.14,3.55C249.11,162.79,247.28,163.04,245.43,163.04 M271.18,165.65
					c0.72-0.05,1.64-0.07,2.55-0.19c4-0.52,7.79-1.78,11.51-3.31c0.76-0.32,1.01-0.7,0.88-1.49c-0.12-0.7-0.15-0.97-0.37-0.97
					c-0.16,0-0.42,0.14-0.88,0.37c-4,1.97-8.17,3.03-12.51,3.03c-1.25,0-2.51-0.09-3.79-0.27c-2.39-0.34-4.68-1.23-5.93-3.47
					c-0.47-0.84-0.94-1.16-1.61-1.16c-0.22,0-0.45,0.03-0.72,0.09c-0.47,0.1-0.57,0.2-0.42,0.64c0.45,1.23,1.04,2.37,1.94,3.34
					C264.31,164.94,267.53,165.6,271.18,165.65 M245.7,166.34C245.7,166.34,245.7,166.34,245.7,166.34
					c6.1,2.31,10.82,5.69,12.84,12.09c1.97-6.21,6.49-9.59,12.32-11.9c-0.81-0.08-1.44-0.17-2.07-0.21
					c-4.54-0.28-8.13-2.95-9.66-7.21c-0.1-0.27-0.07-0.66-0.51-0.66c0,0-0.01,0-0.01,0c-0.47,0.01-0.4,0.43-0.5,0.69
					c-1.25,3.44-3.65,5.61-7.15,6.62C249.33,166.22,247.66,166.34,245.7,166.34 M231.56,165.9c0.07,0,0.16-0.02,0.27-0.04
					c1.95-0.51,3.94-0.78,5.96-0.78c0.04,0,0.07,0,0.11,0c-2.08-0.7-4.12-1.42-6.11-2.27c-0.15-0.06-0.25-0.09-0.33-0.09
					c-0.17,0-0.21,0.15-0.2,0.44c0.02,0.76,0.03,1.52,0.02,2.28C231.28,165.77,231.35,165.9,231.56,165.9 M280.37,165.16
					c0.07,0,0.15-0.01,0.21-0.01c0.04,0,0.07,0,0.11,0c1.27,0,2.51,0.22,3.74,0.5c0.6,0.14,1.01,0.23,1.29,0.23
					c0.63,0,0.64-0.47,0.67-1.99v0c0,0,0,0,0,0s0,0,0,0c0,0-0.02-0.19,0-0.37c0.06-0.55-0.04-0.8-0.35-0.8
					c-0.15,0-0.34,0.05-0.58,0.16c-1.63,0.67-3.29,1.25-4.94,1.87c-0.13,0.05-0.37-0.02-0.37,0.19
					C280.15,165.13,280.25,165.16,280.37,165.16 M244.74,173.77c2.43,0,4.92-0.44,7.47-1.36c0.42-0.15,1.09-0.15,1.16-0.6
					c0.05-0.36-0.54-0.64-0.89-0.91c-4.33-3.39-9.29-4.88-14.73-4.99c-0.1,0-0.19,0-0.29,0c-1.85,0-3.67,0.26-5.45,0.74
					c-0.19,0.05-0.44-0.03-0.55,0.27c-0.17,0.49,0.42,2.61,0.84,2.88C236.29,172.38,240.42,173.77,244.74,173.77 M272.4,173.76
					c4.45,0,8.43-1.54,12.23-3.83c0.95-0.57,1.34-1.25,1.46-2.3c0.08-0.65-0.12-0.81-0.66-0.94c-1.8-0.43-3.6-0.78-5.45-0.78
					c-0.19,0-0.37,0-0.56,0.01c-0.21-0.01-0.41-0.01-0.62-0.01c-1.64,0-3.25,0.23-4.84,0.59c-3.68,0.85-7.05,2.36-9.93,4.87
					c-0.44,0.39-0.47,0.57,0.13,0.78c2.42,0.86,4.89,1.49,7.48,1.59C271.9,173.75,272.15,173.76,272.4,173.76 M285.52,170.28
					c-0.4,0.24-0.66,0.41-0.92,0.57c-3.46,2.07-7.13,3.5-11.22,3.67c-0.3,0.01-0.61,0.02-0.91,0.02c-3.08,0-6.04-0.66-8.91-1.78
					c-0.2-0.08-0.38-0.16-0.55-0.16c-0.15,0-0.3,0.06-0.45,0.24c-1.15,1.28-2.08,2.7-2.7,4.32c-0.1,0.25-0.13,0.34,0.18,0.45
					c2.97,1.07,5.96,2.06,9.15,2.26c0.42,0.03,0.85,0.04,1.26,0.04c4.26,0,8.22-1.32,12.12-2.97c0.19-0.08,0.43-0.21,0.52-0.38
					C284.15,174.61,284.98,172.58,285.52,170.28 M246.53,179.92c3.59,0,7.01-1,10.39-2.24c0.5-0.18,0.38-0.44,0.25-0.74
					c-0.58-1.34-1.35-2.56-2.27-3.71c-0.3-0.37-0.58-0.55-0.94-0.55c-0.19,0-0.41,0.05-0.66,0.15c-2.8,1.12-5.65,1.73-8.56,1.73
					c-1.41,0-2.83-0.14-4.27-0.43c-2.94-0.6-5.62-1.83-8.29-3.45c0.55,2.26,1.41,4.24,2.47,6.14c0.09,0.15,0.26,0.29,0.43,0.36
					c3.12,1.31,6.33,2.36,9.72,2.67C245.38,179.89,245.96,179.92,246.53,179.92 M282.11,178.05c-1.09,0.38-2.09,0.73-3.09,1.08
					c-2.8,0.98-5.65,1.55-8.56,1.55c-1.12,0-2.24-0.08-3.38-0.26c-2.41-0.38-4.71-1.18-7-1.96c-0.16-0.06-0.29-0.09-0.39-0.09
					c-0.2,0-0.3,0.13-0.36,0.49c-0.23,1.35-0.44,2.69-0.21,4.06c0.05,0.28,0.05,0.54,0.35,0.71c2.91,1.64,5.9,2.76,9.1,2.76
					c1.14,0,2.31-0.14,3.51-0.45C276.54,184.8,279.85,182.23,282.11,178.05 M249.04,186.43c3.08,0,5.88-1.23,8.54-2.76
					c0.24-0.14,0.39-0.26,0.41-0.56c0.11-1.43,0.09-2.85-0.21-4.26c-0.06-0.29-0.13-0.48-0.37-0.48c-0.08,0-0.19,0.02-0.33,0.08
					c-1.03,0.4-2.09,0.71-3.14,1.04c-2.43,0.77-4.89,1.21-7.39,1.21c-1.05,0-2.12-0.08-3.19-0.25c-2.63-0.41-5.14-1.21-7.85-2.22
					c0.88,1.47,1.86,2.59,2.82,3.73c2.74,3.27,6.44,4.39,10.48,4.46C248.9,186.43,248.97,186.43,249.04,186.43 M257.71,184.52
					c-3.03,1.67-6.08,2.76-9.26,2.76c-1.47,0-2.98-0.23-4.52-0.75c3.17,2.11,6.61,3.39,10.31,4c0.16,0.03,0.34,0.1,0.5,0.1
					c0.14,0,0.27-0.05,0.37-0.21C256.26,188.63,257.25,186.79,257.71,184.52 M262.49,190.68c0.04,0,0.09,0,0.15-0.01
					c4.16-0.65,8.04-2.02,11.44-4.54c-0.14,0.05-0.28,0.11-0.42,0.16c-1.71,0.54-3.43,0.95-5.21,0.95c-0.49,0-0.99-0.03-1.5-0.1
					c-2.49-0.34-4.79-1.15-6.99-2.33c-0.1-0.06-0.22-0.19-0.34-0.19c-0.05,0-0.1,0.02-0.14,0.08c-0.06,0.07,0.01,0.29,0.05,0.43
					c0.5,1.88,1.36,3.58,2.43,5.2C262.09,190.55,262.23,190.68,262.49,190.68 M258.51,190.92c0.73,0,1.45-0.04,2.18-0.07
					c0.01,0,0.03,0,0.04,0c0.04,0,0.08,0,0.12,0c0.22,0,0.46-0.03,0.19-0.44c-1.08-1.67-1.88-3.48-2.5-5.41
					c-0.59,2.03-1.51,3.89-2.64,5.7C256.78,190.87,257.65,190.92,258.51,190.92 M262.45,152.8
					C262.45,152.8,262.45,152.8,262.45,152.8C262.45,152.81,262.45,152.8,262.45,152.8 M194.76,35.16
					C194.76,35.16,194.76,35.16,194.76,35.16c-0.32,0-0.61,0.13-0.88,0.38c-0.81,0.76-0.14,1.53,0.18,1.9
					c0.38,0.43,0.78,0.85,1.17,1.25c0.73,0.76,1.49,1.54,2.08,2.43c2.01,3.01,2.64,6.49,2.03,11.3c-0.4,3.18-1.21,6.53-2.54,10.55
					c-2.85,8.62-6.7,16.77-9.74,22.91c-3.4,6.87-6.91,13.98-9.32,21.52c-1.58,4.95-3.22,11.51-1.83,18.36
					c1.12,5.53,3.58,10.45,7.31,14.65c5.05,5.67,11.62,9.79,20.09,12.58c7.31,2.41,15.53,3.93,25.89,4.77
					c0.17,0.01,0.35,0.03,0.39,0.06c0,0,0,0,0,0c0,0,0.03,0.06-0.03,0.32c-0.37,1.75-0.53,3.56-0.51,5.89
					c0.04,0.38,0.05,0.81,0.07,1.26c0.02,0.65,0.05,1.39,0.15,2.12c1.05,7.86,4.66,14.26,10.73,19.03
					c5.64,4.43,11.82,6.68,18.35,6.68c3.31,0,6.76-0.58,10.25-1.73c11.67-3.83,19.87-15.13,19.93-27.48
					c0.01-1.85-0.17-3.74-0.53-5.63c-0.01-0.05-0.02-0.1-0.03-0.15c-0.03-0.1-0.06-0.26-0.03-0.31c0-0.01,0.05-0.07,0.3-0.08
					c5.24-0.4,10.06-1.02,14.73-1.91c7.89-1.49,13.68-3.33,18.78-5.97c7.33-3.79,12.28-8.07,15.58-13.47
					c2.81-4.59,4.37-8.9,4.77-13.18c0.38-4.07-0.08-8.35-1.39-13.07c-2.12-7.63-5.38-14.67-8.6-21.15
					c-4.14-8.34-8.43-17.27-11.43-26.55c-1.42-4.39-2.72-9.14-2.55-14.14c0.12-3.58,1.22-6.41,3.37-8.65
					c0.62-0.64,1.22-1.3,1.83-1.96l0.38-0.42c0.55-0.6,0.56-1.26,0.04-1.78c-0.24-0.24-0.52-0.37-0.82-0.37
					c-0.32,0-0.65,0.15-0.96,0.44c-0.14,0.13-0.29,0.27-0.42,0.41c-2.87,2.99-5.75,6.35-8.83,10.27
					c-6.37,8.11-10.78,16.18-13.48,24.66c-2.56,8.02-3.75,16.36-4.67,23.55c-0.6,4.71-0.85,9.52-1.1,14.18
					c-0.08,1.46-0.15,2.91-0.24,4.36c-0.3,4.99-0.71,10.33-1.72,15.55c-1.22,6.34-4.21,11.68-8.86,15.89
					c-0.33,0.3-0.62,0.56-0.75,0.56c-0.13,0-0.38-0.27-0.73-0.65c-6.14-6.64-13.58-9.99-22.15-9.99c-1.18,0-2.39,0.06-3.61,0.19
					c-7.56,0.78-14.05,4.18-19.27,10.13c-0.17,0.19-0.3,0.29-0.39,0.29c-0.08,0-0.2-0.07-0.38-0.23c-4.13-3.84-6.7-7.65-8.07-12.01
					c-1.65-5.24-2.19-10.71-2.61-15.86c-0.3-3.73-0.54-7.78-0.76-12.72c-0.15-3.45-0.43-6.83-0.83-10.06
					c-0.53-4.26-1.22-8.56-2.03-12.8c-1.23-6.36-2.88-11.9-5.07-16.95c-2.89-6.66-6.94-13.15-12.39-19.82
					c-2.42-2.96-4.91-6.02-7.72-8.83C195.52,35.35,195.14,35.16,194.76,35.16L194.76,35.16z M311.14,52.64
					c1.25-1.94,2.62-3.69,4.07-5.54c0.2-0.26,0.41-0.52,0.61-0.78c-0.2,2.94-0.13,5.3,0.21,7.63c-1.14-0.92-2.4-1.38-3.84-1.38
					C311.85,52.57,311.5,52.59,311.14,52.64L311.14,52.64z M201.62,53.98c0.4-2.72,0.5-5.13,0.29-7.49c1.62,1.91,3.16,3.95,4.69,6.2
					c-0.62-0.06-1.1-0.09-1.51-0.09C203.85,52.6,203.24,52.93,201.62,53.98L201.62,53.98z M307.15,59c-0.01,0-0.01,0-0.03-0.02
					c0.01-0.02,0.1-0.15,0.14-0.22c0.04-0.06,0.08-0.12,0.11-0.17c0.7-1.28,1.52-2.76,2.53-4.08c0.53-0.7,1.38-0.79,2.22-0.79
					c0.15,0,0.3,0,0.44,0.01c1.46,0.03,2.55,0.89,3.75,2.17c0.15,0.16,0.17,0.34,0.19,0.58c0.01,0.13,0.03,0.26,0.06,0.39
					c0.26,0.97,0.51,1.95,0.76,2.93l0.33,1.29c0,0.02,0.01,0.03,0.01,0.05c0,0-0.01-0.01-0.01-0.01c-1.02-0.88-2.22-1.58-3.66-2.14
					c-1.19-0.46-2.36-0.69-3.48-0.69c-0.97,0-1.95,0.17-2.89,0.51c-0.07,0.02-0.14,0.06-0.21,0.1C307.32,58.95,307.21,59,307.15,59
					L307.15,59z M200.02,61.1c0.09-0.4,0.18-0.79,0.27-1.18c0.3-1.35,0.59-2.63,0.99-3.87c0.41-1.27,2.33-2.3,4.26-2.3
					c0.44,0,0.86,0.05,1.23,0.16c0.63,0.18,1.02,0.47,1.31,0.97c0.29,0.5,0.6,1,0.91,1.49c0.53,0.84,1.07,1.71,1.51,2.62
					c-1.11-0.44-2.25-0.66-3.37-0.66C204.88,58.33,202.61,59.22,200.02,61.1L200.02,61.1z M196.03,72.17
					c0.02-0.03,0.03-0.06,0.04-0.08c0.88-2.14,2-4.96,2.91-7.85c0.25-0.79,0.68-1.41,1.34-1.93c2.47-1.95,4.55-2.82,6.77-2.82
					c0.32,0,0.65,0.02,0.97,0.06c2.27,0.26,3.61,1.15,4.33,2.89c0.31,0.73,0.63,1.45,0.95,2.18c0.71,1.57,1.44,3.2,1.94,4.88
					c0,0,0,0,0,0c-0.04-0.02-0.08-0.05-0.12-0.07c-2.42-1.33-4.89-2.01-7.36-2.01c-3,0-6.11,1.01-9.24,3
					c-0.7,0.44-1.38,0.93-2.04,1.4C196.37,71.93,196.2,72.05,196.03,72.17L196.03,72.17z M302.37,69.48c-0.01,0-0.03-0.01-0.04-0.03
					c-0.02-0.06,0.07-0.2,0.11-0.28c0.06-0.1,0.13-0.2,0.16-0.32c0.62-1.82,1.43-3.6,2.23-5.33c0.2-0.43,0.4-0.86,0.59-1.3
					c0.91-2,2.64-2.47,4.39-2.67c0.07-0.01,0.14-0.01,0.21-0.01c0.07,0,0.14,0,0.2,0c0.07,0,0.15,0,0.22,0l0.06,0
					c2.66,0,5.03,1.09,7.67,3.54c0.18,0.17,0.25,0.4,0.33,0.67c0.02,0.09,0.05,0.17,0.08,0.25c1,2.9,1.92,5.52,3.04,8.11
					c0,0.01,0.01,0.01,0.01,0.02c0,0-0.01-0.01-0.01-0.01c-1.84-1.37-3.86-2.75-6.17-3.66c-1.87-0.73-3.74-1.1-5.57-1.1
					c-2.15,0-4.3,0.51-6.41,1.51c-0.07,0.03-0.13,0.07-0.2,0.11c-0.1,0.06-0.19,0.11-0.28,0.13c-0.15,0.04-0.27,0.13-0.39,0.21
					C302.53,69.4,302.42,69.48,302.37,69.48L302.37,69.48z M190.41,84.81l0.26-0.56c1.45-3.21,2.91-6.42,4.37-9.63
					c0.06-0.13,0.13-0.23,0.21-0.29c2.56-2.17,5.71-4.52,9.74-5.46c0.94-0.22,1.89-0.33,2.83-0.33c2.52,0,5.09,0.81,7.87,2.47
					c0.25,0.15,0.34,0.38,0.44,0.71c0.84,2.65,1.59,5.56,2.29,8.87c0.01,0.04,0.01,0.07,0.02,0.11c-0.03-0.02-0.05-0.05-0.07-0.07
					c-0.05-0.06-0.1-0.11-0.15-0.15c-2.89-2.43-6.26-3.66-10.01-3.66c-0.45,0-0.91,0.02-1.37,0.05c-4.14,0.32-8.19,1.87-12.38,4.76
					c-1.32,0.91-2.55,1.95-3.74,2.96C190.6,84.65,190.5,84.73,190.41,84.81L190.41,84.81z M299.27,80.55
					C299.27,80.55,299.27,80.55,299.27,80.55l0.07-0.31c0.66-2.83,1.34-5.76,2.25-8.57c0.09-0.28,0.24-0.49,0.45-0.62
					c2.64-1.69,5.17-2.51,7.76-2.51c0.26,0,0.52,0.01,0.78,0.03c2.68,0.17,5.32,1.12,7.83,2.8c0.76,0.51,1.51,1.06,2.24,1.6
					c0.38,0.28,0.76,0.56,1.14,0.83c0.85,0.61,1.2,1.51,1.58,2.47c0.13,0.33,0.26,0.67,0.42,1c0.8,1.67,1.58,3.39,2.34,5.05
					c0.32,0.7,0.64,1.41,0.97,2.11l0.19,0.42l0.03,0.06c-0.03-0.03-0.07-0.06-0.09-0.08l-0.07-0.07c-1.59-1.55-3.41-2.93-5.56-4.21
					c-4.31-2.56-8.1-3.76-11.93-3.76c-0.42,0-0.84,0.01-1.26,0.04c-3.33,0.23-6.25,1.38-8.66,3.41c-0.11,0.1-0.25,0.19-0.43,0.3
					C299.31,80.53,299.29,80.54,299.27,80.55L299.27,80.55z M181.62,103.86c0.08-0.21,0.17-0.42,0.25-0.63
					c0.43-1.1,0.84-2.14,1.28-3.18c1.59-3.75,3.37-7.56,5.29-11.34c0.69-1.36,1.87-2.35,3-3.3c0.21-0.18,0.43-0.36,0.64-0.54
					c4.15-3.58,8.08-5.65,12.36-6.52c1.3-0.26,2.57-0.4,3.77-0.4c3.87,0,7.28,1.4,10.14,4.17c0.29,0.28,0.45,0.58,0.53,1
					c0.38,1.91,0.68,3.87,0.96,5.77c0.16,1.06,0.32,2.12,0.49,3.17c-0.16-0.1-0.29-0.23-0.44-0.37c-0.12-0.11-0.23-0.22-0.35-0.31
					c-3.06-2.33-6.58-3.51-10.46-3.51c-0.73,0-1.48,0.04-2.24,0.12c-6.23,0.67-12.05,3.43-18.31,8.67
					C186.11,98.7,183.84,101.06,181.62,103.86L181.62,103.86z M297.34,91.97c0-0.01,0-0.02,0.01-0.03l0.02-0.08l0.09-0.55
					c0.46-2.75,0.93-5.6,1.42-8.39c0.13-0.77,0.73-1.17,1.37-1.59c0.2-0.13,0.39-0.26,0.57-0.4c2.56-1.97,5.38-2.93,8.63-2.93
					c1.57,0,3.26,0.23,5.04,0.69c3.38,0.88,6.58,2.6,10.38,5.58c1.21,0.95,2.3,2.08,3.35,3.17l0.41,0.42
					c0.82,0.85,1.3,1.95,1.76,3.02c0.14,0.33,0.28,0.65,0.43,0.97l0.29,0.63c1.57,3.34,3.19,6.8,4.52,10.31
					c0.06,0.16,0.13,0.31,0.2,0.46c0.08,0.17,0.16,0.34,0.22,0.51c-0.37-0.32-0.67-0.72-0.99-1.13c-0.19-0.25-0.38-0.5-0.59-0.74
					c-5.91-6.69-12.15-10.96-19.08-13.05c-2.31-0.7-4.54-1.05-6.64-1.05c-3.03,0-5.91,0.74-8.55,2.19
					c-1.05,0.58-1.99,1.29-2.77,1.91l-0.05,0.04C297.37,91.95,297.35,91.96,297.34,91.97L297.34,91.97z M177.94,120.82
					c-0.08-1.83,0.06-3.67,0.46-5.88c0.14-0.79,0.33-1.59,0.52-2.36c0.12-0.5,0.24-1.01,0.35-1.52c0.5-2.34,1.48-4.38,2.99-6.23
					c5.07-6.22,10.14-10.41,15.93-13.16c3.74-1.78,7.23-2.64,10.68-2.64c0.66,0,1.32,0.03,1.98,0.1c3.64,0.35,6.82,1.85,9.46,4.45
					c0.24,0.23,0.27,0.51,0.31,0.83l0.02,0.16c0.4,3.01,0.6,6.02,0.82,9.2l0,0.04c-2.01-1.33-4.04-2.24-6.19-2.77
					c-1.86-0.46-3.77-0.7-5.68-0.7c-3.22,0-6.47,0.63-9.94,1.94c-7.88,2.96-14.44,8.23-20.06,16.13c-0.45,0.64-0.89,1.29-1.35,1.99
					C178.13,120.53,178.04,120.67,177.94,120.82L177.94,120.82z M296.24,103.8c-0.08-0.05-0.08-0.12-0.04-0.37
					c0.01-0.07,0.02-0.14,0.03-0.2c0.11-1.24,0.22-2.48,0.33-3.72l0.21-2.31c0.03-0.31,0.05-0.62,0.07-0.92
					c0.04-0.53,0.08-1.08,0.15-1.62c0.14-1.18,1.01-1.77,1.92-2.4c0.17-0.11,0.33-0.22,0.49-0.34c2.64-1.91,5.63-2.84,9.15-2.84
					c0.91,0,1.88,0.06,2.87,0.19c5.34,0.69,10.42,3.06,16.01,7.49c3.19,2.52,6.17,5.69,9.37,9.95c0.74,0.98,1.02,2.12,1.31,3.32
					c0.07,0.28,0.14,0.56,0.21,0.84c0.77,2.86,1.52,6.23,1.36,9.73c-0.24-0.34-0.47-0.68-0.7-1.01c-0.64-0.93-1.3-1.9-2-2.81
					c-5.54-7.24-12.01-12.11-19.8-14.88c-3.02-1.08-6.06-1.62-9.02-1.62c-0.15,0-0.3,0-0.46,0c-4.17,0.08-7.91,1.17-11.1,3.23
					c-0.06,0.04-0.13,0.1-0.19,0.16C296.36,103.71,296.28,103.79,296.24,103.8L296.24,103.8z M181.59,133.37
					c-0.34-0.78-0.7-1.55-1.06-2.3c-1.18-2.5-2.3-4.85-2.45-7.6c-0.02-0.4,0-0.66,0.16-0.93c5.52-9.16,12.36-15.34,20.92-18.88
					c3.49-1.45,7.02-2.18,10.49-2.18c0.61,0,1.22,0.02,1.83,0.07c3.57,0.26,6.78,1.38,9.53,3.33l0.05,0.04
					c0.3,0.21,0.46,0.34,0.47,0.64c0.12,2.51,0.24,4.72,0.37,6.75c0.02,0.25-0.02,0.31-0.02,0.31c-0.06-0.02-0.23-0.08-0.36-0.13
					c-2.46-0.9-5.01-1.44-7.56-1.59c-0.56-0.03-1.12-0.05-1.68-0.05c-3.91,0-7.8,0.86-11.55,2.56
					c-8.33,3.77-14.51,10.11-18.89,19.41L181.59,133.37L181.59,133.37z M295.89,112.54c-0.03,0-0.04,0-0.04-0.01
					c0,0-0.06-0.05-0.05-0.38c0.04-2,0.16-4.1,0.39-6.61c0.03-0.33,0.22-0.47,0.54-0.68c2.64-1.77,5.49-2.82,8.71-3.19
					c0.9-0.1,1.81-0.15,2.71-0.15c3.91,0,7.88,0.97,12.15,2.95c3.74,1.74,7.18,4.12,10.51,7.27c3,2.83,5.75,6.23,8.4,10.4
					c0.56,0.87,0.38,1.76,0.19,2.7c-0.04,0.21-0.08,0.42-0.12,0.62c-0.25,1.51-0.91,2.9-1.55,4.26c-0.26,0.55-0.53,1.12-0.77,1.69
					c-0.21,0.5-0.44,0.99-0.7,1.55c-0.05,0.11-0.11,0.23-0.16,0.35c-1.19-2.66-2.4-4.86-3.81-6.88c-4.07-5.82-8.78-9.97-14.42-12.67
					c-3.3-1.58-6.82-2.54-10.45-2.86c-0.75-0.06-1.5-0.1-2.23-0.1c-3.01,0-6.02,0.56-8.93,1.65
					C296.08,112.52,295.97,112.54,295.89,112.54L295.89,112.54z M295.08,121.5c0,0-0.05-0.08,0.01-0.46
					c0.18-1.05,0.26-2.1,0.34-3.12c0.11-1.38,0.17-2.49,0.19-3.52c0.01-0.37,0.19-0.52,0.56-0.67c1.79-0.74,3.74-1.24,5.98-1.54
					c0.9-0.12,1.72-0.22,2.54-0.22c0.21,0,0.42,0.01,0.64,0.02c5.27,0.08,10.33,1.59,15.05,4.51c2.68,1.65,5.15,3.73,7.57,6.34
					c2.78,3.01,5.14,6.69,7.21,11.24c0.23,0.51,0.19,0.91-0.13,1.44c-1.45,2.34-3.42,4.52-6.02,6.67c-0.18,0.15-0.26,0.19-0.29,0.19
					c0.01-0.01-0.03-0.09-0.09-0.3c-0.94-3.77-2.72-7.36-5.28-10.66c-3.26-4.21-7.06-7.19-11.61-9.14c-3.13-1.34-6.3-2.02-9.42-2.02
					c-0.25,0-0.5,0-0.75,0.01c-2.24,0.08-4.21,0.44-6.02,1.11c-0.19,0.07-0.33,0.11-0.42,0.11
					C295.09,121.51,295.08,121.5,295.08,121.5L295.08,121.5L295.08,121.5z M188.97,142.45c-0.03-0.01-0.12-0.06-0.3-0.21
					c-1.99-1.68-4.49-4-6.2-7.05c-0.18-0.32-0.2-0.55-0.06-0.84c4.93-11.06,12.25-18,22.37-21.24c2.33-0.75,4.74-1.12,7.15-1.12
					c0.15,0,0.29,0,0.44,0c0.14,0,0.27,0,0.4,0c2.9,0,5.75,0.55,8.71,1.69c0.45,0.17,0.58,0.37,0.61,0.9
					c0.13,2.11,0.31,4.25,0.49,6.32l0.03,0.33l0.01,0.06c0.01,0.1,0.02,0.23,0.01,0.3c-0.07,0-0.15-0.02-0.3-0.06
					c-2.47-0.79-4.93-1.19-7.31-1.19c-5.45,0-10.8,2.1-15.91,6.23c-4.99,4.04-8.36,9.27-10.02,15.55
					C189.03,142.33,188.99,142.42,188.97,142.45L188.97,142.45z M293.83,129.61c0,0-0.02-0.08,0.04-0.34c0.45-1.92,0.78-3.94,0.98-6
					c0.03-0.35,0.25-0.44,0.65-0.57c2.23-0.73,4.42-1.19,5.71-1.19c0.07,0,0.13,0,0.2,0c5.97,0.06,10.73,1.65,15.43,5.15
					c3.64,2.71,6.55,6.24,8.67,10.5c0.83,1.67,1.49,3.45,2.03,5.46c0.12,0.46,0.1,0.71-0.38,1.07c-2.36,1.75-5.06,3.33-8.48,4.97
					c-0.1,0.05-0.26,0.13-0.33,0.13c0.01-0.01-0.03-0.07-0.08-0.33c-0.73-4.02-2.45-7.77-5.11-11.13c-2.11-2.67-4.44-4.6-7.12-5.9
					c-2.76-1.34-5.73-2.02-8.84-2.02c-0.99,0-2.01,0.07-3.03,0.2c-0.09,0.01-0.16,0.02-0.23,0.02
					C293.87,129.62,293.84,129.61,293.83,129.61L293.83,129.61z M215.4,121.51C215.4,121.51,215.4,121.51,215.4,121.51
					c0.18-0.01,0.34-0.01,0.51-0.01c1.9,0,3.94,0.37,6.24,1.12c0.51,0.17,0.67,0.37,0.72,0.92c0.19,2.03,0.49,3.82,0.91,5.46
					c0.11,0.42,0.07,0.54,0.04,0.57c-0.01,0.01-0.06,0.06-0.26,0.06c-0.09,0-0.2-0.01-0.32-0.03c-0.94-0.13-1.89-0.19-2.82-0.19
					c-2.4,0-4.76,0.44-7.03,1.3c-5.29,2.01-9.23,5.87-12.07,11.79c-1.01,2.11-1.64,4.11-1.92,6.12c-0.01,0.1-0.03,0.16-0.04,0.19
					c-0.06-0.02-0.16-0.06-0.2-0.08c-3.09-1.36-6.07-3.11-8.87-5.2c-0.28-0.21-0.34-0.37-0.24-0.69
					c3.4-11.57,10.45-18.45,21.55-21.04c0.88-0.21,1.83-0.3,3.08-0.3C214.91,121.51,215.15,121.51,215.4,121.51
					C215.39,121.51,215.39,121.51,215.4,121.51L215.4,121.51z M210.61,152.49l-0.03,0c-0.05,0-0.11-0.01-0.2-0.03
					c-3.51-0.89-6.63-1.87-9.55-3c-0.38-0.15-0.42-0.29-0.36-0.55c1.88-8.58,6.24-14.18,13.32-17.13c2.11-0.87,4.32-1.13,7.2-1.25
					c0.21,0.02,0.49,0.03,0.78,0.04c0.71,0.01,1.52,0.03,2.16,0.34c0.54,0.26,0.64,0.88,0.75,1.59c0.07,0.41,0.13,0.83,0.3,1.21
					c0.19,0.43,0.35,0.87,0.51,1.31c0.21,0.56,0.42,1.15,0.69,1.7c0.11,0.24,0.13,0.34,0.13,0.37c0,0-0.01,0-0.02,0
					c-0.03,0-0.09,0.01-0.2,0.01c-0.06,0-0.13,0-0.2,0c-0.22-0.01-0.45-0.01-0.67-0.01c-2.78,0-5.28,0.71-7.44,2.11
					c-3.54,2.29-5.75,5.8-6.76,10.71c-0.16,0.76-0.28,1.56-0.36,2.37C210.65,152.44,210.61,152.48,210.61,152.49L210.61,152.49z
					 M291.41,137.06c0.01-0.04,0.02-0.1,0.07-0.21c0.88-2.08,1.49-3.83,1.9-5.51c0.13-0.53,0.46-0.57,1.04-0.62l0.15-0.01
					c0.27-0.02,0.57-0.03,0.93-0.03c0.24,0,0.47,0,0.71,0.01c0.24,0,0.48,0.01,0.72,0.01c0.21-0.01,0.41-0.01,0.61-0.01
					c5.47,0,10.15,2.15,13.9,6.4c3.09,3.5,5.01,7.49,5.72,11.86c0.06,0.35-0.03,0.39-0.29,0.49c-3.28,1.21-6.35,2.17-9.39,2.94
					c-0.12,0.03-0.22,0.05-0.29,0.05c-0.05,0-0.06-0.01-0.06-0.01c-0.03-0.03-0.06-0.15-0.08-0.37c-0.39-3.45-1.36-6.31-2.97-8.75
					c-2.23-3.38-5.31-5.42-9.14-6.07c-0.92-0.16-1.84-0.16-2.74-0.16l-0.52,0C291.62,137.07,291.48,137.07,291.41,137.06
					L291.41,137.06z M252.15,137.61c0.04-0.02,0.13-0.06,0.3-0.1c2.07-0.48,4.19-0.72,6.3-0.72c2,0,4.06,0.22,6.12,0.64
					c0.21,0.04,0.3,0.08,0.35,0.1c-0.03,0.06-0.11,0.17-0.23,0.33c-3.27,4.04-5.35,8.24-6.34,12.79c-0.44-1.98-0.98-3.67-1.67-5.24
					c-1.2-2.74-2.76-5.28-4.63-7.55C252.3,137.81,252.19,137.69,252.15,137.61L252.15,137.61z M249.32,138.49l0.22-0.08
					c0.39-0.14,0.78-0.29,1.18-0.43c0,0,0.01,0,0.01,0c0.07,0,0.13,0.08,0.17,0.13c0.02,0.03,0.05,0.06,0.07,0.09
					c3.97,4.83,6.19,9.75,6.76,15.03l0.01,0.13l0.01,0.15c0,0.03,0,0.05,0,0.06c-0.01,0-0.03,0-0.06,0c-0.04,0-0.09,0-0.14-0.01
					c-1.45-0.17-1.45-0.17-1.84-1.73c-1.11-4.49-3.29-8.34-5.47-11.83c-0.05-0.08-0.1-0.15-0.15-0.23
					c-0.07-0.11-0.14-0.21-0.21-0.32c-0.17-0.29-0.34-0.57-0.51-0.86L249.32,138.49L249.32,138.49z M259.57,153.02
					c0.37-3.32,1.46-6.53,3.31-9.82c0.92-1.63,2.04-3.25,3.32-4.8c0.14-0.17,0.55-0.29,0.98-0.29c0.43,0,0.81,0.12,0.94,0.29
					c0.03,0.05,0.04,0.06-0.11,0.22c-0.06,0.06-0.11,0.12-0.15,0.18c-2.43,3.71-4.04,6.7-5.22,9.7c-0.52,1.32-0.93,2.7-1.24,4.21
					c-0.06,0.31-1.05,0.89-1.64,0.89c-0.12,0-0.16-0.03-0.17-0.03c-0.03-0.02-0.03-0.05-0.02-0.23
					C259.57,153.24,259.58,153.13,259.57,153.02L259.57,153.02z M295.53,154.5c-0.19,0-0.24-0.04-0.25-0.05
					c-0.06-0.06-0.04-0.3-0.03-0.51c0.14-2.96-0.93-5.57-3.19-7.78c-1.21-1.18-2.7-2.1-4.43-2.74c-0.32-0.12-0.37-0.18-0.38-0.19
					c0.01-0.05,0.19-0.27,0.25-0.34l0.4-0.5c0.97-1.2,1.97-2.44,2.75-3.82c0.21-0.37,0.51-0.44,1.01-0.44l0.21,0l0.21,0l0.06,0
					c6.1,0.27,10.16,3.1,12.39,8.66c0.6,1.5,1.01,3.14,1.27,5.16c0.07,0.57-0.02,0.84-0.78,0.98c-0.71,0.13-1.43,0.26-2.14,0.39
					c-2.31,0.43-4.7,0.87-7.08,1.16C295.7,154.5,295.61,154.5,295.53,154.5L295.53,154.5z M222.32,154.58
					c-0.06,0-0.13-0.01-0.21-0.02c-2.82-0.42-6.15-0.94-9.52-1.62c-0.59-0.12-0.77-0.26-0.83-0.36c-0.01-0.02-0.05-0.1,0.01-0.28
					c0.01-0.02,0.01-0.04,0.01-0.06c0.74-5.69,2.67-9.37,6.27-11.92c2.1-1.49,4.58-2.19,7.8-2.19c0.21,0,0.42,0,0.63,0.01
					c0.29,0.01,0.45,0.1,0.56,0.31c0.67,1.37,1.63,2.54,2.56,3.68c0.23,0.28,0.45,0.56,0.68,0.84c0.06,0.07,0.2,0.25,0.22,0.32
					c0,0,0,0-0.01,0c-0.02,0-0.11,0.05-0.33,0.14c-3.99,1.58-6.4,4.04-7.37,7.54c-0.28,1.03-0.39,2.1-0.31,3.28
					c0.02,0.25-0.03,0.3-0.03,0.3C222.44,154.56,222.42,154.58,222.32,154.58L222.32,154.58z M262.74,152.66
					c0.56-2.68,1.53-4.86,2.42-6.65c1.15-2.34,2.55-4.6,3.89-6.69c0.2-0.31,0.32-0.35,0.43-0.35c0.1,0,0.23,0.04,0.4,0.12
					c3.65,1.63,6.9,4.01,9.65,7.07c0.19,0.21,0.19,0.28,0.19,0.28c0,0-0.04,0.06-0.3,0.17c-2.09,0.9-4.47,1.46-7.27,1.71
					c-0.39,0.04-0.77,0.05-1.16,0.07c-0.59,0.03-1.19,0.06-1.79,0.15c-1.86,0.27-3.24,1.17-4.1,2.66c-0.42,0.73-1.12,0.99-1.86,1.26
					C263.07,152.53,262.9,152.59,262.74,152.66L262.74,152.66z M237.91,146.37c0.01,0,0.04-0.06,0.13-0.16
					c2.61-3.02,5.88-5.42,9.71-7.13c0.11-0.05,0.2-0.09,0.25-0.09c0.05,0,0.1,0.07,0.14,0.13c2.42,3.73,5.1,8.12,6.33,13.07
					c0.02,0.09,0.03,0.18,0.04,0.29c0.01,0.06,0.01,0.13,0.02,0.2c0.01,0.02,0.01,0.04,0.01,0.06c-0.03-0.01-0.07-0.03-0.12-0.06
					l-0.29-0.15c-0.61-0.32-1.18-0.61-1.54-1.29c-0.86-1.64-2.4-2.58-4.46-2.73c-2.27-0.16-4.69-0.38-7.06-0.97
					c-0.96-0.24-1.95-0.6-2.96-1.06C237.98,146.42,237.93,146.39,237.91,146.37L237.91,146.37z M234.06,155.69
					c-0.08,0-0.18-0.01-0.29-0.01c-2.91-0.22-5.97-0.49-9.37-0.83c-0.69-0.07-0.82-0.28-0.83-0.84c-0.05-2.87,0.95-5.26,2.99-7.11
					c1.27-1.15,2.72-2,4.31-2.51c0.14-0.05,0.26-0.08,0.35-0.08c0.08,0,0.17,0.02,0.3,0.17c1.54,1.73,3.18,3.04,4.99,4.01
					c-0.04,0.03-0.07,0.06-0.1,0.08c-2.3,1.77-3.06,4.04-2.28,6.74c0.08,0.26,0.08,0.36,0.07,0.39c0,0-0.01,0-0.01,0
					C234.18,155.68,234.14,155.69,234.06,155.69L234.06,155.69z M283.69,155.75c-0.09,0-0.13-0.02-0.17-0.1
					c-0.06-0.1,0.04-0.39,0.1-0.61c0.06-0.2,0.13-0.4,0.15-0.6c0.24-2.25-0.3-3.89-1.69-5.15c-0.29-0.26-0.72-0.66-0.71-0.79
					c0.01-0.12,0.47-0.41,0.84-0.64c1.32-0.82,2.56-1.88,3.81-3.23c0.2-0.22,0.34-0.3,0.5-0.3c0.08,0,0.18,0.02,0.29,0.06
					c4.07,1.52,6.32,3.88,7.08,7.42c0.07,0.31,0.07,0.63,0.07,0.93c0,0.2,0,0.39,0.02,0.58c0,1.31,0,1.31-1.25,1.5
					c-2.56,0.39-5.2,0.62-8.07,0.71c-0.18,0.01-0.37,0.06-0.55,0.12C283.96,155.7,283.81,155.75,283.69,155.75L283.69,155.75z
					 M242.89,156.14c-0.07,0-0.16,0-0.25-0.01c-2.01-0.12-4.38-0.25-6.75-0.35c-0.38-0.02-0.48-0.1-0.59-0.5
					c-0.75-2.65-0.13-4.46,2.07-6.03c0.16-0.12,0.28-0.18,0.4-0.18c0.06,0,0.14,0.02,0.22,0.05c1.66,0.7,3.38,1.08,5.42,1.42
					c-0.06,0.09-0.11,0.17-0.17,0.25c-1.13,1.67-1.25,3.35-0.35,5.01c0.1,0.18,0.13,0.28,0.15,0.33
					C243,156.14,242.96,156.14,242.89,156.14L242.89,156.14z M274.33,150.53c0.04-0.01,0.08-0.01,0.12-0.02
					c1.58-0.25,3.41-0.58,5.09-1.31c0.13-0.06,0.23-0.08,0.33-0.08c0.14,0,0.26,0.06,0.44,0.19c2.35,1.82,2.83,3.3,2,6.1
					c-0.07,0.25-0.2,0.34-0.46,0.34c-0.56,0.01-1.12,0.06-1.67,0.11c-0.57,0.05-1.16,0.11-1.74,0.11c-0.21,0-0.43-0.01-0.64-0.02
					c-0.16-0.01-0.32-0.02-0.49-0.02c-0.68,0-1.34,0.1-2.02,0.22c-0.1,0.01-0.21,0.02-0.31,0.02c-0.11,0-0.15-0.01-0.16-0.02
					c0.01-0.01,0.01-0.11,0.15-0.42c0.78-1.71,0.59-3.47-0.58-5.09C274.38,150.61,274.35,150.57,274.33,150.53L274.33,150.53z
					 M247.79,157.2v-0.25c-2.1,0-3.69-1.44-3.69-3.34c0-0.86,0.34-1.68,0.96-2.3c0.7-0.7,1.66-1.09,2.71-1.09
					c2.07,0.01,3.68,1.48,3.7,3.33c0.01,0.87-0.32,1.7-0.93,2.31c-0.68,0.69-1.65,1.08-2.73,1.09L247.79,157.2L247.79,157.2z
					 M269.92,157.34v-0.25c-1.07,0-2.04-0.39-2.74-1.1c-0.63-0.64-0.98-1.47-0.97-2.36c0.01-1.92,1.6-3.37,3.7-3.38c0,0,0,0,0,0
					c1.1,0,2.07,0.39,2.78,1.1c0.63,0.63,0.97,1.47,0.96,2.34c-0.02,1.87-1.68,3.39-3.71,3.39L269.92,157.34L269.92,157.34z
					 M258.49,154.93c1.76,0,3.59-0.45,5.43-1.34c0.13-0.06,0.39-0.19,0.51-0.19c0,0,0,0,0,0c0,0,0.05,0.09,0.01,0.46
					c-0.06,0.58,0.09,1.22,0.46,1.88c0.12,0.21,0.09,0.27,0.09,0.27c0,0.01-0.03,0.06-0.27,0.14c-1.85,0.62-3.67,1.17-5.65,1.17
					c-0.15,0-0.29,0-0.44-0.01c-0.21,0.01-0.4,0.02-0.58,0.02c-1.77,0-3.48-0.58-5.07-1.22c-0.19-0.08-0.3-0.14-0.31-0.18
					c0-0.01-0.02-0.08,0.09-0.28c0.21-0.38,0.46-0.94,0.44-1.48c-0.02-0.45,0.06-0.54,0.06-0.54c0.09,0.01,0.28,0.1,0.47,0.18
					l0.18,0.09C255.4,154.58,256.94,154.93,258.49,154.93L258.49,154.93z M247.76,158.72c1.43,0,2.65-0.45,3.64-1.33
					c0.21-0.19,0.39-0.28,0.57-0.28c0.14,0,0.28,0.05,0.47,0.15c0.38,0.21,0.79,0.42,1.22,0.49c0.42,0.07,0.49,0.18,0.51,0.21
					c0.06,0.13-0.19,0.5-0.29,0.66c-1.03,1.57-2.59,2.51-5.06,3.05c-1.06,0.23-2.13,0.26-3.17,0.29l-0.38,0.01
					c-4.36-0.11-8.18-0.95-11.68-2.55c-0.35-0.16-0.71-0.36-1.04-0.56l-0.11-0.06c-0.38-0.22-0.47-0.38-0.35-0.69
					c0.04-0.12,0.08-0.14,0.08-0.14c0.04,0,0.12,0.04,0.19,0.06c0.1,0.04,0.21,0.09,0.34,0.1c0.98,0.05,1.96,0.11,2.94,0.17
					c1.37,0.08,2.79,0.17,4.19,0.22c2.61,0.1,5.19,0.16,7.82,0.2L247.76,158.72L247.76,158.72z M263.06,158.01
					c0.01,0,0.08-0.04,0.31-0.05c0.09,0,0.18-0.03,0.28-0.08c0.71-0.35,1.29-0.51,1.81-0.51c0.63,0,1.22,0.25,1.81,0.75
					c0.74,0.63,1.63,0.71,2.32,0.71c0.37,0,0.75-0.03,1.11-0.05c0.17-0.01,0.33-0.02,0.5-0.03c1.65-0.09,3.33-0.16,4.96-0.23
					c1.2-0.05,2.4-0.1,3.59-0.16c1.06-0.05,2.13-0.12,3.19-0.19c0.68-0.04,1.35-0.09,2.03-0.13c0.07,0,0.14-0.02,0.22-0.03
					c0.06-0.01,0.12-0.02,0.18-0.02c0.05,0,0.09,0,0.14,0.14c0.07,0.19,0.01,0.27-0.26,0.45l-0.04,0.03
					c-1.66,1.17-3.53,1.82-5.82,2.42c-2.67,0.7-5.13,1.02-7.98,1.03l-0.18,0c-1.56,0-3.3-0.34-4.75-0.92
					c-1.47-0.59-2.53-1.47-3.23-2.7C263.19,158.34,263.05,158.09,263.06,158.01C263.06,158.01,263.06,158.01,263.06,158.01
					L263.06,158.01z M245.43,163.29c2.06,0,3.88-0.3,5.58-0.93c2.01-0.74,3.45-1.98,4.28-3.68c0.15-0.31,0.27-0.34,0.4-0.34
					c0.06,0,0.12,0.01,0.21,0.03l0.18,0.04c0.26,0.06,0.53,0.12,0.8,0.12c0.23,0,0.28,0.04,0.29,0.04c0,0,0,0,0,0
					c-0.01,0,0,0.07-0.09,0.32c-1.17,3.21-3.47,5.18-7.05,6.02c-1.15,0.27-2.19,0.4-3.18,0.4c-0.17,0-0.35,0-0.52-0.01
					c-5.3-0.1-10.17-1.83-13.82-3.33c-0.43-0.18-0.73-0.47-0.87-0.84c-0.15-0.38-0.11-0.83,0.09-1.26c0.01,0.01,0.02,0.01,0.02,0.01
					l0.07,0.05c2.68,1.56,5.76,2.51,9.97,3.09C243.12,163.2,244.32,163.29,245.43,163.29L245.43,163.29z M260.08,158.6
					c0.01,0,0.06-0.03,0.29-0.08c0.26-0.06,0.48-0.09,0.67-0.09c0.49,0,0.92,0.17,1.39,1.03c1.12,2,3.12,3.18,6.11,3.6
					c1.27,0.18,2.56,0.27,3.82,0.27c4.26,0,8.51-1.03,12.62-3.05l0.17-0.09c0.24-0.12,0.46-0.23,0.56-0.25
					c0.04,0.1,0.09,0.36,0.13,0.62l0.02,0.14c0.11,0.65-0.06,0.94-0.72,1.21c-4.36,1.8-7.99,2.85-11.45,3.3
					c-0.63,0.08-1.27,0.12-1.84,0.15c-0.24,0.01-0.47,0.03-0.68,0.04c-3.06-0.04-6.53-0.47-9.16-3.32
					c-0.79-0.85-1.38-1.89-1.89-3.25C260.07,158.66,260.08,158.61,260.08,158.6C260.08,158.6,260.08,158.6,260.08,158.6
					L260.08,158.6z M246.96,166.57c1.65-0.06,2.88-0.23,4.07-0.58c3.6-1.04,6.06-3.32,7.31-6.77c0.03-0.08,0.04-0.16,0.06-0.25
					c0.05-0.26,0.07-0.28,0.21-0.28c0,0,0,0,0,0c0.14,0,0.17,0.02,0.23,0.28c0.02,0.07,0.04,0.15,0.06,0.21
					c1.56,4.33,5.25,7.09,9.88,7.37c0.35,0.02,0.7,0.06,1.09,0.1c-5.99,2.56-9.53,5.99-11.32,10.98
					C256.74,172.6,253.11,169.13,246.96,166.57L246.96,166.57z M280.83,164.9c0.39-0.14,0.77-0.29,1.16-0.43
					c1.17-0.43,2.38-0.87,3.56-1.36c0.22-0.09,0.38-0.14,0.49-0.14c0.01,0,0.06,0,0.07,0.01c0.01,0.01,0.07,0.11,0.03,0.5
					c-0.02,0.17-0.02,0.3,0,0.4c-0.04,1.63-0.09,1.74-0.42,1.74c-0.25,0-0.65-0.09-1.2-0.22
					C283.42,165.16,282.16,164.92,280.83,164.9L280.83,164.9z M231.54,165.65c0-0.01-0.01-0.07-0.01-0.21
					c0.02-0.75,0-1.51-0.01-2.25c0-0.11,0-0.17,0-0.21c0.04,0.01,0.09,0.03,0.18,0.06c1.62,0.69,3.28,1.29,4.81,1.82
					c-1.59,0.09-3.18,0.34-4.73,0.75c-0.09,0.02-0.16,0.04-0.21,0.04C231.55,165.65,231.55,165.65,231.54,165.65L231.54,165.65z
					 M244.74,173.52c-4.14,0-8.27-1.32-12.29-3.93c-0.31-0.2-0.88-2.18-0.74-2.58c0.02-0.06,0.02-0.07,0.17-0.08
					c0.07-0.01,0.14-0.01,0.2-0.03c1.81-0.49,3.62-0.73,5.38-0.73l0.29,0c5.71,0.12,10.48,1.73,14.58,4.94
					c0.08,0.07,0.18,0.13,0.28,0.2c0.18,0.13,0.53,0.36,0.51,0.48c-0.02,0.15-0.26,0.22-0.6,0.29c-0.14,0.03-0.28,0.06-0.4,0.11
					C249.65,173.06,247.16,173.52,244.74,173.52L244.74,173.52z M263.97,171.79c0.02-0.04,0.08-0.11,0.23-0.23
					c2.67-2.33,5.88-3.91,9.82-4.82c1.71-0.4,3.27-0.59,4.78-0.59c0.2,0,0.41,0,0.61,0.01c0.2-0.01,0.39-0.01,0.57-0.01
					c1.92,0,3.8,0.39,5.39,0.77c0.44,0.1,0.52,0.17,0.47,0.67c-0.12,1.01-0.49,1.6-1.34,2.12c-4.29,2.59-8.13,3.79-12.1,3.79
					c-0.25,0-0.5,0-0.75-0.01c-2.29-0.09-4.64-0.59-7.4-1.57C264.09,171.86,264.01,171.82,263.97,171.79L263.97,171.79z
					 M272.47,174.79c0.3,0,0.61-0.01,0.92-0.02c3.71-0.16,7.42-1.37,11.33-3.71c0.13-0.08,0.26-0.16,0.41-0.25
					c-0.5,1.92-1.25,3.76-2.26,5.61c-0.02,0.04-0.1,0.15-0.4,0.27c-3.44,1.46-7.55,2.95-12.02,2.95c-0.42,0-0.84-0.01-1.25-0.04
					c-3.19-0.2-6.24-1.22-9.08-2.24c-0.03-0.01-0.05-0.02-0.07-0.03c0.01-0.03,0.02-0.06,0.03-0.09c0.58-1.52,1.45-2.9,2.65-4.24
					c0.12-0.14,0.21-0.15,0.27-0.15c0.1,0,0.22,0.05,0.36,0.11l0.1,0.04C266.58,174.2,269.53,174.79,272.47,174.79L272.47,174.79z
					 M246.53,179.67c-0.57,0-1.15-0.03-1.71-0.08c-2.94-0.27-5.92-1.09-9.65-2.66c-0.13-0.06-0.26-0.16-0.31-0.25
					c-1.05-1.88-1.79-3.64-2.29-5.49c2.79,1.64,5.3,2.65,7.85,3.17c1.43,0.29,2.89,0.44,4.32,0.44c2.86,0,5.77-0.59,8.66-1.74
					c0.23-0.09,0.41-0.13,0.57-0.13c0.27,0,0.48,0.13,0.74,0.46c0.95,1.19,1.68,2.38,2.23,3.65c0.05,0.11,0.11,0.25,0.09,0.29
					c0,0-0.02,0.05-0.2,0.12C253.85,178.55,250.29,179.67,246.53,179.67L246.53,179.67z M270.45,180.94c2.8,0,5.63-0.51,8.65-1.57
					l2-0.7l0.47-0.16c-2.19,3.75-5.33,6.11-9.56,7.21c-1.14,0.29-2.3,0.44-3.44,0.44c-2.83,0-5.68-0.87-8.98-2.73
					c-0.15-0.08-0.17-0.18-0.21-0.42l-0.02-0.12c-0.22-1.3-0.04-2.57,0.21-3.97c0.04-0.24,0.09-0.28,0.09-0.28c0,0,0,0,0.01,0
					c0.11,0,0.24,0.04,0.33,0.07l0.38,0.13c2.14,0.73,4.36,1.48,6.66,1.84C268.16,180.85,269.31,180.94,270.45,180.94L270.45,180.94
					z M246.56,180.96c2.42,0,4.86-0.4,7.47-1.22l0.67-0.21c0.82-0.25,1.67-0.52,2.49-0.84c0.13-0.05,0.2-0.06,0.23-0.06
					c0.04,0.01,0.07,0.06,0.12,0.28c0.26,1.22,0.33,2.56,0.2,4.19c-0.01,0.17-0.07,0.24-0.29,0.36c-2.21,1.27-5.16,2.72-8.42,2.72
					l-0.22,0c-4.53-0.09-7.9-1.52-10.29-4.38l-0.31-0.37c-0.7-0.83-1.42-1.69-2.1-2.71c2.12,0.78,4.59,1.57,7.2,1.98
					C244.4,180.87,245.48,180.96,246.56,180.96L246.56,180.96z M262.49,190.43c-0.12,0-0.2-0.03-0.34-0.24
					c-1.16-1.76-1.94-3.44-2.39-5.13l-0.02-0.07c0-0.01,0-0.02-0.01-0.03c0.03,0.03,0.07,0.05,0.1,0.07
					c2.36,1.26,4.68,2.03,7.08,2.35c0.5,0.07,1.02,0.1,1.53,0.1c1.47,0,2.88-0.28,4.09-0.61c-2.82,1.78-6.09,2.94-9.93,3.54
					C262.56,190.42,262.52,190.43,262.49,190.43L262.49,190.43z M248.45,187.52c2.8,0,5.64-0.8,8.89-2.53
					c-0.53,2.13-1.52,3.86-2.44,5.28c-0.05,0.08-0.1,0.09-0.16,0.09c-0.08,0-0.17-0.02-0.26-0.05c-0.07-0.02-0.14-0.03-0.2-0.04
					c-3.21-0.53-6.09-1.54-8.75-3.06C246.5,187.42,247.48,187.52,248.45,187.52L248.45,187.52z M256.3,190.52
					c1-1.65,1.73-3.17,2.25-4.71c0.63,1.77,1.38,3.33,2.28,4.74c0.01,0.02,0.03,0.04,0.04,0.06c-0.01,0-0.01,0-0.02,0l-0.06,0
					l-0.06,0c0,0-0.05,0-0.05,0l-0.24,0.01c-0.63,0.03-1.28,0.06-1.93,0.06C257.68,190.67,256.97,190.62,256.3,190.52L256.3,190.52z
					"/>
              </g>
                <path class="green-miami" d="M339.89,121.33c-1.04-1.47-2.01-2.97-3.1-4.4c-5.18-6.77-11.59-11.91-19.68-14.8
				c-3.02-1.08-6.18-1.67-9.39-1.61c-3.92,0.07-7.63,1.03-10.97,3.19c-0.19,0.13-0.39,0.45-0.65,0.28
				c-0.28-0.18-0.14-0.52-0.11-0.79c0.18-2.01,0.36-4.02,0.54-6.03c0.08-0.85,0.12-1.7,0.22-2.55c0.18-1.52,1.47-2.15,2.51-2.91
				c3.67-2.65,7.8-3.25,12.2-2.69c6.19,0.79,11.35,3.75,16.13,7.54c3.63,2.87,6.65,6.31,9.41,10c0.95,1.26,1.17,2.79,1.57,4.24
				C339.49,114.24,340.19,117.7,339.89,121.33z"/>
                <path class="green-miami" d="M336.08,133.92c-1.17-2.7-2.45-5.1-4.01-7.34c-3.74-5.36-8.37-9.74-14.32-12.58
				c-3.28-1.57-6.72-2.52-10.36-2.84c-3.83-0.33-7.49,0.2-11.06,1.54c-0.55,0.21-0.79,0.14-0.77-0.54c0.04-2.21,0.19-4.42,0.39-6.63
				c0.04-0.44,0.32-0.65,0.65-0.87c2.68-1.8,5.59-2.86,8.82-3.23c5.31-0.61,10.24,0.61,14.99,2.82c3.94,1.83,7.42,4.32,10.58,7.31
				c3.29,3.11,6.02,6.64,8.43,10.44c0.74,1.16,0.3,2.33,0.1,3.5c-0.35,2.16-1.5,4.02-2.34,6C336.86,132.26,336.5,133,336.08,133.92z
				"/>
                <path class="green-miami" d="M305.34,111.75c5.48,0.08,10.5,1.67,15.16,4.54c2.86,1.76,5.36,3.94,7.62,6.39
				c3.08,3.34,5.38,7.19,7.25,11.31c0.27,0.59,0.22,1.08-0.15,1.67c-1.62,2.62-3.72,4.78-6.08,6.73c-0.49,0.4-0.64,0.33-0.78-0.24
				c-0.97-3.9-2.78-7.4-5.23-10.56c-3.08-3.97-6.85-7.07-11.51-9.06c-3.23-1.38-6.56-2.1-10.06-1.98c-2.02,0.07-4.02,0.38-5.94,1.09
				c-0.67,0.25-0.92,0.15-0.79-0.64c0.17-1.02,0.25-2.06,0.33-3.1c0.09-1.17,0.16-2.33,0.19-3.5c0.01-0.53,0.32-0.73,0.71-0.89
				c1.94-0.8,3.97-1.28,6.04-1.56C303.18,111.81,304.25,111.68,305.34,111.75z"/>
                <path class="green-miami" d="M336.38,104.32c-0.93-0.53-1.43-1.49-2.11-2.26c-5.23-5.93-11.3-10.66-18.96-12.98
				c-5.1-1.54-10.17-1.53-15,1.12c-0.98,0.54-1.86,1.19-2.73,1.88c-0.12,0.09-0.3,0.25-0.38,0.22c-0.21-0.09-0.12-0.33-0.09-0.5
				c0.5-2.98,0.99-5.97,1.51-8.95c0.2-1.14,1.27-1.55,2.03-2.14c4.2-3.24,8.93-3.57,13.89-2.28c3.94,1.02,7.3,3.14,10.47,5.62
				c1.38,1.08,2.57,2.36,3.78,3.62c1.1,1.14,1.58,2.67,2.24,4.07c1.69,3.61,3.41,7.22,4.82,10.96
				C336.05,103.24,336.39,103.72,336.38,104.32L336.38,104.32z"/>
                <path class="green-miami" d="M327.61,84.91c-0.05,0.09-0.08,0.22-0.14,0.24c-0.22,0.08-0.34-0.11-0.47-0.24
				c-1.66-1.62-3.53-2.99-5.51-4.17c-4.01-2.39-8.28-4.01-13.05-3.68c-3.16,0.22-6.05,1.28-8.51,3.35c-0.14,0.12-0.3,0.23-0.47,0.32
				c-0.12,0.07-0.24,0.27-0.39,0.11c-0.07-0.07-0.06-0.24-0.03-0.36c0.7-2.98,1.39-5.97,2.32-8.89c0.1-0.3,0.27-0.58,0.55-0.76
				c2.65-1.7,5.53-2.73,8.69-2.53c2.88,0.19,5.55,1.23,7.96,2.84c1.16,0.77,2.26,1.62,3.39,2.43c1.22,0.88,1.48,2.32,2.07,3.56
				c1.13,2.37,2.21,4.77,3.31,7.16C327.42,84.5,327.51,84.7,327.61,84.91z"/>
                <path class="green-miami" d="M310.44,59.31c3.19-0.02,5.67,1.54,7.9,3.61c0.3,0.28,0.35,0.67,0.47,1.02c0.94,2.73,1.89,5.45,3.04,8.09
				c0.07,0.16,0.28,0.35,0.09,0.51c-0.19,0.15-0.32-0.09-0.47-0.2c-1.91-1.42-3.87-2.75-6.11-3.62c-3.99-1.56-7.91-1.44-11.78,0.41
				c-0.17,0.08-0.33,0.2-0.51,0.25c-0.31,0.09-0.61,0.55-0.92,0.25c-0.26-0.25,0.12-0.57,0.21-0.86c0.77-2.29,1.83-4.45,2.83-6.65
				c0.92-2.03,2.62-2.59,4.58-2.82C310,59.29,310.22,59.31,310.44,59.31z"/>
                <path class="green-miami" d="M318.01,61.32c-0.03,0.05-0.05,0.17-0.11,0.19c-0.18,0.06-0.28-0.1-0.4-0.2c-1.07-0.92-2.28-1.58-3.59-2.09
				c-2.05-0.79-4.11-0.93-6.2-0.17c-0.24,0.09-0.56,0.36-0.78,0.11c-0.16-0.19,0.1-0.46,0.22-0.68c0.77-1.42,1.57-2.83,2.55-4.11
				c0.7-0.92,1.88-0.91,2.87-0.88c1.65,0.04,2.84,1.09,3.92,2.25c0.3,0.32,0.22,0.71,0.31,1.07c0.38,1.4,0.73,2.81,1.09,4.22
				C317.92,61.1,317.96,61.19,318.01,61.32z"/>
                <path class="green-miami" d="M316.13,45.51c-0.26,3.12-0.28,6,0.26,9.1c-1.67-1.64-3.5-2.05-5.76-1.62
				C312.28,50.34,314.16,48.05,316.13,45.51z"/>
                <path class="green-miami" d="M301.44,121.26c6.51,0.07,11.28,2,15.57,5.2c3.77,2.81,6.66,6.38,8.74,10.59c0.87,1.76,1.54,3.61,2.05,5.51
				c0.15,0.57,0.08,0.92-0.47,1.33c-2.66,1.97-5.54,3.56-8.52,4.99c-0.47,0.23-0.64,0.28-0.76-0.39c-0.74-4.1-2.48-7.76-5.06-11.02
				c-1.93-2.44-4.22-4.47-7.04-5.83c-3.72-1.81-7.65-2.33-11.74-1.79c-0.54,0.07-0.73-0.01-0.58-0.65c0.46-1.96,0.78-3.96,0.98-5.97
				c0.05-0.53,0.43-0.66,0.83-0.79C297.73,121.7,300.07,121.2,301.44,121.26z"/>
                <path class="green-miami" d="M296.94,130.45c5.92-0.17,10.82,2.09,14.69,6.47c3.03,3.43,5.04,7.41,5.78,11.99
				c0.08,0.49-0.1,0.64-0.45,0.77c-3.09,1.14-6.22,2.14-9.42,2.95c-0.61,0.15-0.68-0.01-0.74-0.55c-0.35-3.09-1.21-6.02-2.93-8.64
				c-2.14-3.25-5.08-5.31-8.97-5.96c-1.08-0.18-2.15-0.15-3.22-0.16c-0.44,0-0.65-0.03-0.43-0.57c0.75-1.78,1.42-3.59,1.89-5.47
				c0.2-0.79,0.85-0.77,1.41-0.81C295.34,130.4,296.14,130.45,296.94,130.45z"/>
                <path class="green-miami" d="M292.14,137.89c5.77,0.25,10.21,2.8,12.63,8.81c0.68,1.69,1.05,3.44,1.29,5.22c0.1,0.75-0.14,1.1-0.98,1.26
				c-3.07,0.55-6.13,1.17-9.23,1.55c-0.9,0.11-0.88-0.21-0.85-0.81c0.14-3.01-1.01-5.52-3.11-7.58c-1.23-1.2-2.72-2.09-4.34-2.68
				c-0.7-0.26-0.64-0.42-0.24-0.92c1.11-1.38,2.25-2.73,3.13-4.29C290.81,137.77,291.51,137.9,292.14,137.89z"/>
                <path class="green-miami" d="M294.23,153.3c0,1.53,0,1.55-1.46,1.77c-2.68,0.41-5.39,0.63-8.1,0.72c-0.47,0.02-1.08,0.48-1.37-0.01
				c-0.18-0.31,0.17-0.89,0.22-1.36c0.2-1.88-0.11-3.58-1.61-4.94c-1.1-1-1.07-1.06,0.16-1.82c1.41-0.88,2.64-1.97,3.76-3.19
				c0.31-0.33,0.57-0.49,1.07-0.3c3.65,1.37,6.37,3.58,7.24,7.6C294.25,152.31,294.18,152.84,294.23,153.3z"/>
                <path class="green-miami" d="M275.35,156.4c-0.62,0.06-0.98,0.06-0.6-0.77c0.77-1.69,0.52-3.34-0.55-4.84c-0.28-0.39-0.3-0.43,0.21-0.52
				c1.71-0.27,3.42-0.6,5.03-1.29c0.43-0.19,0.7-0.11,1.02,0.14c2.52,1.96,2.91,3.61,2.08,6.37c-0.1,0.33-0.31,0.52-0.69,0.52
				c-1.36,0.01-2.7,0.3-4.07,0.19C276.96,156.13,276.15,156.26,275.35,156.4z"/>
                <path class="orange-miami" d="M221.74,104.29c-2.08-1.43-4.21-2.44-6.53-3.02c-5.3-1.32-10.45-0.66-15.48,1.23
				c-8.4,3.15-14.8,8.82-19.94,16.04c-0.67,0.94-1.29,1.92-2.05,3.04c-0.19-2.38,0.03-4.54,0.41-6.68c0.23-1.31,0.59-2.59,0.87-3.89
				c0.51-2.36,1.5-4.44,3.04-6.34c4.47-5.48,9.57-10.16,16.02-13.23c4.04-1.92,8.27-3.01,12.79-2.57c3.74,0.36,6.93,1.89,9.61,4.53
				c0.35,0.34,0.35,0.73,0.4,1.13C221.3,97.72,221.51,100.93,221.74,104.29z"/>
                <path class="orange-miami" d="M181.57,133.98c-1.44-3.52-3.53-6.66-3.75-10.5c-0.02-0.4-0.01-0.73,0.2-1.08
				c5.09-8.45,11.78-15.15,21.04-18.98c3.98-1.65,8.13-2.44,12.44-2.13c3.5,0.26,6.76,1.32,9.65,3.38c0.31,0.22,0.61,0.41,0.63,0.87
				c0.1,2.25,0.22,4.5,0.37,6.75c0.05,0.7-0.24,0.61-0.71,0.44c-2.42-0.88-4.91-1.42-7.49-1.58c-4.59-0.28-8.95,0.6-13.11,2.49
				c-8.77,3.97-14.72,10.7-18.76,19.28C181.93,133.23,181.78,133.54,181.57,133.98z"/>
                <path class="orange-miami" d="M212.37,111.74c3.18-0.06,6.23,0.56,9.19,1.7c0.57,0.22,0.74,0.53,0.77,1.12c0.14,2.22,0.33,4.43,0.52,6.64
				c0.04,0.49,0.1,0.78-0.61,0.56c-8.62-2.76-16.2-0.5-22.99,5c-4.97,4.03-8.29,9.21-9.93,15.42c-0.17,0.63-0.35,0.66-0.83,0.25
				c-2.44-2.06-4.67-4.3-6.26-7.11c-0.21-0.38-0.24-0.69-0.07-1.07c4.56-10.23,11.58-17.88,22.52-21.38
				C207.19,112.08,209.75,111.69,212.37,111.74z"/>
                <path class="orange-miami" d="M215.4,121.26c2.31-0.08,4.59,0.39,6.83,1.13c0.6,0.2,0.83,0.48,0.89,1.13c0.17,1.83,0.45,3.64,0.91,5.42
				c0.2,0.79,0.04,1.03-0.82,0.91c-3.32-0.45-6.58-0.11-9.72,1.09c-5.66,2.16-9.38,6.33-11.93,11.67c-0.92,1.92-1.6,3.93-1.9,6.04
				c-0.07,0.51-0.22,0.47-0.59,0.31c-3.18-1.4-6.14-3.15-8.92-5.23c-0.34-0.26-0.47-0.51-0.33-0.96
				c3.27-11.14,10.21-18.53,21.73-21.21C212.78,121.27,214.05,121.23,215.4,121.26z"/>
                <path class="orange-miami" d="M220.57,92.45c-0.52-0.17-0.8-0.58-1.17-0.86c-3.72-2.83-7.92-3.83-12.53-3.34
				c-7.06,0.76-12.87,4.17-18.18,8.61c-2.64,2.21-4.97,4.72-7.09,7.43c-0.11,0.14-0.2,0.33-0.56,0.35c0.64-1.6,1.23-3.16,1.88-4.69
				c1.63-3.85,3.4-7.63,5.3-11.36c0.85-1.66,2.34-2.74,3.7-3.92c3.65-3.14,7.68-5.6,12.47-6.58c5.34-1.09,10.13-0.04,14.14,3.84
				c0.33,0.32,0.51,0.67,0.6,1.13c0.6,2.98,0.97,6,1.46,8.99C220.61,92.18,220.58,92.3,220.57,92.45z"/>
                <path class="orange-miami" d="M189.87,85.4c1.64-3.63,3.29-7.26,4.94-10.89c0.06-0.14,0.16-0.28,0.27-0.38c2.92-2.48,6.02-4.62,9.85-5.52
				c3.97-0.93,7.51,0.14,10.88,2.16c0.34,0.2,0.45,0.52,0.55,0.85c0.93,2.92,1.67,5.9,2.3,8.9c0.03,0.15,0.06,0.42,0,0.45
				c-0.3,0.13-0.43-0.17-0.61-0.32c-3.25-2.73-7.03-3.87-11.21-3.55c-4.53,0.35-8.55,2.16-12.26,4.72c-1.62,1.12-3.09,2.42-4.6,3.68
				C189.94,85.47,189.9,85.44,189.87,85.4z"/>
                <path class="orange-miami" d="M215.66,69.79c-0.25,0.13-0.42-0.04-0.61-0.15c-5.65-3.12-11.08-2.37-16.34,0.98
				c-1.01,0.64-1.98,1.36-2.97,2.05c-0.25-0.27,0.01-0.49,0.09-0.68c1.06-2.58,2.06-5.17,2.9-7.83c0.27-0.86,0.73-1.51,1.42-2.05
				c2.33-1.84,4.84-3.17,7.93-2.81c2.01,0.23,3.67,0.96,4.54,3.04c1.04,2.48,2.29,4.86,3.02,7.46L215.66,69.79z"/>
                <path class="orange-miami" d="M210.81,59.38c-4.2-1.82-7.75-0.32-11.19,2.33c0.49-2.03,0.83-3.93,1.43-5.74c0.59-1.81,3.63-2.91,5.8-2.3
				c0.64,0.18,1.11,0.49,1.46,1.09c0.87,1.5,1.89,2.92,2.61,4.51C210.88,59.31,210.85,59.34,210.81,59.38z"/>
                <path class="orange-miami" d="M201.29,54.5c0.49-3,0.64-5.86,0.29-8.78c1.99,2.29,3.8,4.69,5.52,7.26
				C204.03,52.7,204.03,52.7,201.29,54.5z"/>
                <path class="orange-miami" d="M220.98,130.27c0.83,0.09,2.09-0.07,3.06,0.4c1.01,0.49,0.74,1.94,1.17,2.92c0.43,0.98,0.72,2.03,1.19,2.99
				c0.35,0.72,0.08,0.75-0.52,0.73c-2.86-0.11-5.55,0.49-7.96,2.06c-3.83,2.48-5.76,6.2-6.65,10.55c-0.16,0.77-0.28,1.56-0.36,2.35
				c-0.04,0.43-0.16,0.53-0.59,0.42c-3.25-0.82-6.45-1.79-9.58-3.01c-0.41-0.16-0.62-0.38-0.52-0.84
				c1.73-7.92,5.71-14.08,13.47-17.3C215.92,130.62,218.29,130.39,220.98,130.27z"/>
                <path class="orange-miami" d="M211.54,152.22c0.66-5.06,2.33-9.22,6.37-12.09c2.56-1.82,5.51-2.31,8.58-2.22
				c0.35,0.01,0.62,0.13,0.77,0.45c0.82,1.67,2.07,3.03,3.21,4.47c0.38,0.48,0.41,0.59-0.23,0.84c-3.49,1.38-6.17,3.58-7.22,7.37
				c-0.29,1.05-0.37,2.12-0.3,3.2c0.03,0.53-0.13,0.64-0.65,0.57c-3.19-0.47-6.37-0.98-9.54-1.62
				C211.69,153.02,211.34,152.75,211.54,152.22z"/>
                <path class="orange-miami" d="M236.97,148.43c-0.18,0.14-0.29,0.24-0.41,0.33c-2.17,1.67-2.96,3.82-2.19,6.47
				c0.21,0.72,0.02,0.75-0.63,0.7c-3.13-0.24-6.25-0.52-9.38-0.83c-0.81-0.08-1.04-0.39-1.05-1.09c-0.05-2.89,0.92-5.35,3.07-7.3
				c1.28-1.16,2.75-2.03,4.4-2.57c0.37-0.12,0.63-0.17,0.92,0.16C233.2,145.97,234.9,147.39,236.97,148.43z"/>
                <path class="orange-miami" d="M243.81,150.35c-0.14,0.22-0.25,0.4-0.37,0.58c-1.03,1.52-1.22,3.12-0.33,4.75
				c0.39,0.71,0.13,0.74-0.48,0.7c-2.25-0.13-4.5-0.26-6.75-0.35c-0.51-0.02-0.69-0.2-0.82-0.68c-0.78-2.75-0.14-4.66,2.17-6.3
				c0.27-0.19,0.49-0.31,0.86-0.15C239.89,149.65,241.81,150.03,243.81,150.35z"/>
                <path class="orange-miami" d="M215.65,69.8c0.02,0.02,0.05,0.03,0.06,0.05c0.01,0.01-0.01,0.03-0.01,0.05c-0.01-0.04-0.02-0.07-0.03-0.11
				C215.66,69.79,215.65,69.8,215.65,69.8z"/>
                <path class="orange-miami" d="M210.92,59.27c0.03,0.06,0.05,0.11,0.08,0.17c-0.07-0.02-0.13-0.04-0.2-0.06
				C210.85,59.34,210.88,59.31,210.92,59.27z"/>
            </g>
          </g>

            <g id="Z_3_" class="animate__animated animate__fadeIn _5 ">
            <polygon class="black" points="338.79,176.35 364.7,163.18 402.13,180.03 390.16,216.74 428.79,230.79 402.89,243.08 362.06,229.91
			375.67,190.39 		"/>
              <polygon class="red black-stroke small-stroke" points="331.75,167.76 357.65,154.59 395.08,171.44 383.11,208.15 421.75,222.2 395.85,234.49
			355.02,221.32 368.63,181.81 		"/>
          </g>
            <g id="circles2_8_" class="animate__animated animate__fadeIn _5 ">
            <g>
              <circle class="red black-stroke small-stroke" cx="596.2" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="615" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="633.81" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="652.61" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="671.42" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="690.23" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="709.03" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="727.84" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="746.64" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="765.45" cy="596.43" r="6.59"/>
              <circle class="red black-stroke small-stroke" cx="784.26" cy="596.43" r="6.59"/>
            </g>
              <g>
              <circle class="red black-stroke small-stroke" cx="623.71" cy="626.72" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="642.52" cy="626.72" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="661.32" cy="626.72" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="680.13" cy="626.72" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="698.94" cy="626.72" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="717.74" cy="626.72" r="6.59"/>
            </g>
              <g>
              <circle class="red black-stroke small-stroke" cx="556.61" cy="657.01" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="575.41" cy="657.01" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="594.22" cy="657.01" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="613.03" cy="657.01" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="631.83" cy="657.01" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="650.64" cy="657.01" r="6.59"/>
            </g>
              <g>
              <circle class="red black-stroke small-stroke" cx="645" cy="566.14" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="663.81" cy="566.14" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="682.62" cy="566.14" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="701.42" cy="566.14" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="720.23" cy="566.14" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="739.03" cy="566.14" r="6.59"/>
            </g>
          </g>
            <g id="circle_6_" class="animate__animated animate__fadeIn _5 ">
            <circle class="black" cx="141.74" cy="388.74" r="30.62"/>
              <circle class="red black-stroke small-stroke" cx="137.25" cy="382.47" r="30.62"/>
          </g>
            <g id="thunderbolt_1_" class="animate__animated animate__fadeIn _5 ">
            <polygon class="black" points="724.33,99.84 762.37,104.52 754.76,127.35 766.47,129.55 755.35,151.35 765.12,153.29 719.06,219.25
			724.69,165.55 712.62,163.65 722.34,140.31 708.52,137.89 		"/>
              <polygon class="blue black-stroke small-stroke" points="718.84,95.89 756.89,100.57 749.28,123.4 760.98,125.6 749.86,147.4 759.63,149.34 713.57,215.3
			719.21,161.6 707.13,159.69 716.85,136.36 703.03,133.94 		"/>
          </g>
            <image style="overflow:visible;" width="900" xlink:href="../assets/dailyprophet.png"
                   transform="matrix(0.2149 0 0 0.2149 309.3435 294.6824)" class="animate__animated animate__fadeIn _6">
          </image>

            <image style="overflow:visible;" width="1000" xlink:href="../assets/quid-shirts.png"
                   transform="matrix(0.2149 0 0 0.2149 460.3435 60.6824)" class="animate__animated animate__fadeIn _6">
          </image>
            <image style="overflow:visible;" width="1000" xlink:href="../assets/whatiswinter.png"
                   transform="matrix(0.2149 0 0 0.2149 45.3435 445.6824)" class="animate__animated animate__fadeIn _6">
          </image>
        </g>
        </svg>

        <h1 class="title is-1 has-text-grey-dark is-highlighted blue-highlight animate__animated animate__fadeInDown _2">quidditch</h1>
      </div>
    </section>
  </b-carousel-item>
</template>

<script>
export default {
  name: "Quidditch"
}
</script>
